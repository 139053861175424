import axios from "../api"

const initialState = {
  turnover: null,
  mediaplan: null,
  error: null
}

export const historic = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    turnover(state, turnover) {
      state.turnover = turnover
    },
    mediaplan(state, mediaplan) {
      state.mediaplan = mediaplan
    },
    error(state, error) {
      state.error = error
    }
  },
  actions: {
    fetchHistoricTurnover({
      // commit,
      rootGetters
    }, year) {
      let url = "/turnover/historic" + (year ? "/" + year : "")
      return axios.get(url, {
          headers: rootGetters["user/authHeader"]
        })
    },
    fetchHistoricMediaplan({
      // commit,
      rootGetters
    }, year) {
      let url = "/mediaplanung/historic" + (year ? "/" + year : "")
      return axios.get(url, {
          headers: rootGetters["user/authHeader"]
        })
    },
  }
};