
<template>
  <div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue"
//import useUI from '@/js/useUI.js'

export default {
  mixins: [UiMixin],
  data: function() {
    return {
      showChannels: false,
    }
  },
  computed: {
    probeFinancialData() {
      return this.$store.getters["analysis/probeFinancialData"]
    },
    referenceFinancialData() {
      return this.$store.getters["analysis/referenceFinancialData"]
    },
    selectedChannels() {
      return this.$store.state.analysis.selectedChannels
    },
    referenceChannelsGross() {
      let mediaplan = this.referenceFinancialData.mediaplan
      if (!mediaplan) {
        return null
      }
      return this.accumulatedChannels(mediaplan, this.referenceFinancialData.meta.year)
    },
    probeChannelsGross() {
      let mediaplan = this.probeFinancialData.mediaplan
      if (!mediaplan) {
        return null
      }
      return this.accumulatedChannels(mediaplan, this.probeFinancialData.meta.year)
    },
    fillColor() {
      let canvas = document.querySelector(".chart-diagram canvas")
      if (canvas) {
        let ctx = canvas.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "rgba(36,210,222, 0.25)"); //blue: "rgba(64,81,242,0.125)");
        gradient.addColorStop(0.5, "rgba(64,81,242,0)");
        return gradient;
      }
      return "#0f0";
    },
    chartData() {
      let labels = this.$store.getters["config/calendarWeeks"].map( w => w)
      let zeros = this.$store.getters["config/calendarWeeks"].map( () => 0)

      labels[0] = "CW " + labels[0]
      let chartData = {
        labels: labels,
        datasets: [],
      }

      if (this.$store.state.ui.isFetchingChartData) {
        chartData.datasets = [
          {
            label: "Loading data ...",
            data: zeros,
            pointRadius: 0,
            borderWidth: 2,
            borderColor: "#D5D8E2",
          }
        ]
        return chartData
      }

      // Reference Turnover
      if (this.referenceFinancialData.turnover) {
        chartData.datasets.push({
            label: this.referenceFinancialData.meta?.name || "--",
            lineTension: 0.3,
            pointRadius: 0,
            pointHitRadius: 20,
            data:  this.referenceFinancialData?.turnover?.map(t => t?.turnover),
            borderColor: "#3F51F2",
            backgroundColor: "rgba(65, 87, 238, 0.04)", // blue
            borderWidth: 2,
            fill: true,
        });
      }
      // Probe Turnover
      if (this.probeFinancialData.turnover) {
        chartData.datasets.push({
            label: this.probeFinancialData.meta?.name || "--",
            lineTension: 0.3,
            pointRadius: 0,
            pointHitRadius: 20,
            data: this.probeFinancialData?.turnover?.map(t => t?.turnover),
            borderColor: "#00D2DE",
            // backgroundColor: "#00D2DE",
            borderWidth: 2,
            backgroundColor: "rgb(0, 210, 222, 0.04)", // green
            fill: true,
            // backgroundColor: this.fillColor,
        });
      }

      if (this.showChannels) {
        if (this.referenceChannelsGross) {
          chartData.datasets.push({
            backgroundColor: "rgba(65, 87, 238, 0.25)",
            type: 'bar',
            data: this.referenceChannelsGross.map(v => v.gross),
          })
        }
        if (this.probeChannelsGross) {
          chartData.datasets.push({
            backgroundColor: "rgb(0, 210, 222, 0.25)",
            type: 'bar',
            data: this.probeChannelsGross.map(v => v.gross),
          })
        }
      }
      return chartData;
    },
    zoomRange() {
      let maxValue = 0
      if (this.showChannels) {
        if (this.referenceChannelsGross) {
          maxValue = Math.max(...this.referenceChannelsGross.map(v => v.gross))
        }
        if (this.probeChannelsGross) {
          maxValue = Math.max(maxValue, Math.max(...this.probeChannelsGross.map(v => v.gross)))
        }
      }
      return {
        min: 0,
        max: Math.round(maxValue * 1.25) // leave some space at the top for the alpha gradient overlay
      }
    },
    chartSubtitle() {
      let a, b, ref, probe

      ref = this.referenceFinancialData
      probe = this.probeFinancialData

      if (ref?.meta) {
        a = ref.meta?.name || "Unbenannt"
      }
      if (probe?.meta) {
        b = probe.meta?.name || "Unbenannt"
      }
      if (ref?.meta && probe?.meta) {
        return `${a} compared with ${b}`
      }
      if (ref) {
        return ref.meta?.subtitle || a
      }
      if (probe) {
        return probe.meta?.subtitle || b
      }
      return ""
    },
    probeDataId: {
      get() {
        let key = this.$route.meta.datasetKeys?.probe
        return key ? this.$store.state.analysis.datasetIds[key]?.probe : null
      },
      set(id) {
        this.$store.commit("analysis/datasetIdForKeyAndType", {
          type: "probe",
          key: this.getDatasetKey("probe"),
          id: id
        })
      }
    },
    referenceDataId: {
      get() {
        let key = this.$route.meta.datasetKeys?.reference
        return key ? this.$store.state.analysis.datasetIds[key]?.reference : null
      },
      set(id) {
        this.$store.commit("analysis/datasetIdForKeyAndType", {
          type: "reference",
          key: this.getDatasetKey("reference"),
          id: id
        })
      }
    },
  },
  methods: {
    accumulatedChannels(mediaplan, yearKey) {
      let weeks = this.$store.state.config.weeks
      let accumulatedValues = weeks.map( () => {
        return {
          cw: null,
          gross: null,
          week_index: null,
          year: null
        }
      })
      let addAllChannels = this.selectedChannels.length == 0
      var value
      weeks.forEach( week => {
        mediaplan.channels.forEach( channel => {
          if (addAllChannels || this.selectedChannels.includes(channel.key)) {
            let valuesForYear = channel.values.filter( v => v.year == yearKey)
            let v = valuesForYear.find( v => v.week_index == week.week_index)
            if (v) {
              value = accumulatedValues[week.week_index]
              value.gross += v.gross
              value.week_index = week.week_index
              value.cw = week.cw
              value.year = yearKey
            }
          }
        })
      })
      return accumulatedValues
    },
    fetchReferenceData(opts = {}) {
      if (!this.referenceDataId) {
        this.$store.commit("analysis/resetReferenceFinancialData")
        return new Promise( r => r() )
      }
      return new Promise( resolve => {
        this.$store.dispatch("analysis/fetchReferenceFinancialData", {
          id: this.referenceDataId,
          historicYear: opts.historicYear
        }).then( () => {
          resolve()
        })
      })
    },
    fetchProbeData(/*opts = { blockUI: true }*/) {
      if (!this.probeDataId) {
        this.$store.commit("analysis/resetProbeFinancialData")
        return new Promise( r => r() )
      }
      return new Promise( resolve => {
        this.$store.dispatch("analysis/fetchProbeFinancialData", {
            id: this.probeDataId,
          }).then( () => {
          resolve()
        })
      })
    },
    getDatasetKey(datasetType) {
      let datasetKey = this.$route.meta.datasetKeys?.[datasetType]
      if (!datasetKey) {
        throw new Error("Dataset key for probe missing in route metadata!")
      }
      return datasetKey
    },
    calculateTurnoverSums() {
      return this.calculateTurnoverSumsOverWeeks(
        this.referenceFinancialData,
        this.probeFinancialData
      )
    },
    /**
     * Bilde die Summe über Turnover-Arrays (A, B) für überlappenden Zeiträume in A und B.

       Die Umsatzsummen & Mediaplan-Summen sollen gebildet werden aus den Wochen, bei denen sowohl in
       A als auch in B der Umsatz (auch für Mediaplan-Summe) existiert und echt größer als 0 ist.

      In beiden Dashboard-Varianten soll die Gesamtsumme der Auswahl in den Differenzen-Boxen
      (Sell Out Umsatz und Mediaspending (Brutto)) angezeigt werden, wenn nur (A) oder (B)
      einzeln und in dem anderen "No selection" ausgewählt wurde.

       - See Change #1348
       - See Change #1368
       - See Change #1379 aka "Geschäftsjahre"

        Die Logik sollte sein:

        a) finde kleinsten week_index in A und kleinsten in B, bei dem turnover > 0
        a1) wenn nicht gefunden, können keine Summen/Differenzen berechnet werden

        b) setze start_index auf max(minA, minB). Wenn ==1, setze auf 0.

        c) finde größten week_index in A und größten in B, bei dem turnover > 0
        c1) wenn nicht gefunden, können keine Summen/Differenzen berechnet werden

        d) setze end_index auf min(maxA, maxB). Wenn >=52, setze auf 54 ** (siehe unten)

        e) bilde Summe für [start_index..end_index].
        Achtung: bei Geschäftsjahr "Juli - Juni" kann es vorkommen, dass die KW53 in einem
        oder beiden Datensätzen fehlt - und somit auch ein week_index in der Mitte fehlt.
        D.h. man muss da einen null-Check einbauen. Aber pro Datensatz einzeln, du brauchst
        nicht auf die Existenz im anderen zu checken.

        f) der Punkt bleibt: Gesamtsumme der Auswahl in den Differenzen-Boxen soll
        (Sell Out Umsatz und Mediaspending (Brutto)) angezeigt werden, wenn nur (A) oder (B)
        einzeln und in dem anderen "No selection" ausgewählt wurde.

    */
    calculateTurnoverSumsOverWeeks(referenceData, probeData) {
      let turnoverA = referenceData?.turnover
      let turnoverB = probeData?.turnover

      let mediaplanA = referenceData?.mediaplan
      let mediaplanB = probeData?.mediaplan

      let accumulatedChannelsOfMediaplanA = mediaplanA ? this.accumulatedChannels(mediaplanA, referenceData.meta.year) : []
      let accumulatedChannelsOfMediaplanB = mediaplanB ? this.accumulatedChannels(mediaplanB, probeData.meta.year) : []

      let hasA = turnoverA != null
      let hasB = turnoverB != null
      let sumA = 0, sumB = 0, mediaplanSumA = 0, mediaplanSumB = 0

      // a) finde kleinsten week_index in A und kleinsten in B, bei dem turnover > 0
      var turnoverGreaterZeroA = turnoverA?.filter( t => t?.turnover > 0) || []
      var turnoverGreaterZeroB = turnoverB?.filter( t => t?.turnover > 0) || []
      var weekIndexesA = turnoverGreaterZeroA.map( t => t.week_index)
      var weekIndexesB = turnoverGreaterZeroB.map( t => t.week_index)

      var minWeekIndexA = Math.min(...weekIndexesA)
      var minWeekIndexB = Math.min(...weekIndexesB)

      // a1) wenn nicht gefunden, können keine Summen/Differenzen berechnet werden
      if (hasA && hasB && (minWeekIndexA == Infinity || minWeekIndexB == Infinity)) {
          return 0
      } else if (hasA && !hasB && minWeekIndexA == Infinity) {
          return 0
      } else if (hasB && !hasA && minWeekIndexB == Infinity) {
          return 0
      }

      //  b) setze start_index auf max(minA, minB). Wenn ==1, setze auf 0.
      let startIndex = Math.max(minWeekIndexA, minWeekIndexB)
      if (startIndex == 1) {
        startIndex = 0
      }

      // c) finde größten week_index in A und größten in B, bei dem turnover > 0
      var maxWeekIndexA = Math.max(...weekIndexesA)
      var maxWeekIndexB = Math.max(...weekIndexesB)
      // c1) wenn nicht gefunden, können keine Summen/Differenzen berechnet werden
      // -> erledigt durch a1 check (s.o.)

      // d) setze end_index auf min(maxA, maxB). Wenn >=52, setze auf 54 ** (siehe unten)
      let endIndex = Math.min(maxWeekIndexA, maxWeekIndexB)
      if (endIndex >= 52) {
        endIndex = 54
      }

      for (var i=startIndex; i<=endIndex; i++) {
        sumA += turnoverGreaterZeroA.find( t => t.week_index == i)?.turnover || 0
        sumB += turnoverGreaterZeroB.find( t => t.week_index == i)?.turnover || 0
        mediaplanSumA += accumulatedChannelsOfMediaplanA.find( m => m.week_index == i)?.gross || 0
        mediaplanSumB += accumulatedChannelsOfMediaplanB.find( m => m.week_index == i)?.gross || 0
      }

      return {
        hasReference: hasA,
        hasProbe: hasB,
        sumReference: sumA,
        sumProbe: sumB,
        sumReferenceMediaplan: mediaplanSumA,
        sumProbeMediaplan: mediaplanSumB,
      }
    },

  },
};
</script>

<style scoped>
</style>
