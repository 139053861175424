<template>
  <div class="mediaplan-details">
    <div class="content">
      <table v-if="mediaplan">
          <th width="5%"></th>
          <th width="19%">Media channel</th>
          <th width="19%">Budget</th>
          <th>Sell-Out uplift</th>
          <tr v-for="(channel, index) in mediaplan.channels" v-bind:key="index" @click="onSelectChannel(channel)">
            <td><CheckBox class="checkbox"
                :class="selectedChannels.includes(channel.key) ? 'always-visible' : ''"
                :checked="selectedChannels.includes(channel.key)" /></td>
            <td>{{ channel.channel }}</td>
            <td>
              <span class="channel-total-value">{{ formatCurrency(channel.total_value) }}</span>
            </td>
            <td>
              <span class="channel-sellout-gross-income">Will be calculated during saving</span>
            </td>
          </tr>
      </table>
    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue";
import CheckBox from "@/components/ui/CheckBox.vue";

export default {
  name: "MediaplanDetails",
  mixins: [UiMixin],
  components: {
    CheckBox
  },
  props: {
    mediaplan: {
      type: Object
    },
  },
  methods: {
    onSelectChannel(channel) {
      this.$store.commit("analysis/toggleSelectedChannel", channel.key)
    }
  },
  computed: {
    selectedChannels() {
      return this.$store.state.analysis.selectedChannels
    },
  }
};
</script>

<style scoped>
h3 {
  font-size: 18px;
}

.header {
  text-align: left;
}
table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

th {
  text-align: left;
  height: 40px;
}

td {
  text-align: left;
  border-bottom: 1px solid var(--c-bright-grey);
}

tr:last-child td {
  border-bottom: none;
}

tr {
  cursor: pointer;
  height: var(--tr-default-height);
}

tr:hover {
  background-color: var(--c-white-grey);
}

.checkbox {
  margin-left: 4px;
  margin-top: 2px;
  visibility: hidden;
}
.checkbox.always-visible {
  visibility: visible;
}
tr:hover .checkbox {
  visibility: visible;
}

</style>
