<template>
  <div class="info-hud" @mouseover="onHover" @mouseleave="onLeave">
    <inline-svg :src="require('@/assets/svg/icons/main_window/icon_info_12x12px.svg')" width="12" height="12"></inline-svg>
  </div>
  <div v-show="showHud" class="hud" :style="hudStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoHud",
  props: {
  },
  data: function() {
    return {
      hudStyle: {
        top: 0,
        left: 0,
      },
      showHud: false,
    }
  },
  computed: {
  },
  methods: {
    onHover(e) {
      let el = e.target
      var rect = el.getBoundingClientRect();
      this.hudStyle = {
        top: (rect.top + rect.height + 10) + 'px',
        left: rect.left + 'px',
      }
      this.showHud = true
    },
    onLeave() {
      this.showHud = false
    }
  }
};
</script>

<style scoped>
.info-hud {
  display: inline-block;
}
.hud {
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 7px;
  min-width: 120px;
  min-height: 80px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  padding: 20px;
}
</style>
