<template>
  <div class="menu-overlay-page">
    <FullFramePageContainer>
      <div class="container">
        <div class="col left">
          <SiteMenu />
          <AdminPanel v-if="showAdminPanel" />
        </div>
        <div class="col center">
            <inline-svg :src="require('@/assets/svg/icons/main_menu/shape_network.svg')" width="210" height="154"></inline-svg>
        </div>
        <div class="col right">
          <div class="contact">
            <span class="company-name">SimCog Technologies GmbH<br></span>
            Elbchaussee 1<br>
            22765 Hamburg<br>
            Germany<br>
            <button class="button primary tall" @click="onContact">Contact</button>
          </div>
        </div>
      </div>
      <div class="icon-button close" @click="onClose">
        <inline-svg :src="require('@/assets/svg/icons/main_window/icon_main_menu_close_24x24px.svg')" width="24" height="24"></inline-svg>
      </div>
    </FullFramePageContainer>
  </div>
</template>

<script>

import FullFramePageContainer from '@/views/FullFramePageContainer.vue'
import SiteMenu from "@/components/site/SiteMenu.vue"
import AdminPanel from "@/components/ui/AdminPanel.vue"

export default {
  name: 'MenuOverlayPage',
  components: {
    FullFramePageContainer,
    SiteMenu,
    AdminPanel
  },
  computed: {
    showAdminPanel() {
      return process.env.VUE_APP_SHOW_DEV_OPTIONS
    }
  },
  methods: {
    onContact() {
      window.open("mailto:pia-support@simcog.de")
    },
    onClose() {
      this.$store.commit("ui/isSiteMenuShown", false)
    }
  }
}
</script>

<style scoped>
.menu-overlay-page {
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}
.container > div {
  flex-grow: 1;
  flex-basis: 0;
}
.col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact {
  color: var(--c-deep-grey);
  line-height: 1.5;
}
.contact button {
  margin-top: 20px;
  width: 248px;
}
.company-name {
  color: var(--c-dark-blue);
}
.icon-button.close {
  position: fixed;
  top: 48px;
  left: 26px;
}

@media screen and (max-width: 1023px) {
  .container {
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
  }
  .col.center {
    display: none;
  }
}
</style>
