<template>
  <div class="total-amount-tiles">
    <fieldset>
      <h3>Total</h3>
      <div class="total-amount-tile">
        <div class="left">
          <label>Sell-out</label>
          <span v-if="referenceFinancialData?.meta?.totalValues[currentSegment]" class="reference-data">A: {{ formatCurrency(referenceFinancialData?.meta?.totalValues[currentSegment] || 0) }}</span>
          <span v-else class="reference-data not-available">A: --</span>
          <span v-if="probeFinancialData?.meta?.totalValues[currentSegment]" class="probe-data">B: {{ formatCurrency(probeFinancialData?.meta?.totalValues[currentSegment] || 0) }}</span>
          <span v-else class="probe-data not-available">B: --</span>
        </div>
        <div class="divider"></div>
        <div class="right">
          <label>Media</label>
          <span v-if="referenceFinancialData.mediaplan" class="reference-data">A: {{ formatCurrency(referenceFinancialData.mediaplan.total_value || 0) }}</span>
          <span v-else class="reference-data not-available">A: --</span>
          <span v-if="probeFinancialData.mediaplan" class="probe-data">B: {{ formatCurrency(probeFinancialData.mediaplan.total_value || 0) }}</span>
          <span v-else class="probe-data not-available">B: --</span>
        </div>
      </div>

    </fieldset>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue"
import AnalysisMixin from "@/components/mixins/AnalysisMixin.vue"

export default {
  name: 'TotalAmountTiles',
  mixins: [AnalysisMixin, UiMixin],
  computed: {
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
  },
  methods: {
  }
}
</script>

<style scoped>
.total-amount-tile {
    background: var(--c-white-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 98px;
    border-radius: 7px;
    justify-content: space-between;
}
.left, .right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.left span, .right span {
    display: block;
}
span.margin-percentage {
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 8px;
}

span.reference-data {
    color: var(--c-reference-data);
}

span.probe-data {
    color: var(--c-probe-data);
}

.divider {
    width: 1px;
    height: 60px;
    background: var(--c-light-grey);
}
.not-available {
  opacity: 0.25;
}

label {
  margin-bottom: 10px;
}

h3 {
  margin-top: 0;
}
</style>
