<template>
  <div></div>
</template>

<script>

import useUI from '@/js/useUI.js'

export default {
  name: "RenameMixin",
  data: function() {
    return {
      itemToBeRenamed: null,
      newItemName: null,
      renamingError: null,
    }
  },
  methods: {
    doCancelRename() {
      useUI(this.$store).unblockUI()
      this.itemToBeRenamed = null
      this.newItemName = null
      this.renamingError = null
    }
  },
};
</script>
