export default function ( { commit } ) {

  function blockUI(opts = { loading: false }) {
    unblockUI()
    if (opts.unblockDisabled) {
      commit("ui/isUIUnblockByUserDisabled", true)
    }
    if (opts.complete) {
      commit("ui/isScreenBlocked", true)
    } else {
      commit("ui/isMasterViewBlocked", true)
    }
    if (opts.loading) {
      commit("ui/isLoading", true)
    }
    if (opts.chart) {
      commit("ui/isFetchingChartData", true)
    }
  }

  function unblockUI() {
    commit("ui/isUIUnblockByUserDisabled", false)
    commit("ui/isMasterViewBlocked", false)
    commit("ui/isScreenBlocked", false)
    commit("ui/isLoading", false)
    commit("ui/isFetchingChartData", false)
  }

  function getScrollParent(node) {
    if (node == null) {
      return null;
    }
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  return {
    blockUI,
    unblockUI,
    getScrollParent,
  }
}