<template>
  <div class="level-meter">
    <svg xmlns="http://www.w3.org/2000/svg" width="145.143" height="294.096">
      <defs>
        <linearGradient id="levelmeter_gradient" x1="-.03" y1=".5" x2="1.015" y2=".5" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fff"/>
          <stop offset=".493" stop-color="#3f51f2"/>
          <stop offset="1" stop-color="#fff"/>
        </linearGradient>
        <filter id="levelmeter_border" x="0" y="0" width="57.016" height="294.096" filterUnits="userSpaceOnUse">
          <feOffset dy="5"/>
          <feGaussianBlur stdDeviation="4.5" result="blur"/>
          <feFlood flood-color="#263757" flood-opacity=".161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g data-name="Gruppe 4221">
        <g id="needle" data-name="Gruppe 4209" :transform="`translate(0 ${translateY}) rotate(90 428.453 -107.547)`">
          <rect width="53.84" height="51.809" rx="7" transform="translate(651 175.763)" fill="#3f51f2"/>
          <text transform="rotate(-90 442.524 -240.856)" fill="#fff" font-size="12" font-weight="500" xml:space="preserve" text-anchor="middle"><tspan x="2">{{ needleLabel }}</tspan></text>
          <path d="m677.92 236.343-11.43-12.771h22.86Z" fill="#3f51f2"/>
        </g>
        <g data-name="Gruppe 4220">
          <g data-name="Gruppe 4212" transform="translate(-1150.994 -258.952)">
            <g transform="translate(1150.99 258.95)" filter="url(#levelmeter_border)">
              <rect data-name="Rechteck 1238" width="267.096" height="30.016" rx="15.008" transform="rotate(90 17.51 26.01)" fill="#fff"/>
            </g>
            <rect data-name="Rechteck 1241" width="238.825" height="4.074" rx="2.037" transform="rotate(90 449.976 731.563)" fill="url(#levelmeter_gradient)"/>
          </g>
          <path fill="none" stroke="#3f51f2" d="M64.313 141.957H53.686"/>
          <path fill="none" stroke="#aeb4cb" d="M58.999 102.957h-5.313"/>
          <path fill="none" stroke="#aeb4cb" d="M58.999 180.957h-5.313"/>
          <path fill="none" stroke="#aeb4cb" d="M58.999 258.957h-5.313"/>
          <path fill="none" stroke="#aeb4cb" d="M58.999 24.957h-5.313"/>
        </g>
        <text transform="translate(66.563 67)" fill="#aeb4cb" font-size="10" font-weight="600" letter-spacing=".03em"><tspan x="-13.325" y="0">+10%</tspan></text>
        <text transform="translate(66.563 223)" fill="#aeb4cb" font-size="10" font-weight="600" letter-spacing=".03em"><tspan x="-12.215" y="0">-10%</tspan></text>
      </g>
    </svg>
    <div class="legend">Deviation forecast to sell-out revenue</div>
  </div>
</template>

<script>
export default {
  name: "LevelMeter",
  props: {
    label: {
      type: String,
      default: ""
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    needleLabel() {
      let minLen = 10
      let s = ""
      if (this.label?.length <= minLen) {
        for (var i=0; i < minLen - this.label.length; i++) {
          s += " "
        }
        s += this.label
      }
      return s
    },
    translateY() {
      return this.percentage * -7.75 // magic number: depends on the height of the scale and SVG transform, determined by hand
    }
  }
};
</script>

<style scoped>
.level-meter {
  margin: 0 0 0 80px;
}
.level-meter svg {
  margin-left: 40px;
}
.legend {
  text-transform: uppercase;
  max-width: 150px;
  color: var(--c-greyish);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
}
.container.info-shown .level-meter {
  display: none;
}
</style>
