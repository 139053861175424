<template>
  <div class="latest-mediaplans">
    <div class="content">
      <table>
          <tr v-for="(item, index) in reports" v-bind:key="index" @click="onReportClick(item)">
              <td width="5%" >
                <inline-svg v-if="!item.locked" :src="require('@/assets/svg/icons/reports/icon_reports_small_25x25px.svg')" width="25" height="25"></inline-svg>
                <inline-svg v-if="item.locked" :src="require('@/assets/svg/icons/reports/icon_reports_locked_small_25x25px.svg')" width="25" height="25"></inline-svg>
              </td>
              <td width="35%" class="name">
                <span class="marked-private" v-if="item.visibility == 'private'">[PRIVATE]</span>
                <span class="marked-simcog" v-if="item.visibility == 'simcog'">[SIMCOG]</span>
                {{ item.title }}
              </td>
              <td width="15%" class="date nowrap">
                {{ dateInfos(item).lastModified }}
                <InfoHud v-if="dateInfos(item).lastModified">
                  <slot>
                    <DateInfo :item="item" />
                  </slot>
                </InfoHud>
              </td>
              <td width="15%" class="nowrap">{{ numberOfReportViewsTitles[item.id] }}</td>
              <td width="15%" class="nowrap">{{ item.user_firstname }} {{ item.user_lastname }}</td>
              <td width="15%" class="nowrap actions">
                <div class="action-button-wrapper">
                  <button class="button rounded ghost" @click.stop="onReportClick(item)">Open report</button>
                  <div class="icon-button"
                      @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: item, menuItems: contextMenuItems[item.id] })">
                    <inline-svg
                      :src="require('@/assets/svg/icons/main_window/icon_options_default_24x5px.svg')" width="24" height="5">
                    </inline-svg>
                  </div>
                </div>
              </td>
          </tr>
      </table>
    </div>
    <ConfirmDialog v-if="reportToBeDeleted" title="Delete report?">
      <template v-slot:message><span v-html="deleteConfirmMessage"></span></template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="onCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
          <button @click.stop="doDeleteReport" @keyup.enter="doDeleteReport" class="button primary mediumsize rounded">Delete</button>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog v-if="reportToBeRenamed" title="Rename report">
        <template v-slot:message>Please enter a new name for the report.</template>
        <template v-slot:content>
          <TextInput class="input-report-name" type="text" v-model="reportToBeRenamed.title" @keyup.enter="doRename" />
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="onCancelRename" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="isDoRenameButtonDisabled" @click.stop="doRename" class="button primary mediumsize rounded">Rename</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue";
import InfoHud from "@/components/ui/InfoHud.vue"
import DateInfo from "@/components/lists/DateInfo.vue"
import ReportMixin from "../mixins/ReportMixin.vue";
import Util from "@/util.js";
import useUI from '@/js/useUI.js'
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import TextInput from "@/components/ui/TextInput.vue"

export default {
  name: "ReportsList",
  mixins: [UiMixin, ReportMixin],
  props: {
    reports: {
      type: Array
    }
  },
  components: {
    InfoHud,
    DateInfo,
    ConfirmDialog,
    TextInput
  },
  data: function() {
    return {
    }
  },
  methods: {
    onReportClick(report) {
      this.$router.push(`/reports/${report.id}/views/0`);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters["config/currentUser"]
    },
    isDoRenameButtonDisabled() {
      return this.reportToBeRenamed.title.length == 0
    },
    contextMenuItems() {
      const currentUser = this.currentUser
      return Util.contextMenuItems(this.reports, (report) => {
        const allActions = [
          {
            title: "Rename",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.$store.dispatch("reports/fetchReport", report.id).then( r => {
                this.reportToBeRenamed = r
              })
            },
            disabled: report.locked,
          },
          {
            title: "Download",
            handler: () => {
              this.download("reports/downloadReport", report.id)
            }
          },
          {
            title: "Delete",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true })
              this.reportToBeDeleted = report
            },
            disabled: report.locked,
          },
          {
            title: report.locked ? "Unlock report" : "Lock report",
            handler: () => {
              this.doUpdateReportLockState(report, !report.locked)
            }
          },
          {
            title: "Mark as public",
            handler: () => {this.doChangeVisibility(report, "public")},
            disabled: report.locked || report.visibility == "public",
          },
          {
            title: "Mark as SimCog only",
            handler: () => {this.doChangeVisibility(report, "simcog")},
            disabled: report.locked || report.visibility == "simcog" || !currentUser.is_simcog_user,
          },
          {
            title: "Mark as private",
            handler: () => {this.doChangeVisibility(report, "private")},
            disabled: report.locked || report.visibility == "private" || !report.is_own_report,
          }
        ]
        return allActions.filter((action) => {return !action.disabled})
      })
    },
  },
};
</script>

<style scoped>
.latest-mediaplans {
}

h3 {
  font-size: 18px;
}

.header {
  text-align: left;
}
table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

td {
  text-align: left;
  border-bottom: 1px solid var(--c-bright-grey);
}
td.actions {
  text-align: center;
}
tr:last-child td {
  border-bottom: none;
}

td.name {
  font-weight: 500;
}

td.name .headline-badge {
  transform: translate(0px, -4px);
}

td:last-child {
  text-align: right;
}

tr {
  cursor: pointer;
  height: var(--tr-default-height);
}

tr:hover {
  background-color: var(--c-white-grey);
}

.button {
  margin: 0;
}
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.action-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.marked-private {
  color: rgb(200, 0, 0);
}

.marked-simcog {
  color: rgb(200, 0, 180);
}

</style>
