<template>
  <div class="margin-tile">
    <div class="left">
        <span v-if="referenceTotalValue && probeTotalValue" class="margin-percentage">{{ sign + Math.abs(differencePercent.toFixed(2)) }}%</span>
        <span v-else class="margin-percentage not-available">--</span>
        <span v-if="referenceTotalValue && probeTotalValue" class="margin-amount">{{ sign + formatCurrency(Math.abs(difference)) }}</span>
        <span v-else class="margin-amount not-available">--</span>
    </div>
    <div class="divider"></div>
    <div class="right">
        <span v-if="referenceTotalValue" class="reference-data">A: {{ formatCurrency(referenceTotalValue) }}</span>
        <span v-else class="reference-data not-available">A: --</span>

        <span v-if="probeTotalValue" class="probe-data">B: {{ formatCurrency(probeTotalValue) }}</span>
        <span v-else class="probe-data not-available">B: --</span>

    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue"

export default {
  name: 'MarginTile',
  mixins: [UiMixin],
  props: {
    referenceTotalValue: {
      type: Number
    },
    probeTotalValue: {
      type: Number
    }
  },
  computed: {
    difference() {
      return this.probeTotalValue - this.referenceTotalValue
    },
    differencePercent() {
      return this.difference / this.referenceTotalValue * 100
    },
    sign() {
        if (this.difference == 0) {
            return " "
        } else {
            return (this.difference > 0 ? '+ ' : '- ')
        }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.margin-tile {
    background: var(--c-white-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 98px;
    border-radius: 7px;
    justify-content: space-between;
}
.left, .right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.left span, .right span {
    display: block;
}
span.margin-percentage {
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 8px;
}

span.reference-data {
    color: var(--c-reference-data);
}

span.probe-data {
    color: var(--c-probe-data);
}

.divider {
    width: 1px;
    height: 60px;
    background: var(--c-light-grey);
}
.not-available {
  opacity: 0.25;
}
</style>
