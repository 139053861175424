<template>
  <div class="planning-step payfactors">
    <PlanningContainer v-if="currentTarget">
      <form>
        <h4>Sell-out margin</h4>
        <fieldset>
          <label>Margin</label>
          <NumberInput :step="0.1" :min="0.01" :max="1" v-model="currentTarget.sellout_to_sellin_factor"/>
        </fieldset>

        <h4>Media discount <span class="pay-factors-count">({{ payFactorsCount }})</span> </h4>
        <fieldset>
          <template v-for="(payFactor, index) in currentTarget.pay_factors" v-bind:key="index">
            <label>{{payFactor.label}}</label>
            <NumberInput v-model="payFactor.value" :min="0.01" :max="1" />
          </template>
        </fieldset>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import PlanningContainer from "./PlanningContainer.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'
import NumberInput from "@/components/ui/NumberInput.vue"

export default {
  name: 'PayFactorsStep',
  components: {
      PlanningContainer,
      NumberInput
  },
  mixins: [CurrentTargetMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
  computed: {
    payFactorsCount() {
      return this.currentTarget.pay_factors.length
    }
  }
}
</script>

<style scoped>
.text-input {
  margin-bottom: 20px;
}
.pay-factors-count {
  color: var(--c-button-blue);
}
</style>
