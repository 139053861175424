
<template>
  <div class="headline-badge">
    <inline-svg v-if="type == badgeTypeAI"
        :src="require('@/assets/svg/icons/right_sidebar/icon_label_ai_16px.svg')" width="32" height="16">
    </inline-svg>
    <inline-svg v-if="type == badgeTypeAIPlus"
        :src="require('@/assets/svg/icons/right_sidebar/icon_label_ai_plus_16px.svg')" width="32" height="16">
    </inline-svg>
  </div>
</template>

<script>

const BadgeType = Object.freeze( {
    AI: "ai",
    AIPLUS: "aiplus"
})
export default {
  name: 'HeadlineBadge',
  props: {
      type: String
  },
  data: function () {
      return {
          badgeTypeAI: BadgeType.AI,
          badgeTypeAIPlus: BadgeType.AIPLUS,
      }
  }
}
</script>

<style scoped>
.headline-badge {
  display: inline-block;
  transform: translate(-8px, -12px);
}
</style>
