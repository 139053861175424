<template>
  <div class="check-box" @click="toggle()">
    <inline-svg v-if="!checked"
        :src="require('@/assets/svg/icons/main_window/icon_checkbox_empty_20x20px.svg')" width="20" height="20">
    </inline-svg>
    <inline-svg v-if="checked"
        :src="require('@/assets/svg/icons/main_window/icon_checkbox_checked_20x20px.svg')" width="20" height="20">
    </inline-svg>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    checked: {
        type: Boolean,
        default: false
    },
  },
  data: function() {
    return {
    }
  },
  methods: {
    toggle() {
      this.$emit("checkbox:click")
    }
  }
};
</script>

<style scoped>
.check-box {
  width: 20px;
  height: 20px;
  display: inline-block;
}
</style>
