<template>
  <div class="dashboard flex vertical full-height">
    <div class="scrollable-container main">
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <WidgetContainer>
          <AnalysisWidget />
        </WidgetContainer>
      </WidgetRow>
      <WidgetRow flexGrow="0">
        <div class="lower-widget-row">
           <transition name="fade">
            <WidgetContainer v-if="!isInfoBarShown" class="nopadding stats-container">
              <StatsWidget class="stats-widget" />
            </WidgetContainer>
           </transition>
          <WidgetContainer>
            <LatestMediaplans class="latest-mediaplans-widget" />
          </WidgetContainer>
        </div>
      </WidgetRow>
    </div>
  </div>
</template>

<script>
import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import LatestMediaplans from "../components/widgets/LatestMediaplans.vue"
import WidgetRow from "../components/widgets/WidgetRow.vue"
import StatsWidget from "../components/widgets/StatsWidget.vue"
import AnalysisWidget from "../components/widgets/AnalysisWidget.vue"
import AnalysisMixin from "@/components/mixins/AnalysisMixin.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'DashboardPage',
  components: {
    WidgetContainer,
    LatestMediaplans,
    WidgetRow,
    StatsWidget,
    AnalysisWidget,
  },
  mixins: [AnalysisMixin],
  data: function() {
    return {
      isChartVisible: false
    }
  },
  computed: {
    isInfoBarShown() {
      return this.$store.state.info.isInfoBarShown
    },
  },
  created() {
    this.$store.dispatch("config/fetchStatus")
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit("ui/isFetchingChartData", true)

      vm.$store.commit("analysis/datasetIdForKeyAndType", {
        type: "probe",
        key: vm.getDatasetKey("probe"),
        id: vm.$store.getters["predictions/favoritePredictionId"]
      })

      vm.$store.commit("analysis/datasetIdForKeyAndType", {
        type: "reference",
        key: vm.getDatasetKey("reference"),
        id: -1
      })

      useUI(vm.$store).blockUI({ loading: true, complete: false, chart: true })

      Promise.all([
        new Promise(resolve => { vm.fetchReferenceData().then( () => resolve()) }),
        new Promise(resolve => { vm.fetchProbeData().then( () => resolve()) }),
      ]).finally(() => {
        useUI(vm.$store).unblockUI()
      })
    })
  },
}
</script>

<style scoped>
.lower-widget-row {
  /* grid-template-columns: 310px 1fr; */
  display: flex;
  width: 100%;
  flex-direction: row;
}
.stats-widget {
  min-width: 310px;
  max-width: 310px;
}
.stats-container {
  flex-grow: 0;
  margin-right: 20px;
}
.latest-mediaplans-widget {
  flex-grow: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: all .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  min-width: 0;
  max-width: 0;
  padding: 0;
  margin: 0;
}

</style>
