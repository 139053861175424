<template>
    <div :class="dropdownClazz">
        <div ref="optionsList" class="options-list" :class="isActive ? 'active' : ''">
          <div
            class="options-map"
            v-for="(optionSet, optionSetIndex) in availableOptions"
            v-bind:key="'optionset_' + optionSetIndex"
          >
            <ul>
              <li v-for="(option, index) in optionSet.options"
                :class="liClazz(optionSet, option)"
                v-bind:key="index"
                :value="option.value"
                @mouseup.stop="onSelectLi(optionSet, option)">
                <div class="dropdown-option">
                  <input
                    type="checkbox"
                    v-if="checkboxes && !option.isNoneOption"
                    :checked="Array.isArray(modelValue) && modelValue.includes(option.value)"
                  >
                  <div class="label" v-html="renderOptionLabel(option, index)"></div>
                  <svg v-show="isSelected(optionSet, option)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path d="m.75 7.932 4.879 4.879L15.25 3.19" style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px"/>
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div ref="valueBox" class="value-box">
            <div
              :class="clazz"
              @mousedown.prevent="toggleDropdown" @mouseleave="onMouseLeave"
              v-html="displayValue"
            >
            </div>
            <div v-if="showPrefix" :class="'prefix' + (isActive ? ' active' : '')">
                <slot name="prefix"></slot>
            </div>
            <div v-if="showSuffix" :class="'suffix' + (isActive ? ' active' : '')">
                <slot name="suffix"></slot>
            </div>
        </div>
    </div>
</template>

<script>

import Util from "../../util.js"

export default {
  name: "DropDown",
  components: {},
  props: {
    modelValue: {
      type: [Number, String, Boolean, Object],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Please select",
    },
    showPrefix: {
      type: Boolean,
    },
    showSuffix: {
      type: Boolean,
    },
    options: {
      type: [Array, Object]
    },
    allowNoSelection: { // allows null
      type: Boolean,
      default: true
    },
    noSelectionLabel: {
      type: String,
      default: "Reset selection"
    },
    noSelectionLabelSelected: {
      type: String,
      default: "No selection"
    },
    allowEmtpyMultiselection: {
      type: Boolean,
      default: true
    },
    emptyMultiSelectionValue: {
      type: [Array, null],
      default: function() {
        return []
      }
    },
    isMultiselect: {
      type: Boolean,
      default: false
    },
    checkboxes: {
      type: Boolean,
    },
    divider: {
      type: Boolean,
    },
    fixedTitle: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean
    },
    renderOptionLabelCallback: {
      type: Function
    }
  },
  data: function () {
    return {
      isActive: false,
      valueBoxNaturalRect: null,
      optionsListNaturalRect: null
    };
  },
  computed: {
    displayValue() {
      if (this.fixedTitle) {
        return this.fixedTitle
      }
      if (this.modelValue != null) {
        if (this.isMultiselect) {
          switch (this.modelValue.length) {
            case 1: return "1 option selected"
            case 0: return "No option selected"
            default: {
              let includesAll = (arr, target) => target.every(v => arr.includes(v));
              if (includesAll(this.options.map( o => o.value), this.modelValue)) {
                return `${this.modelValue.length} options selected`
              } else {
                return "Selection invalid"
              }
            }
          }
        }
        return this.valueLabel
      }
      return this.placeholder;
    },
    valueLabel() {
      let labels = []
      let label
      Object.values(this.availableOptions).forEach( o => {
        if (this.modelValue instanceof Object) {
          label = o.options?.find((opt) => opt.value != null && Object.values(this.modelValue).includes(opt.value) )?.label
        } else {
          let opt = o.options?.find((opt) => opt.value != null && opt.value == this.modelValue)
          label = opt ? this.renderOptionLabel(opt, -1) : ""
        }
        if (label) {
            labels.push(label)
        }
      })
      return labels.length ? labels.join(", ") : this.placeholder
    },
    clazz() {
      let s = "selectbox";
      s += this.isActive ? " active" : "";
      s += this.showPrefix ? " has-prefix" : "";
      s += this.showSuffix ? " has-suffix" : "";
      return s;
    },
    dropdownClazz() {
      let s = "dropdown"
      s += (this.modelValue != null ? " value-set" : "")
      s += (this.disabled ? " disabled" : "")
      return s
    },
    availableOptions() {
      let optionSets = []
      let availableOptions = this.options
      if (this.allowNoSelection) {
        availableOptions = [
        {
          label: this.modelValue ? this.noSelectionLabel : this.noSelectionLabelSelected,
          value: null,
          isNoneOption: true
        },
        ].concat(this.options);
      }
      if (Array.isArray(this.options)) {
        optionSets = [{
          key: null,
          options: availableOptions
        }]
      } else if (this.options instanceof Object) {
        Object.keys(this.options).forEach( key => {
          optionSets.push({
            key: key,
            options: this.options[key]
          })
        })
      }
      return optionSets;
    },
  },
  methods: {
    liClazz(optionSet, option) {
      let hasSeparator = Object.values(optionSet).findIndex( o => o?.isSeparator ) > -1
      let s = ""
      if (this.divider) {
        s += " has-divider"
      }
      if (option.isDisabled) {
        s += " is-disabled"
      }
      if (option.isSeparator) {
        s += " is-separator"
      } else if (hasSeparator) {
        s += " indented"
      }
      return s
    },
    limitString(str) {
      return str
    },
    isSelected(optionSet, option) {
      if (option.isSeparator) {
        return false
      }
      if (optionSet.key) {
        return this.modelValue[optionSet.key] == option.value
      } else {
        return this.modelValue == option.value
      }
    },
    considerMultiselectValueChange(model, newValue) {
        // use array for model when multiselect is true
        if (this.isMultiselect) {
          // check if model is an array, otherwise convert it into an array
          if (!Array.isArray(model)) {
            if (!model) {
              model = []
            } else {
              model = [model]
            }
          }
          if (!newValue) {
            model = null
          }
          // toggle element in array: remove if it was already included in the array, otherwise push
          else if (model.includes(newValue)) {
            let idx =model.indexOf(newValue)
            if (idx > -1) {
              model.splice(idx, 1)
            }
          } else {
            model.push(newValue)
          }
          if (model?.length == 0) {
            model = this.emptyMultiSelectionValue
          }
        } else {
          model = newValue
        }
        return model
    },
    onSelectLi(optionSet, option) {
      if (option.isDisabled || option.isSeparator) {
        return
      }
      var updatedValue
      if (optionSet.key) {
        let model = JSON.parse(JSON.stringify(this.modelValue))
        model[optionSet.key] = model[optionSet.key] == option.value ? null : option.value
        updatedValue = model
      } else {
        updatedValue = this.considerMultiselectValueChange(this.modelValue, option.value)
      }
      this.$emit("update:modelValue", updatedValue);

      if (!this.isMultiselect) {
        this.isActive = false
      }
    },
    toggleDropdown() {
      if (this.disabled) return
      this.isActive = !this.isActive;
    },
    handleDocumentClick(e) {
      let el = e.target?.classList?.contains("dropdown-option") ? e.target : e.target?.parentElement
      if (!el?.classList?.contains("dropdown-option")) {
        this.isActive = false;
      }
    },
    onBlur() {
      this.isActive = false
    },
    onResize() {
      this.isActive = false
    },
    onScroll() {
      this.isActive = false
    },
    onMouseLeave() {
      if (this.isActive) {
        document.removeEventListener("click", this.handleDocumentClick);
        document.addEventListener("click", this.handleDocumentClick);
      }
    },
    updateOptionsListStyle() {
        let el = this.$refs.optionsList;
        let valueBoxEl = this.$refs.valueBox;
        let valueBoxRect = this.valueBoxNaturalRect
        valueBoxEl.style.position = "fixed";
        valueBoxEl.style.left = valueBoxRect.left + "px";
        valueBoxEl.style.top = valueBoxRect.top + "px";
        valueBoxEl.style.width = valueBoxRect.width + "px";
        valueBoxEl.style.zIndex = 100000
        let distanceToBottom = (window.innerHeight - valueBoxRect.top - valueBoxRect.height) - this.optionsListNaturalRect.height
        el.style.position = "fixed";
        el.style.left = valueBoxRect.left + "px";
        el.style.top = valueBoxRect.top + valueBoxRect.height + "px";
        el.style.width = valueBoxRect.width + "px";
        el.style.zIndex = 100000
        if (distanceToBottom < 0) {
            el.style.overflowX = "auto"
            el.style.height = (this.optionsListNaturalRect.height + distanceToBottom) + "px";
        }
    },
    resetOptionsListStyle() {
        let el = this.$refs.optionsList;
        el.style.position = null
        el.style.top = null
        el.style.left = null
        el.style.width = null
        el.style.height = null
        el.style.overflowX = null
        el.style.zIndex = null
        let valueBoxEl = this.$refs.valueBox;
        valueBoxEl.style.position = null
        valueBoxEl.style.top = null
        valueBoxEl.style.left = null
        valueBoxEl.style.width = null
        valueBoxEl.style.height = null
        valueBoxEl.style.zIndex = null
    },
    renderOptionLabel(option, index) {
      let label
      if (this.renderOptionLabelCallback) {
        label = this.renderOptionLabelCallback(option, index)
      } else {
        label = option.label
      }
      return label
    },
  },
  watch: {
    isActive(active) {
      if (active) {
          this.$nextTick(() => {
              let rect = this.$refs.optionsList.getBoundingClientRect()
              this.optionsListNaturalRect = rect
              this.valueBoxNaturalRect = this.$refs.valueBox.getBoundingClientRect()
              this.updateOptionsListStyle();
              window.addEventListener("resize", this.onResize);
              setTimeout( () => {
                  let scrollParent = Util.getScrollParent(this.$el)
                  scrollParent.addEventListener("scroll", this.onScroll)
              }, 200)
          });
      } else {
          Util.getScrollParent(this.$el).removeEventListener("scroll", this.onScroll)
          this.resetOptionsListStyle()
          document.removeEventListener("click", this.handleDocumentClick);
          window.removeEventListener("resize", this.onResize);
      }
    }
  }
};
</script>

<style scoped>

.dropdown {
  position: relative;
  margin-bottom: 20px;
  height: var(--dropdown-height);
  min-width: 200px;
}
.dropdown.disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
.selectbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  border-radius: 7px;
  /* width: calc(100% - 40px); */
  height: var(--dropdown-height);
  padding: 0 40px 0 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgOS43NDNsLTYtNiAxLjQ4Ny0xLjQ4Nkw2IDYuNzdsNC41MTMtNC41MTNMMTIgMy43NDN6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-color: #F6F7F9;
  background-size: 12px 12px;
  background-position: right 20px top 50%;
  cursor: pointer;
}

.white-dropdown .selectbox:hover {
  border-color: #409aff;
}

.dropdown.white-dropdown {
  margin-bottom: 0;
  height: 40px;
}
.white-dropdown .selectbox {
  height: 40px;
  background-color: #fff;
  border: 1px solid #E4E7ED;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M9.133 3.857 6.027.751l-3.16 3.16M2.867 8.143l3.106 3.106 3.16-3.16' style='fill:none;stroke:%23181d29;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); */
}

.value-box {
  width: 100%;
  text-align: left;
}
.selectbox.has-prefix {
  padding: 0 40px 0 40px;
}
.selectbox select {
  display: none;
}
.selectbox.active {
  outline: 1px solid #F6F7F9;
  font-weight: 500;
  color: #000;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggdHJhbnNmb3JtPSJzY2FsZSgxIC0xKSB0cmFuc2xhdGUoMCAtMTIpIiBmaWxsPSIjM2Y1MWYyIiBkPSJNNiA5Ljc0M2wtNi02IDEuNDg3LTEuNDg2TDYgNi43N2w0LjUxMy00LjUxM0wxMiAzLjc0M3oiLz48L3N2Zz4=");
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  border-radius: 7px;
  width: calc(100% - 40px);
  height: var(--dropdown-height);
  color: #000;
  padding: 0 20px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgOS43NDNsLTYtNiAxLjQ4Ny0xLjQ4Nkw2IDYuNzdsNC41MTMtNC41MTNMMTIgMy43NDN6Ii8+PC9zdmc+")
    no-repeat;
  background-size: 12px 12px;
  background-position: right 20px top 50%;

}
select.has-prefix {
  padding: 0 40px 0 40px;
  width: calc(100% - 80px);
}

select.active {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgMi4yNTdsNiA2LTEuNDg3IDEuNDg2TDYgNS4yMyAxLjQ4NyA5Ljc0MyAwIDguMjU3eiIvPjwvc3ZnPg==");
  z-index: 999;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  outline: 1px solid #ECEDF0;
}

select:focus {
  outline: 1px solid #ECEDF0;
}

option {
  display: none;
}
.options-list {
  position: absolute;
  width: 100%;
  z-index: 998;
  flex-direction: column;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: #F6F7F9;
  display: none;
}
.options-list.active {
  display: block;
  transform: translate(0, -4px);
  outline: 1px solid #ECEDF0;
  z-index: 999;
  box-shadow: 0 0 40px rgba(102, 119, 142, 0.2);
}
ul {
  padding: 4px 0;
  margin: 0;
  /* position: absolute; */
  width: 100%;
  z-index: 998;
  text-align: left;
}
li {
  list-style-type: none;
  height: var(--dropdown-option-height);
  padding: 0;
  margin: 0;
  color: #000;
  cursor: pointer;
}
li:last-child {
  margin-bottom: 10px;
}
.dropdown-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
  height: var(--dropdown-option-height);
  margin: 0 20px;
}
li.has-divider .dropdown-option {
  border-bottom: 1px solid #e9ebf2;
}
li.is-separator {
  font-weight: 500;
}
li.is-disabled .dropdown-option div.label, li.is-disabled .dropdown-option svg {
  opacity: 0.35;
}
li.indented .dropdown-option {
  padding-left: 10px;
}
.options-map:last-child li.has-divider:last-child .dropdown-option {
  border-bottom: none;
}
.dropdown-option > div.label {
  flex-grow: 1;
  user-select: none;
}
.dropdown-option > svg {
  color: var(--primary-color);
  width: 12px;
  height: 12px;
}
li:hover {
  background-color: #F7F8FA;
  color: #1682F3;
}
li.is-separator:hover, li.is-disabled:hover {
  background-color: initial;
  cursor: default;
  color: initial;
}
.prefix {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: var(--dropdown-height);
  width: 40px;
}
.prefix.active {
  z-index: 999;
}
li input[type=checkbox] {
  margin-right: 10px;
  pointer-events: none;
}

.white-dropdown .options-list {
  background-color: #fff;
}
</style>


<style>
  :root {
    --dropdown-height: 48px;
    --dropdown-option-height: 36px;
  }
</style>
