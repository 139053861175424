import axios from "../api"

const initialState = {
  targets: [],
  currentTarget: null,
  error: null
}

export const ai = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    targets(state, targets) {
      state.targets = targets
    },
    currentTarget(state, target) {
      if (target) {
        let newTarget = JSON.parse(JSON.stringify(target))
        newTarget.channel_budgets?.forEach(budget => {
          budget.bounds = {
            annual_min: budget.annual_min,
            annual_max: budget.annual_max
          },
          budget.annual_min = 0
          budget.annual_max = null
        });
        state.currentTarget = newTarget
      } else {
        state.currentTarget = null
      }
    },
    scenarios(state, scenarios) {
      state.currentTarget.scenarios = scenarios
    },
    error(state, error) {
      state.error = error
    }
  },
  getters: {
    predefinedTargets(state) {
      return state.targets.filter( t => t.is_predefined == true)
    },
    customTargets(state) {
      return state.targets.filter( t => t.is_predefined == false)
    },
  },
  actions: {
    fetchTargets({
      commit,
      rootGetters
    }) {
      return axios.get("/ai_targets", {
          headers: rootGetters["user/authHeader"]
        })
        .then((response) => {
          commit("targets", response.data.data.targets)
        })
        .catch((response) => {
          commit("error", response.message)
          throw (new Error("Failed fetching ai_targets"))
        })
    },
    fetchAlJobs() {
      // {{base_url}}/jobs
    },
    fetchJobsByStatus() {
      //{{base_url}}/jobs/by_status/RUNNING
    },
    updateTargetConfig() {
      // patch
      // {{base_url}}/ai_targets
    },


    startOptimization({
      commit,
      rootGetters
    }, targetConfig) {
      let cfg = JSON.parse(JSON.stringify(targetConfig))
      if (!cfg) {
        throw new Error("No config given")
      }
      cfg.channel_budgets?.forEach( budget => {
        // see currentTarget -> budget.annual_max comes with -1 from server and is replaced with null
        // to display "Unlimited" in placeholder. Before posting, we replace null with -1 again to comply with
        // the current api requirements (TBD, maybe we should change this).
        if (budget.annual_min == null) {
          budget.annual_min = 0
        }
        if (budget.annual_max == null) {
          budget.annual_max = -1
        }
      })

      cfg.start_optimization = true
      cfg.save_as_template = false
      return axios.post("/ai_targets", cfg, {
        headers: rootGetters["user/authHeader"]
      })
      .then((response) => {
        console.log("startOptimization response", response)
        //commit("currentPrediction", response.data.data)
      })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error("Failed executing prediction"))
      })
    },

    createNewTemplate({
      commit,
      rootGetters
    }, targetConfig) {
      let cfg = JSON.parse(JSON.stringify(targetConfig))
      cfg.start_optimization = false
      cfg.save_as_template = true
      return axios.post("/ai_targets", cfg, {
        headers: rootGetters["user/authHeader"]
      })
      .then((response) => {
        console.log("createNewTemplate response", response)
        //commit("currentPrediction", response.data.data)
      })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error("Failed executing prediction"))
      })
    },

    updateTarget({
      rootGetters
    }, payload) {
      return axios.patch(`/ai_targets/${payload.id}`, payload,
      {
        headers: rootGetters["user/authHeader"]
      })
    },

    deleteTarget({
      rootGetters
    }, id) {
      return axios.delete(`/ai_targets/${id}`,
      {
        headers: rootGetters["user/authHeader"]
      })
    },

  }
};