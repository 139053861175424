<template>
  <div :class="'predefined-report-tile' + (selected ? ' active' : '')" @click="onClick">
    <div class="icon">{{ report.iconLabel }}</div>
    <div class="text">
      <h3>{{ report.title }}</h3>
      <span class="description">{{ report.description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PredefinedReportTile',
  props: {
      report: {
        type: Object,
      },
      selected: {
        type: Boolean
      }
  },
  data: function () {
    return {
    }
  },
  methods: {
      onClick() {

      }
  }
}
</script>

<style scoped>
.predefined-report-tile {
    background-color: #F3F5FB;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    width: 420px;
    height: 94px;
    border-radius: 7px;
    cursor: pointer;
    margin: 0 10px 10px 0;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 0 16px;
    border: 1px solid #E6EBF7;
}

.icon {
  width: 44px;
  height: 44px;
  background-color: rgba(67,80,233, 0.1);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid #4350E9;
  margin-right: 20px;
  text-transform: uppercase;
}

h3 {
  font-size: 18px;
  letter-spacing: normal;
  margin: 26px 0 3px 0;
  color: var(--c-dark-blue);
}

.text {
  text-align: left;
  height: 100%;
  font-size: 12px;
  color: rgba(30,37,102, 0.53)
}
span.description {
  color: var(--c-dark-blue);
  letter-spacing: normal;
  font-weight: 300;
}
.simple-tile.active, .simple-tile.active:hover {
    background: var(--c-button-blue);
    color: #fff;
}

.simple-tile:hover {
    background: var(--c-soft-grey);
}

</style>
