
<template>
  <div class="error-message">
    <div class="message">{{ errorMessage }}</div>
    <div class="actions"><button class="button warning" @click="onClose">Close</button></div>
  </div>
</template>

<script>

export default {
  name: 'ErrorMessage',
  computed: {
    errorMessage() {
      return this.$store.state.ui.error
    }
  },
  methods: {
    onClose() {
      this.$store.commit("ui/error", null)
    }
  }
}
</script>

<style scoped>
.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  background: rgba(255, 0, 0, 0.5);
  margin-left: 20px;
  color: white;
  bottom: 0;
  left: 0;
  width: 50%;
  min-height: 80px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.message {
  flex-grow: 1;
  text-align: left;
  padding-left: 20px;
  user-select: all;
}
.actions {
  padding-right: 20px;
}
</style>
