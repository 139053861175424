<template>
  <input
    ref="inputRef"
    :class="clazz"
    type="text"
    :min="min"
    :max="max"
    :value="effectiveValue"
    :placeholder="placeholder"
    @input="onInput"
    @focus="onFocus"
    @change="onChange"
    @blur="onBlur">
</template>

<script>

import Util from "@/util.js"

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    autoSelectOnFocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
  },
  data: function() {
    return {
      hasError: false,
      isFocused: false,
      formattedValue: Util.formatCurrency(this.modelValue, this.$store.state.config.currency)
    }
  },
  computed: {
    effectiveValue() {
      return this.modelValue ? this.formattedValue : null
    },
    clazz() {
      let s = "text-input"
      if (this.hasError) {
        s += " has-error"
      }
      return s
    }
  },
  methods: {
    onFocus(e) {
      this.isFocused = true
      if (this.autoSelectOnFocus) {
        this.$nextTick( () => {
          e.target.select()
        })
      }
    },
    onChange(e) {
      if (e.target.value == "") {
        this.$emit("update:modelValue", null)
        return
      }
      let number = this.parse(e.target.value)
      this.hasError = number == null
      if (!this.hasError) {
        this.$emit("update:modelValue", number)
        this.formattedValue = Util.formatCurrency(number, this.$store.state.config.currency)
        e.target.value = this.formattedValue
      }
      this.clearErrorDelayed()
    },
    onInput() {
    },
    isOutOfBounds(number) {
      return (this.min != null && number < this.min) || (this.max != null && number > this.max)
    },
    parse(inputValue) {
      let number = Util.parseCurrency(inputValue, this.$store.state.config.currency)
      let isNotANumber = Number.isNaN(number)
      let isOutOfBounds = this.isOutOfBounds(number)
      if (isNotANumber) {
        this.$emit("parseerror:nan", inputValue)
      }
      if (isOutOfBounds) {
        this.$emit("parseerror:outofbounds", number)
      }

      if  (isNotANumber || isOutOfBounds) {
        return null
      }
      return number
    },
    clearErrorDelayed() {
      setTimeout( () => {
        this.hasError = false
      }, 750)
    }
  },
  watch: {
    modelValue(newValue) {
      this.formattedValue = Util.formatCurrency(newValue, this.$store.state.config.currency)
    }
  }
}
</script>
<style scoped>

.text-input {
  background: var(--c-white-grey);
  height: 54px;
  border-radius: 7px;
  color: var(--c-dark-blue);
  padding: 0 20px;
  border: none;
  font-size: 16px;
  transition: all 0.35s ease-in-out;
  border: 2px solid transparent;
}

.text-input.has-error {
  border: 2px solid var(--c-red);
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.text-input.has-error:focus {
  outline: none;
}

.debuginfo {
  font-size: 10px;
  color: #888;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>
