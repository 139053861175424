<template>
  <input ref="inputRef"
    :class="clazz"
    :type="inputType"
    :min="inputMin"
    :max="inputMax"
    :step="step"
    :value="modelValue"
    @input="onInput"
    >
</template>

<script>

export default {
  name: 'TextInput',
  props: {
    modelValue: {
      type: [Number, String],
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: 1
    },
    numbersOnly: {
      type: Boolean
    },
  },
  data: function() {
    return {
      validates: true
    }
  },
  computed: {
    inputType() {
      return this.numbersOnly ? "number" : "text"
    },
    inputMin() {
      if (this.min == -1) {
        return Number.MAX_SAFE_INTEGER
      } else if (this.min == null || this.min == undefined) {
        return Number.NEGATIVE_INFINITY
      }
      return this.min
    },
    inputMax() {
      return (this.max == -1 || this.max == undefined || this.max == null) ? Number.MAX_SAFE_INTEGER : this.max
    },
    clazz() {
      let s = "text-input"
      if (!this.validates) {
        s += " invalid"
      }
      return s
    }
  },
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e.target.value)
    },
    onChange(e) {
      this.validate(e)
      let value = e.target.value
      if (value == "") {
        this.$emit("update:modelValue", null)
      }
      if (!this.validates) {
        this.$refs.inputRef.value = this.modelValue
        this.validates = true
      }
    },
    validate(e) {
      if (this.numbersOnly) {
        let value = e.target.value
        if (value.length > 0) { // note: minus sign will result in value=""
          if (value < this.inputMin || value > this.inputMax ) {
            this.validates = false
          } else {
            this.onChange(e)
          }
        }
      } else {
        this.onChange(e)
      }
    }
  },
}
</script>
<style scoped>

.text-input {
  background: var(--c-white-grey);
  height: 54px;
  border-radius: 7px;
  color: var(--c-dark-blue);
  padding: 0 20px;
  border: none;
  font-size: 16px;
}

.text-input.invalid {
  outline: 1px solid  var(--c-red);
}

</style>
