import { createWebHistory, createRouter } from "vue-router"
import DashboardPage from "@/views/DashboardPage.vue"
import AnalysisPage from "@/views/AnalysisPage.vue"
import PredictionsPage from "@/views/PredictionsPage.vue"
import MediaplansPage from "@/views/MediaplansPage.vue"
import LoginPage from "@/views/LoginPage.vue"
import LogoutPage from "@/views/LogoutPage.vue"
import ReportPage from "@/views/ReportPage.vue"
import ReportsPage from "@/views/ReportsPage.vue"
import InternalInfoPage from "@/views/InternalInfoPage.vue"

import AppLayout from "@/AppLayout.vue"
import PublicLayout from "@/PublicLayout.vue"
import WelcomeSidebar from "@/components/sidebar/WelcomeSidebar.vue"
import AnalysisSidebar from "@/components/sidebar/AnalysisSidebar.vue"
import PredictionSidebar from "@/components/sidebar/PredictionSidebar.vue"
import MediaplanSidebar from "@/components/sidebar/MediaplanSidebar.vue"
import ReportSidebar from "@/components/sidebar/ReportSidebar.vue"
import ReportsSidebar from "@/components/sidebar/ReportsSidebar.vue"
import ReportFiltersSidebar from "@/components/sidebar/ReportFiltersSidebar.vue"
import InternalInfoSidebar from "@/components/sidebar/InternalInfoSidebar.vue"

import TargetStep from "@/components/sidebar/planning/TargetStep.vue"
import BudgetStep from "@/components/sidebar/planning/BudgetStep.vue"
import PayFactorsStep from "@/components/sidebar/planning/PayFactorsStep.vue"
import OverviewStep from "@/components/sidebar/planning/OverviewStep.vue"
import NameStep from "@/components/sidebar/planning/NameStep.vue"
import ScenariosStep from "@/components/sidebar/planning/ScenariosStep.vue"
import StartPlanning from "@/components/sidebar/planning/StartPlanning.vue"
import InProgress from "@/components/sidebar/planning/InProgress.vue"


import store from "@/store"

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { pageTitle: "Login | PIA by SimCog" },
    component: PublicLayout,
    children: [
      {
        name: 'LoginPage',
        path: '',
        component: LoginPage,
      }
    ]
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { pageTitle: "Logout | PIA by SimCog" },
    component: AppLayout,
    children: [
      {
        name: 'LogoutPage',
        path: '',
        component: LogoutPage,
      }
    ]
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      datasetKeys: {
        reference: "dashboard",
        probe: "dashboard"
      }
    },
    component: AppLayout,
    children: [
      {
        name: 'DashboardPage',
        path: '',
        components: {
          default: DashboardPage,
          sidebar: WelcomeSidebar,
        }
      }
    ]
  },
  { path: '/planning', redirect: '/planning/target' },
  {
    path: "/planning/target",
    name: "Mediaplanung Ziel",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/target",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Mediaplanung Ziel Child',
        components: {
          default: DashboardPage,
          sidebar: TargetStep,
        }
      }
    ]
  },
  {
    path: "/planning/budget",
    name: "Mediaplanung Budget",
    component: AppLayout,
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/budget",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    children: [
      {
        name: 'Mediaplanung Budget Child',
        path: '',
        components: {
          default: DashboardPage,
          sidebar: BudgetStep,
        }
      }
    ]
  },
  {
    path: "/planning/payfactors",
    name: "Mediaplanung Pay-Faktoren",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/payfactors",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Mediaplanung Pay-Faktoren Child',
        components: {
          default: DashboardPage,
          sidebar: PayFactorsStep,
        }
      }
    ]
  },
  {
    path: "/planning/overview",
    name: "Mediaplanung Übersicht",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/overview",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Mediaplanung Overview Child',
        components: {
          default: DashboardPage,
          sidebar: OverviewStep,
        }
      }
    ],
  },
  {
    path: "/planning/name",
    name: "Mediaplanung Name",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/name",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Mediaplanung Name Child",
        components: {
          default: DashboardPage,
          sidebar: NameStep,
        }
      }
    ]
  },
  {
    path: "/planning/scenarios",
    name: "Mediaplanung Szenarien",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      showInfoBar: "/planning/scenarios",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Mediaplanung Szenarien Child",
        components: {
          default: DashboardPage,
          sidebar: ScenariosStep,
        }
      }
    ]
  },
  {
    path: "/planning/start",
    name: "Mediaplanung Start",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Mediaplanung Start Child",
        components: {
          default: DashboardPage,
          sidebar: StartPlanning,
        }
      }
    ]
  },
  {
    path: "/planning/inprogress",
    name: "Mediaplanung in Arbeit",
    meta: {
      pageTitle: "Dashboard | PIA by SimCog",
      datasetKeys: {
        reference: "planning",
        probe: "planning"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Mediaplanung in Arbeit Child",
        components: {
          default: DashboardPage,
          sidebar: InProgress,
        }
      }
    ]
  },
  {
    path: "/analysis",
    name: "Analyse",
    meta: {
      pageTitle: "Analyse | PIA by SimCog",
      datasetKeys: {
        reference: "analysis",
        probe: "analysis"
      }
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Analyse Child",
        components: {
          default: AnalysisPage,
          sidebar: AnalysisSidebar
        }
      }
    ]
  },
  {
    path: "/predictions",
    name: "Prognosen",
    meta: {
      pageTitle: "Forecasts | PIA by SimCog",
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Prognosen Child",
        components: {
          default: PredictionsPage,
          sidebar: PredictionSidebar,
        },
      }
    ]
  },
  {
    path: "/predictions/scenario",
    name: "prediction for Mediaplan",
    meta: {
      pageTitle: "Forecasts | PIA by SimCog",
      showInfoBar: "/predictions/scenario"
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "prediction for Mediaplan Child",
        components: {
          default: PredictionsPage,
          sidebar: PredictionSidebar,
        },
      }
    ]
  },
  {
    path: "/predictions/current",
    name: "Current Prediction",
    meta: {
      pageTitle: "Forecasts | PIA by SimCog",
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Current Prediction Child",
        components: {
          default: PredictionsPage,
          sidebar: PredictionSidebar,
        },
      }
    ]
  },
  {
    path: "/mediaplans",
    name: "Mediaplans",
    meta: {
      pageTitle: "Media plans | PIA by SimCog",
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Mediaplans Child",
        components: {
          default: MediaplansPage,
          sidebar: MediaplanSidebar,
        }
      }
    ]
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      pageTitle: "Reports | PIA by SimCog",
      segmentSelectorDisabled: true
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Reports Child",
        components: {
          default: ReportsPage,
          sidebar: ReportsSidebar,
        }
      }
    ]
  },
  {
    path: "/reports/:reportId",
    name: "Report",
    meta: {
      pageTitle: "Report | PIA by SimCog",
      segmentSelectorDisabled: true
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Report Child",
        components: {
          default: ReportPage,
          sidebar: ReportSidebar,
        }
      }
    ]
  },
  {
    path: "/reports/:reportId/views/:viewIndex",
    name: "Report View",
    meta: {
      pageTitle: "Report | PIA by SimCog",
      segmentSelectorDisabled: true
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Report View Child",
        components: {
          default: ReportPage,
          sidebar: ReportSidebar,
        }
      }
    ]
  },
  {
    path: "/reports/:reportId/views/:viewIndex/datasets/:datasetIndex/filters",
    name: "Report View Filter",
    meta: {
      pageTitle: "Report | PIA by SimCog",
      segmentSelectorDisabled: true
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Report Filters Child",
        meta: {
          showBackButton: true,
          backPath: "/reports/:reportId/views/:viewIndex"
        },
        components: {
          default: ReportPage,
          sidebar: ReportFiltersSidebar,
        }
      }
    ]
  },
  {
    path: "/internal",
    name: "Project information",
    meta: {
      pageTitle: "Project Information | PIA by SimCog",
      segmentSelectorDisabled: true
    },
    component: AppLayout,
    children: [
      {
        path: '/internal',
        name: "Internal Information Child",
        meta: {},
        components: {
          default: InternalInfoPage,
          sidebar: InternalInfoSidebar,
        }
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach(
  (to, from, next) => {
    let isAuthenticated = store.state.user.token
    if (to.path != "/login") {
      if (!isAuthenticated) {
        next({
          path: '/login'
        })
      } else {
        if (store.getters["user/isTokenRefreshNeeded"]) {
          store.dispatch("user/renewToken").then( () => {
            next()
          })
          .catch ( () => {
            next({ path: '/login' })
          })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  }
)
router.afterEach( (to) => {
  store.commit("info/isInfoBarShown", to.meta.showInfoBar != null)
  store.commit("info/infoBarPath", to.meta.showInfoBar)
  document.title = to.meta.pageTitle || "PIA by SimCog"
})

export default router;
