<template>
  <div class="reports-sidebar">
    <SidebarContent>
        <template v-slot:content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
              Create reports based on predefined views or create your own report.
            </h1>
            <div class="subtitle">You can create revenue and/or media&nbsp;spending reports for all included brands. Look at detailed data for specific SKUs, regions or media channels and share your reports with a link or as an Excel export.</div>
          </div>
        </template>
        <template v-slot:actions>
          <!-- <div class="action-buttons">
            <button class="button primary tall" @click="onAnalyze">Analyze for me</button>
            <button class="button ghost tall" @click="onPlan">Plan for me</button>
          </div> -->
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'ReportsSidebar',
  components: {
      SidebarContent,
  },
  props: {
  },
  methods: {
  }
}
</script>

<style scoped>
.reports-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h1 {
    font-weight: 500;
    font-size: 32px;
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
