<template>
  <div>
  </div>
</template>

<script>

export default {
  data: () => {
    return {}
  },
  computed: {
  },
  methods: {
    getSourceTypeStyle(view, sourceType) {
      if (sourceType == null) return null
      view.style = view.style ?? {}
      view.style.sourceType = view.style.sourceType ?? {}
      view.style.sourceType[sourceType] = view.style.sourceType[sourceType] ?? {}
      return view.style.sourceType[sourceType]
    },
    getSourceTypeConfig(sourceType) {
      if (sourceType == null) return null
      const labels = this.$store.getters["config/labels"]
      return labels.views.datasets.types[sourceType]
    },
    getDimensionName(sourceType) {
      return this.getSourceTypeConfig(sourceType)?.dimension
    },
    getDimension(sourceType) {
      if (sourceType == null) return null
      const labels = this.$store.getters["config/labels"]
      const sourceObj = labels.views.datasets.types[sourceType]
      return labels.views.datasets.dimensions[sourceObj?.dimension]
    },
    stackingSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.stacked
    },
    toggleStacking(view, sourceType) {
      let ax = this.getSourceTypeStyle(view, sourceType)
      ax.stacked = !ax.stacked
    },
    logAxisSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.log
    },
    toggleLogAxis(view, sourceType) {
      let style = this.getSourceTypeStyle(view, sourceType)
      style.log = !style.log
    },
    relativeAxisSelected(view, sourceType) {
      const style = this.getSourceTypeStyle(view, sourceType)
      return style?.relative
    },
    toggleRelativeAxis(view, sourceType) {
      let ax = this.getSourceTypeStyle(view, sourceType)
      ax.relative = !ax.relative
    },
    stackingAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowStacking
    },
    logAxisAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowLogAxis
    },
    relativeAxisAllowed(sourceType) {
      return this.getDimension(sourceType)?.allowRelativeAxis
    },
    anyDrawingOptionEnabled(sourceType) {
      const dim = this.getDimension(sourceType)
      if (dim == null) return false
      return dim.allowStacking || dim.allowLogAxis || dim.allowRelativeAxis
    },
  },
}
</script>

<style scoped>
</style>
