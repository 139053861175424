import axios from "../api"
import Util from "@/util.js"

export const baseColors = [
  { label: 'Red', value: 'red' },
  { label: 'Green', value: 'green' },
  { label: 'Blue', value: 'blue' },
  { label: 'Cyan', value: 'cyan' },
  { label: 'Magenta', value: 'magenta' },
  { label: 'Yellow', value: 'yellow' },
  { label: 'Black', value: 'black' },
  { label: 'Lime', value: 'lime' },
  { label: 'Maroon', value: 'maroon' },
  { label: 'Navy', value: 'navy' },
  { label: 'Olive', value: 'olive' },
  { label: 'Purple', value: 'purple' },
  { label: 'Teal', value: 'teal' },
  { label: 'Orange', value: 'orange' },
  { label: 'Brown', value: 'brown' },
  { label: 'Pink', value: 'pink' },
  { label: 'Coral', value: 'coral' },
  { label: 'Gold', value: 'gold' },
  { label: 'Indigo', value: 'indigo' },
  { label: 'Violet', value: 'violet' },
  { label: 'Beige', value: 'beige' },
  { label: 'Turquoise', value: 'turquoise' },
];

export const palettes = {
  dutchField: ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300",
    "#dc0ab4", "#b3d4ff", "#00bfa0"],
  riverNight: ["#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7",
    "#5ad45a", "#8be04e", "#ebdc78"],
  otcClassic: [
    "#FF9000", "#51E06B", "#009BFF", "#D62D82", "#FFD200", "#FF5745", "#239A3F",
    "#00D2DE", "#3F51F2", "#FF45F1", "#B100FF", "#BAD526", "#CE1010", "#BE740D",
    "#7A14BA", "#B96900", "#3BA34D", "#0070B9", "#9B205F", "#B99800", "#B93F32",
    "#1A702E", "#0098A1", "#2E3BAF", "#B932AF", "#8100B9", "#879A1B", "#950C0C",
    "#8A5409", "#590E87", "#FFAF46", "#81E994", "#46B7FF", "#E167A5", "#FFDF46",
    "#FF8579", "#60B674", "#46DFE7", "#7481F6", "#FF79F5", "#C746FF", "#CDE062",
    "#DB5252", "#D09B50", "#9F55CD"],
  hls: ["#db5f57", "#db9057", "#dbc257", "#c3db57", "#91db57", "#5fdb57",
    "#57db80", "#57dbb2", "#57d3db", "#57a2db", "#5770db", "#6f57db",
    "#a157db", "#d357db", "#db57b2", "#db5780"],
  tab10: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b",
    "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", "#1f77b4", "#ff7f0e",
    "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  muted: ["#4878d0", "#ee854a", "#6acc64", "#d65f5f", "#956cb4", "#8c613c",
    "#dc7ec0", "#797979", "#d5bb67", "#82c6e2", "#4878d0", "#ee854a",
    "#6acc64", "#d65f5f", "#956cb4", "#8c613c"],
  colorblind: ["#0173b2", "#de8f05", "#029e73", "#d55e00", "#cc78bc", "#ca9161",
      "#fbafe4", "#949494", "#ece133", "#56b4e9", "#0173b2", "#de8f05",
      "#029e73", "#d55e00", "#cc78bc", "#ca9161"],
  retroMetro: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32",
    "#87bc45", "#27aeef", "#b33dc6"],
}

const emptyDataset = {
  "style": {
    "chartType": "line",
  },
}
const emptyView = {
  "datasets": [emptyDataset]
}

const initialState = {
  reports: [],
  filters: [],
  currentReport: null,
  currentViewIndex: 0,
  currentDatasetIndex: 0,
  error: null,
  emptyDataset: emptyDataset,
  emptyReport: {
    "title": "New report",
    "save" : false,
    "transient": true,
    "views": [emptyView]
  }
}

function ensureDatasetColors(report) {
  report.views?.forEach( view => {
    let baseColor = null
    let usedColors = view.datasets.reduce( (used, dataset) => {
      used.push(dataset.style.color)
      return used
    }, [])

    view.datasets?.forEach( dataset => {
      if (!dataset.style.color) {
        for (var i=0; i<baseColors.length; i++) {
          baseColor = Util.getNextItem(baseColors.map( c => c.value), baseColor)
          if (!usedColors.includes(baseColor)) break;
        }
        dataset.style.color = baseColor
      }
    })
  })
  return report
}

function ensureChartType(report) {
  report.views?.forEach( view => {
    view.datasets?.forEach( dataset => {
      if (!dataset.style.chartType) {
        dataset.style.chartType = "line"
      }
      if (!dataset.style.lineType) {
        dataset.style.lineType = "line"
      }
    })
  })
  return report
}

function prepareReportForSending(report) {
  let copy = JSON.parse(JSON.stringify(report))
  copy.views?.forEach( view => {
    delete view.chartData
    view.datasets?.forEach( dataset => {
      delete dataset.chartData
    })
  })
  return copy
}

function expandObjects(objs, key) {
  // Takes a list of objects and checks for obj.key - if array, replicates the object for each
  // value of the array.
  // Example: expandObject([{a: 1, b: [2, 3]}], "b") ==> [{a: 1, b: 2}, {a: 1, b: 3}]
  let result = []
  for (const obj of objs) {
    if (Array.isArray(obj[key])) {
      for (const value of obj[key]) {
        result.push({...obj, [key]: value})
      }
    } else {
      result.push({...obj})
    }
  }
  return result
}

function expandFilters(filters) {
  // The filters are allowed to contain arrays if multiple similar options are allowed.
  // Internally every valid filter combination must be listed.
  // Here, "channel" and "region" arrays are expanded
  return filters.map((f) => ({
      ...f,
      options: expandObjects(expandObjects(f.options, "channel"), "region")
    })
  )
}

export const reports = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    reports(state, reports) {
      state.reports = reports
    },
    filters(state, filters) {
      state.filters = filters
    },
    currentReport(state, currentReport) {
      state.currentReport = currentReport
    },
    currentViewIndex(state, currentViewIndex) {
      state.currentViewIndex = currentViewIndex
    },
    currentDatasetIndex(state, currentDatasetIndex) {
      state.currentDatasetIndex = currentDatasetIndex
    },
    error(state, error) {
      state.error = error
    },
  },
  getters: {
    reports( state ) {
      return state.reports
    },
    filters( state ) {
      return state.filters
    },
    currentReport( state ) {
      return state.currentReport
    },
    currentView( state ) {
      return state.currentReport?.views?.[state.currentViewIndex] || null
    },
    currentDataset( state ) {
      return state.currentReport?.views?.[state.currentViewIndex]?.datasets[state.currentDatasetIndex] || null
    },
    currentViewIndex( state ) {
      return state.currentViewIndex
    },
    currentDatasetIndex( state ) {
      return state.currentDatasetIndex
    },
    emptyReport( state ) {
      return ensureDatasetColors(JSON.parse(JSON.stringify(state.emptyReport)))
    },
  },
  actions: {
    fetchFilters({
      commit,
      rootGetters
    }) {
      let url = "/reports/filter"
      axios.get(url, {
          headers: rootGetters["user/authHeader"]
      }).then((response) => {
        commit("filters", expandFilters(response.data.data))
      })
      .catch((err) => {
        commit("ui/error", err, { root: true })
        //throw (new Error("Failed fetching predictions"))
      })
    },
    newReport({
      commit,
      getters,
      dispatch
    }) {
      return new Promise( (resolve) => {
        dispatch("updateTransient", getters["emptyReport"]).then( report => {
          commit("currentReport", report)
          resolve(report)
        })
      })
    },
    addNewDataset({
      dispatch
    }, payload) {
      let newDataset = JSON.parse(JSON.stringify(emptyDataset))
      let datasets = payload.report.views[payload.viewIndex].datasets
      datasets.push(newDataset)
      return dispatch("updateTransient", ensureDatasetColors(payload.report))
    },
    setDatasets({state, dispatch}, payload) {
      state.currentReport.views[payload.viewIndex].datasets = payload.datasets
      return dispatch("updateTransient", payload.report)
    },
    duplicateDataset({state, dispatch}, payload) {
      let datasets = state.currentReport.views[payload.viewIndex].datasets
      datasets.splice(
        payload.datasetIndex, 0, JSON.parse(JSON.stringify(datasets[payload.datasetIndex]))
      )
      return dispatch("updateTransient", payload.report)
    },
    deleteDataset({
      state,
      dispatch
    }, payload) {
      state.currentReport.views[payload.viewIndex].datasets.splice(payload.datasetIndex, 1)
      return dispatch("updateTransient", payload.report)
    },
    addNewView({
      dispatch
    }, report) {
      let newView = JSON.parse(JSON.stringify(emptyView))
      report.views.push(newView)
      return dispatch("updateTransient", ensureDatasetColors(report))
    },
    deleteView({
      dispatch
    }, payload) {
      payload.report.views.splice(payload.viewIndex, 1)
      return dispatch("updateTransient", payload.report)
    },
    fetchReport({
      commit,
      rootGetters
    }, reportId) {
      return new Promise( (resolve, reject) => {
        axios.get("/reports/" + reportId, {
          headers: rootGetters["user/authHeader"]
        }).then((response) => {
          let report = ensureChartType(response.data.data)
          report = ensureDatasetColors(report)
          resolve(report)
        })
        .catch((err) => {
          commit("ui/error", err, { root: true })
          reject(err)
        })
      })
    },
    fetchReports({
      commit,
      rootGetters
    }) {
      let url = "/reports"
      axios.get(url, {
          headers: rootGetters["user/authHeader"]
      }).then((response) => {
        commit("reports", response.data.data)
      })
      .catch((err) => {
        commit("ui/error", err, { root: true })
        //throw (new Error("Failed fetching predictions"))
      })
    },
    updateReportLockState({rootGetters, commit}, payload) {
      // special PUT request to unlock report (locking also possible via regular update)
      const id = payload.id
      const locked = payload.locked
      const url = locked ? `/reports/${id}/lock` : `/reports/${id}/unlock`
      return new Promise( (resolve, reject) => {
        axios.patch(url, {}, {
          headers: rootGetters["user/authHeader"]
        }).then( response => {
          commit("currentReport", response.data.data)
          resolve(response.data.data)
        }).catch( err => {
          reject(err)
        })
      })
    },
    deleteReport({
      rootGetters
    }, id) {
      return axios.delete(`/reports/${id}`, {
        headers: rootGetters["user/authHeader"]
      })
    },
    updateReport({
      rootGetters,
      commit
    }, report) {
      return new Promise( (resolve, reject) => {
        report.save = !report.predefined
        delete report.transient
        axios.put(`/reports/${report.id}`, prepareReportForSending(report),
        {
          headers: rootGetters["user/authHeader"]
        }).then( response => {
          commit("currentReport", response.data.data)
          resolve(response.data.data)
        }).catch( err => {
          reject(err)
        })
      })
    },
    updateTransient({
      dispatch,
      commit
    }, report) {
      report.save = false
      report.transient = true
      return new Promise( (resolve, reject) => {
        dispatch("addReport", prepareReportForSending(report)).then( responseReport => {
          responseReport.transient = true
          commit("currentReport", responseReport)
          resolve(responseReport)
        }).catch( err => {
          reject(err)
        })
      })
    },
    addReport({
      rootGetters
    }, report) {
      return new Promise( (resolve, reject) => {
        axios.post(`/reports`, report, {
          headers: rootGetters["user/authHeader"]
        }).then( response => {
          resolve(response.data.data)
        }).catch( err => {
          reject(err)
        })
      })
    },
    downloadReport({ rootGetters }, id) {
      return axios.get(`/reports/${id}/download?asbase64json`, {
        headers: rootGetters["user/authHeader"],
        //responseType: 'blob',
      })
    },
    downloadTransientReport({ rootGetters }, report) {
      let authHeader = rootGetters["user/authHeader"]
      return axios.post(`/reports/download?asbase64json`, report, {
        headers: authHeader,
        //responseType: 'blob',
      })
    },
  }
};
