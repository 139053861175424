<template>
  <div class="prediction-list item-collection">
    <ul>
        <li class="header">
          <div></div>
          <div :class="$store.state.predictions.sortInfo.property == 'name' ? 'active' : ''">Name <SortActions property="name" label="Name" @sort="onSort" /></div>
          <div :class="$store.state.predictions.sortInfo.property == 'created_timestamp' ? 'active' : ''">Date <SortActions property="created_timestamp" label="Date" @sort="onSort" /></div>
          <div :class="$store.state.predictions.sortInfo.property == 'mediaplanung_name' ? 'active' : ''">Media plan <SortActions property="mediaplanung_name" label="Media plan" @sort="onSort" /></div>
          <div :class="$store.state.predictions.sortInfo.property == totalValueSortProperty ? 'total-value active' : 'total-value'">Forecast sum <SortActions :property="totalValueSortProperty" label="Sum" @sort="onSort" /></div>
          <div></div>
        </li>
        <li v-for="(item, index) in predictions" v-bind:key="index" :class="clazz(item)">
            <div class="icon">
              <inline-svg
                  :src="require('@/assets/svg/icons/forecast/icon_forecast_small_25x25px.svg')" width="25" height="25">
              </inline-svg>
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="date nowrap">{{ dateInfos(item).start }}
              <InfoHud v-if="dateInfos(item).start">
                <slot>
                  <DateInfo :item="item" />
                </slot>
              </InfoHud>
            </div>
            <div class="label">
              {{ item.mediaplanung_name }}
              <InfoHud v-if="item.scenarios.length">
                <slot v-for="(scenario, index) in item.scenarios" v-bind:key="index">
                  {{ scenario }}
                </slot>
              </InfoHud>
            </div>
            <div class="total-value nowrap">&sum; {{ formatCurrency(item.segments[currentSegment].total_value) }}</div>
            <div class="actions">
              <button class="button rounded ghost" @click="onAnalysisClick(item)">Analysis</button>
              <div class="icon-button flex"
                  @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: item, menuItems: contextMenuItems[item.id] })">
                  <inline-svg
                      :src="require('@/assets/svg/icons/main_window/icon_options_default_24x5px.svg')" width="24" height="5">
                  </inline-svg>
              </div>
            </div>
        </li>
    </ul>
    <div v-if="!predictions || predictions.length == 0">No forecast saved</div>
    <ConfirmDialog v-if="predictionToBeDeleted" title="Delete forecast?">
      <template v-slot:message><span v-html="deleteConfirmMessage"></span></template>
      <template v-slot:actions>
        <div class="button-group">
          <button @click.stop="doCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
          <button @click.stop="doDeletePrediction" class="button primary mediumsize rounded">Delete</button>
        </div>
      </template>
    </ConfirmDialog>
    <RenameDialog
        v-if="itemToBeRenamed"
        title="Rename forecast"
        :oldName="itemToBeRenamed.name"
        v-model="newItemName"
        :error="renamingError"
        @rename:cancel="doCancelRename"
        @rename:save="doRenamePrediction"
      />
  </div>
</template>

<script>
import Util from "@/util.js";
import UiMixin from '../mixins/UiMixin.vue';
import AnalysisMixin from "../mixins/AnalysisMixin.vue";
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import useUI from '@/js/useUI.js'
import InfoHud from "@/components/ui/InfoHud.vue"
import DateInfo from "@/components/lists/DateInfo.vue"
import SortActions from "@/components/lists/SortActions.vue"
import RenameMixin from "@/components/mixins/RenameMixin.vue"
import RenameDialog from "../ui/RenameDialog.vue"

export default {
  name: "MediaplanList",
  mixins: [UiMixin, RenameMixin, AnalysisMixin],
  components: {
    ConfirmDialog,
    InfoHud,
    DateInfo,
    SortActions,
    RenameDialog
  },
  data: function() {
    return {
      predictionToBeDeleted: null
    }
  },
  methods: {
    onSort(sortInfo) {
      this.$store.commit("predictions/sortInfo", sortInfo)
    },
    onAnalysisClick(prediction) {
      // this.referenceDataId = prediction.id
      // this.probeDataId = null
      this.$router.push({
        path: "/analysis",
        query: { probe: "none", reference: prediction.id }
      });
    },
    clazz(item) {
      if (item.is_favorite) {
        return 'list-badge list-badge-favorite'
      }
      return ''
    },
    isLastKnownYear(prediction) {
      return prediction.year == this.$store.getters["config/lastKnownYear"]
    },
    doRenamePrediction() {
      useUI(this.$store).unblockUI()
      this.renamingError = null
      this.$store
        .dispatch("predictions/updatePrediction", {
            id: this.itemToBeRenamed.id,
            name: this.newItemName
        })
        .then(() => {
          this.itemToBeRenamed = null
          this.newItemName = null
          this.$store.dispatch("predictions/fetchAllPredictions").then( () => {
            useUI(this.$store).unblockUI()
          })
        })
        .catch( (error, response) => {
          console.log("renamingError", error, response)
          this.renamingError = error
        });
    },
    doDeletePrediction() {
      this.$store
        .dispatch("predictions/deletePrediction", this.predictionToBeDeleted.id)
        .then(() => {
          useUI(this.$store).unblockUI();
          this.predictionToBeDeleted = null;
          this.$store.dispatch("predictions/fetchAllPredictions");
        });
    },
    doCancelDelete() {
      useUI(this.$store).unblockUI()
      this.predictionToBeDeleted = null;
    }
  },
  computed: {
    totalValueSortProperty() {
      return `segments.${this.currentSegment}.total_value`
    },
    deleteConfirmMessage() {
      return `Do you really whish to delete <b>${this.predictionToBeDeleted.name}</b>? This will permanently delete the forecast.`
    },
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
    predictions() {
      return this.$store.getters["predictions/predictions"]
    },
    contextMenuItems() {
      return Util.contextMenuItems(this.predictions, (prediction) => {
        let items = [
          {
            title: "Details",
            handler: () => {
              this.$router.push("/analysis");
            },
          },
          {
            title: "Download",
            handler: () => {
              this.download("predictions/downloadPrediction", prediction.id)
            },
          },
          {
            title: "Rename",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
              this.itemToBeRenamed = prediction
              this.newItemName = this.itemToBeRenamed.name
            }
          },
          {
            title: "Delete",
            handler: () => {
              useUI(this.$store).blockUI({ complete: true })
              this.predictionToBeDeleted = prediction
            },
          },
        ];

        if (this.isLastKnownYear(prediction)) {
          items.push({
            title: "Show on dashboard",
            handler: () => {
              this.$store
                .dispatch("predictions/updatePrediction", {
                  id: prediction.id,
                  is_favorite: true
                }).then(() => {
                  this.$store.dispatch("predictions/fetchAllPredictions");
                });
            },
          })
        }

        return items
      });
    },
  },
};
</script>

<style scoped>
.total-value {
  color: var(--c-button-blue);
  text-align: right;
  padding: 20px;
}
li.header {
  background: #e9eef9;
  border: 1px solid #dde6f9;
}
.name, .label {
  padding-right: 20px;
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>
