<template>
  <div class="prediction-sidebar">
    <SidebarContent>
        <template v-slot:content>
            <h2 class="underline left ai">
                Create forecast
                <HeadlineBadge type="ai" />
            </h2>
            <div class="flex vertical full-height">
              <div class="scrollable-container sidebar">
                <form>
                  <fieldset>
                    <h3>Choose media plan</h3>
                    <label>Media plan</label>
                    <DropDown :options="mediaplanOptions" v-model="mediaplanId" @update:modelValue="onChooseMediaplan" />
                  </fieldset>
                  <!-- <fieldset v-show="mediaplanId != null" v-if="isNameFieldShown">
                    <h3>Forecast name</h3>
                    <label>Name</label>
                    <TextInput type="text" v-model="predictionName" />
                  </fieldset> -->
                  <fieldset v-show="mediaplanId != null">
                    <h3>Scenarios &amp; year</h3>
                    <ScenarioSelection v-model="scenarioConfig" @change="onScenarioChanged" />
                  </fieldset>
                  <fieldset v-show="mediaplanId != null">
                    <label>Year</label>
                    <DropDown :options="predictionYearOptions" v-model="predictionYear" @update:modelValue="onYearChanged" />
                  </fieldset>
                  <!-- <fieldset>
                  <label>Media plan for previous year</label>
                    <DropDown :options="$store.getters['config.knownDataYearOptions']" v-model="knownDataYear"/>
                  </fieldset> -->
                </form>
              </div>
            </div>
        </template>
        <template v-slot:actions>
            <div class="button-group">
              <button v-show="isCancelButtonShown" class="button ghost tall" @click="onCancel">Cancel</button>
              <button :disabled="isSaveButtonDisabled" v-show="isSaveButtonShown" class="button primary tall" @click="onSave">Save</button>
              <button v-show="isDoPredictionButtonShown" :disabled="!startButtonEnabled" class="button primary tall" @click="onStart">Create forecast</button>
            </div>
        </template>
    </SidebarContent>
    <ConfirmDialog v-if="showSavePredictionDialog" title="Save forecast">
        <template v-slot:message>Please enter a name for the forecast.</template>
        <template v-slot:content>
          <TextInput class="input-prediction-name" type="text" v-model="predictionName" />
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="onCancelSave" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="isSaveButtonDisabled" @click.stop="doSavePrediction" class="button primary mediumsize rounded">Save</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import HeadlineBadge from "@/components/ui/HeadlineBadge.vue"
import DropDown from "@/components/forms/DropDown.vue"
import TextInput from "@/components/ui/TextInput.vue"
import UiMixin from "@/components/mixins/UiMixin.vue"
import ScenarioSelection from "@/components/sidebar/ScenarioSelection.vue"
import useUI from '@/js/useUI.js'
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"

const DialogStates = Object.freeze({
  PREDICTION_INITIAL: "initial",
  MEDIAPLAN_SELECTED: "mediaplan_selected",
  PREDICTION_PENDING: "prediction_pending",
  PREDICTION_COMPUTED: "prediction_computed"
})

const RequestStates = Object.freeze({
  NO_REQUEST: "no_request",
  PENDING: "pending",
  USER_CANCELED: "user_canceled"
})

export default {
  name: 'PredictionSidebar',
  mixins: [UiMixin],
  components: {
      SidebarContent,
      HeadlineBadge,
      DropDown,
      ScenarioSelection,
      TextInput,
      ConfirmDialog
  },
  data: function() {
    return {
      predictionName: "",
      requestState: RequestStates.NO_REQUEST,
      predictionYear: null,
      scenarioConfig: {},
      showSavePredictionDialog: false
    }
  },
  props: {
  },
  methods: {
    updateName() {
      let mediaplan = this.$store.getters["mediaplanning/mediaplanById"](this.mediaplanId)
      if (mediaplan) {
        this.predictionName = mediaplan.name + " forecast " + this.predictionYear || ""
      }
    },
    onChooseMediaplan() {
      this.updateName()
      if (this.dialogState == DialogStates.PREDICTION_COMPUTED) {
        this.doPrediction()
      } else {
        this.$router.push("/predictions/scenario")
      }
    },
    onYearChanged() {
      this.updateName()
      this.updatePrediction()
    },
    onScenarioChanged() {
      this.updatePrediction()
    },
    updatePrediction() {
      if (this.dialogState == DialogStates.PREDICTION_COMPUTED) {
        this.doPrediction()
      }
    },
    doPrediction() {
      this.requestState = RequestStates.PENDING
      let cfg = JSON.parse(JSON.stringify(this.predictionConfig))
      delete cfg.save_as
      this.$store.dispatch("predictions/predict", cfg)
      .then( () => {
        this.$router.push("/predictions/current")
      }).finally( () => {
        this.requestState = RequestStates.NO_REQUEST
      })
    },
    onStart() {
      this.doPrediction()
    },
    onCancel() {
      useUI(this.$store).unblockUI()
      this.resetForm()
      this.$store.commit("predictions/currentPrediction", null)
      this.requestState = RequestStates.USER_CANCELED
      this.$router.push("/predictions")
    },
    onCancelSave() {
      useUI(this.$store).unblockUI()
      this.showSavePredictionDialog = false
    },
    onSave() {
      useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
      this.showSavePredictionDialog = true
    },
    doSavePrediction() {
      this.requestState = RequestStates.PENDING
      this.showSavePredictionDialog = false
      this.$store.dispatch("predictions/addPrediction", this.predictionConfig).then( () => {
        this.$store.commit("predictions/currentPrediction", null)
        this.$store.dispatch("predictions/fetchAllPredictions")
        this.$store.dispatch("mediaplanning/fetchAllPlans")
        this.$router.push("/predictions")
      }).finally( () => {
        useUI(this.$store).unblockUI()
        this.requestState = RequestStates.NO_REQUEST
      })
    },
    resetForm() {
      this.mediaplanId = null
    }
  },
  computed: {
    mediaplanId: {
      get() {
        return this.$store.state.mediaplanning.currentMediaplanId
      },
      set(id) {
        this.$store.commit("mediaplanning/currentMediaplanId", id)
      }
    },
    predictionYearOptions() {
      return this.$store.getters['config/predictionYearOptions']
    },
    path() {
      return this.$route.path
    },
    dialogState() {
      if (this.$store.state.predictions.currentPrediction) {
        return DialogStates.PREDICTION_COMPUTED
      }
      if (this.mediaplanId != null) {
        return DialogStates.MEDIAPLAN_SELECTED
      }
      return DialogStates.PREDICTION_INITIAL
    },
    startButtonEnabled() {
      return this.mediaplanId != null &&
             this.dialogState != DialogStates.PREDICTION_PENDING &&
             this.isScenarioConfigComplete &&
             this.predictionYear //&&
             //this.predictionName && this.predictionName.length > 2
    },
    mediaplanOptions() {
      return this.$store.getters["mediaplanning/mediaplanOptions"]
    },
    predictionConfig() {
      let cfg = {
        mediaplanung_id: this.mediaplanId,
        scenarios: Object.values(this.scenarioConfig),
        year: this.predictionYear
      }
      if (this.predictionName?.length) {
        cfg.save_as = this.predictionName
      }
      return cfg
    },
    isCancelButtonShown() {
      return this.dialogState == DialogStates.PREDICTION_COMPUTED || this.dialogState == DialogStates.MEDIAPLAN_SELECTED
    },
    isSaveButtonShown() {
      return this.dialogState == DialogStates.PREDICTION_COMPUTED
    },
    // isMediaplanSelectionShown() {
    //   return this.dialogState == DialogStates.PREDICTION_INITIAL || this.dialogState == DialogStates.MEDIAPLAN_SELECTED
    // },
    isNameFieldShown() {
      return this.dialogState == DialogStates.PREDICTION_COMPUTED
    },
    isSaveButtonDisabled() {
      return this.predictionName.length < 3
    },
    isDoPredictionButtonShown() {
      return this.dialogState == DialogStates.PREDICTION_INITIAL || this.dialogState == DialogStates.MEDIAPLAN_SELECTED
    },
    scenarioOptions() {
      return this.$store.getters["config/scenarioOptions"]
    },
    isScenarioConfigComplete() {
      let mandatoryKeys = Object.keys(this.scenarioOptions)
      let configuredKeys = Object.keys(this.scenarioConfig)
      let checker = (arr, target) => target.every(v => arr.includes(v));
      let complete = checker(configuredKeys, mandatoryKeys)
      return complete
    }
  },
  watch: {
    requestState: function(state) {
      if (state == RequestStates.PENDING) {
        useUI(this.$store).blockUI({ loading: true, complete: true })
      } else {
        useUI(this.$store).unblockUI()
      }
    },
    dialogState: function(state) {
      if (state == DialogStates.MEDIAPLAN_SELECTED) {
        useUI(this.$store).blockUI({ unblockDisabled: true })
      }
    },
  },
  beforeRouteLeave(to) {
    if (!to.path.startsWith("/predictions")) {
      this.resetForm()
      this.$store.commit("predictions/currentPrediction", null)
    }
  }

}
</script>

<style scoped>
.prediction-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h1 {
    font-weight: 500;
    font-size: 32px;
    padding: 40px;
    flex-grow: 1;
}
.button-group {
  padding-right: 40px;
}
.save-prediction-text {
  margin: 20px 0;
  font-size: 14px;
}
</style>
<style>
input[type="text"].input-prediction-name {
  width: 320px;
  margin-bottom: 40px;
}
</style>
