<template>
  <div class="inspector-sidebar">
    <div class="header">
      <div v-if="hasBackButton" class="back-button" @click="$emit('nav:back', $route.meta.backPath)">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22" height="22" viewBox="0 0 22 22" xml:space="preserve">
          <polygon class="st0" fill="#3F51F2" points="17.56,2.119 15.44,0 4.44,11 15.44,22 17.56,19.881 8.679,11 "/>
        </svg>
        <span>Back</span>
      </div>
      <img class="logo" width="49" height="21" src="@/assets/svg/logos/pia/pia_logo_small_positive.svg">
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectorSidebar',
  computed: {
    hasBackButton() {
      return this.$route.meta.backPath != undefined
    },
  },
}
</script>

<style scoped>
.inspector-sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.content {
    padding: 0 10px 40px 40px;
    flex-grow: 1;
}
.header {
    height: 65px;
    position: relative;
}
.logo {
    position: absolute;
    bottom: 10px;
    right: 68px;
}
.back-button {
  position: absolute;
  bottom: 10px;
  left: 15px;
  height: 20px;
  width: 120px;
  color: var(--c-button-blue);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.back-button span {
  margin: 1px 0 0 10px;
  display: inline-block;
}
</style>
