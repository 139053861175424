<template>
  <div class="dropzone" v-bind="getRootProps()">
    <div class="dropzone-outer">
      <div class="dropzone-inner">
        <input v-bind="getInputProps()" />
        <inline-svg
            :src="require('@/assets/svg/icons/right_sidebar/icon_main_upload_40x30px.svg')" width="40" height="30">
        </inline-svg>
        <p>
            Drag &amp; drop,<br>
            or <button @click="open">select a file</button> to upload
        </p>
        <div class="filename">{{ filename }}</div>
      </div>
    </div>
    <div v-show="error && error.length" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex'
import useUI from '@/js/useUI.js'
import { ref } from 'vue'

export default {
  name: 'DropZone',
  setup() {
    const store = useStore()
    const ui = useUI(store)
    const error = ref("")
    const filename = ref("")

    function onDrop(acceptFiles, rejectReasons) {
      if (acceptFiles.length) {
        ui.blockUI({ loading: true, complete: true })

        let formData = new FormData();
        let file = acceptFiles[0]
        filename.value = file.name
        formData.append("file", file);
        error.value = ""
        store.dispatch("mediaplanning/uploadPlan", formData)
          .then((response) => {
            store.dispatch("mediaplanning/fetchAllPlans")
            ui.unblockUI()
            console.log("Upload successful", response)
          }).catch(err => {
            error.value = err.response.data.error
            ui.unblockUI()
            console.error("Upload failed", err)
          })
      }
      console.log(acceptFiles);
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
      error,
      filename
    };
  },
}
</script>

<style scoped>

.dropzone-outer {
    border-radius: 7px;
    border: 1px dashed var(--c-dark-blue);
    padding: 7px 6px;
    margin-bottom: 20px;
}
.dropzone-inner {
    display: flex;
    flex-direction: column;
    background: var(--c-white-grey);
    justify-content: center;
    align-items: center;
    height: 182px;
    border-radius: 5px;
    cursor: pointer;
}
.dropzone p {
    font-weight: 500;
    text-align: center;
}
.error {
  padding: 0 0 30px 0;
  text-align: center;
  color: var(--c-red);
}
.dropzone:focus, .dropzone-outer:focus {
  outline: none;
}
input[type=file]:focus {
  outline: none;
}
</style>
