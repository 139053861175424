<template>
  <div class="analysis-sidebar">
    <SidebarContent class="noactions" >
        <template v-slot:content>
            <h2 class="underline left ai">
                Analysis
                <HeadlineBadge type="ai" />
            </h2>
            <div class="scrollable-container sidebar">
              <form>
                <fieldset>
                  <DropDown :options="referencePredictionOptions" v-model="referenceId" :showPrefix="true">
                    <template v-slot:prefix>
                      <div class="dot reference"></div>
                    </template>
                  </DropDown>
                  <div v-if="isReferenceHistoric">
                    <DropDown v-model="referenceYear" :options="knownDataYearOptions" :showPrefix="true" :allowNoSelection="false">
                      <template v-slot:prefix>
                        <div class="dot reference"></div>
                      </template>
                    </DropDown>
                  </div>
                  <hr class="sidebar" />
                  <DropDown :options="probePredictionOptions" v-model="probeId" :showPrefix="true">
                    <template v-slot:prefix>
                      <div class="dot probe"></div>
                    </template>
                  </DropDown>
                  <div v-if="isProbeHistoric">
                    <DropDown v-model="probeYear" :options="knownDataYearOptions" :showPrefix="true" :allowNoSelection="false">
                      <template v-slot:prefix>
                        <div class="dot probe"></div>
                      </template>
                    </DropDown>
                  </div>
                </fieldset>
                <TotalAmountTiles />
                <AnalysisMarginTiles />
              </form>
            </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import HeadlineBadge from "@/components/ui/HeadlineBadge.vue"
import DropDown from "@/components/forms/DropDown.vue"
import UiMixin from "@/components/mixins/UiMixin.vue"
import AnalysisMixin from "@/components/mixins/AnalysisMixin.vue"
import AnalysisMarginTiles from "@/components/sidebar/AnalysisMarginTiles.vue"
import TotalAmountTiles from "@/components/sidebar/TotalAmountTiles.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'AnalysisSidebar',
  mixins: [UiMixin, AnalysisMixin],
  components: {
      SidebarContent,
      HeadlineBadge,
      DropDown,
      AnalysisMarginTiles,
      TotalAmountTiles
  },
  computed: {
    isReferenceHistoric() {
      return this.referenceDataId == -1;
    },
    isProbeHistoric() {
      return this.probeDataId == -1;
    },
    knownDataYearOptions() {
      return this.$store.getters["config/knownDataYearOptions"].slice().reverse()
    },
    referencePredictionOptions() {
      let options = this.$store.getters["predictions/predictionOptions"].map( o => {
        return {
          label: "(A) " + o.label,
          value: o.value
        }
      })
      return [{
          label: "(A) True sell-out revenue",
          value: -1
      }].concat(options)
    },
    probePredictionOptions() {
      let options = this.$store.getters["predictions/predictionOptions"].map( o => {
        return {
          label: "(B) " + o.label,
          value: o.value
        }
      })
      return [{
          label: "(B) True sell-out revenue",
          value: -1
      }].concat(options)
    },
    referenceId: {
      get() {
        return this.referenceDataId
      },
      set(id) {
        this.referenceDataId = id
        useUI(this.$store).blockUI({ loading: true, complete: false, chart: true })
        this.$store.dispatch("analysis/fetchReferenceFinancialData", { id: id }).then( () => {
          useUI(this.$store).unblockUI()
        })
      }
    },
    probeId: {
      get() {
        return this.probeDataId
      },
      set(id) {
        this.probeDataId = id
        useUI(this.$store).blockUI({ loading: true, complete: false, chart: true })
        this.$store.dispatch("analysis/fetchProbeFinancialData", { id: id }).then( () => {
          useUI(this.$store).unblockUI()
        })
      }
    },
    referenceYear: {
      get() {
        return this.$store.state.analysis.historicReferenceYear
      },
      set(year) {
        this.$store.commit("analysis/historicReferenceYear", year)
        useUI(this.$store).blockUI({ loading: true, complete: false, chart: true })
        this.$store.dispatch("analysis/fetchReferenceFinancialData", { id: this.referenceDataId }).then( () => {
          useUI(this.$store).unblockUI()
        })
      }
    },
    probeYear: {
      get() {
        return this.$store.state.analysis.historicProbeYear
      },
      set(year) {
        this.$store.commit("analysis/historicProbeYear", year)
        useUI(this.$store).blockUI({ loading: true, complete: false, chart: true })
        this.$store.dispatch("analysis/fetchProbeFinancialData", { id: this.probeDataId }).then( () => {
          useUI(this.$store).unblockUI()
        })
      }
    },
  },
}
</script>

<style scoped>
.analysis-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h1 {
    font-weight: 500;
    font-size: 32px;
    padding: 40px;
    flex-grow: 1;
}
.dot.reference {
  background: var(--c-reference-data);
}

.dot.probe {
  background: var(--c-probe-data);
}
hr.sidebar {
  margin-bottom: 30px;
}
</style>
