<template>
  <ConfirmDialog :title="title">
    <template v-slot:message>
      <div>
        <label>Old name</label>
        <TextInput type="text" :placeholder="oldName" disabled />
      </div>
      <div>
        <label>New name</label>
        <TextInput type="text" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)"/>
      </div>
      <div class="error-message">
        {{ errorMessage }}
      </div>
    </template>
    <template v-slot:actions>
      <button @click.stop="$emit('rename:cancel')" class="button ghost mediumsize rounded">Cancel</button>
      <button :disabled="!validates" @click.stop="$emit('rename:save')" class="button primary mediumsize rounded">Rename</button>
    </template>
  </ConfirmDialog>
</template>

<script>

import TextInput from "@/components/ui/TextInput.vue"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"

export default {
  name: "RenameDialog",
  components: {
    TextInput,
    ConfirmDialog
  },
  props: {
    oldName: {
      type: String
    },
    modelValue: {
      type: String
    },
    title: {
      type: String
    },
    error: {
      type: Object
    }
  },
  computed: {
    validates() {
      return this.modelValue?.length > 0
    },
    errorMessage() {
      return this.error?.response?.data?.error
    }
  }
};
</script>

<style scoped>
:deep(input) {
  width: 100%;
}
.error-message {
  color: #F23F51;
  margin-top: 20px;
}
</style>
