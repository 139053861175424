<template>
  <div class="confirm-dialog">
    <h2 class="underline">{{ title }}</h2>
    <p class="confirm-message">
      <slot name="message"></slot>
    </p>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style scoped>
.confirm-dialog {
  background: #fff;
  padding: 40px;
  border-radius: 7px;
  box-shadow: var(--std-box-shadow);
  width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200000;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.actions {
  width: 300px;
}
.confirm-message {
  font-size: 14px;
  width: 70%;
}
.content {
  margin-bottom: 40px;
}
h2.underline {
  margin-bottom: 10px;
}
:deep(.button-group button) {
  margin-right: 20px;
}
</style>
