<template>
  <div class="latest-mediaplans">
    <div class="content">
      <table>
          <th class="title" colspan="5">
              <h3>Latest media plans</h3>
          </th>
          <th class="header-actions">
            <div class="icon-button"
                    @click.stop="$store.dispatch('ui/toggleContextMenu', { event: $event, data: item, menuItems: contextMenuItems })">
                <inline-svg
                    :src="require('@/assets/svg/icons/main_window/icon_options_default_24x5px.svg')" width="24" height="5">
                </inline-svg>
            </div>
          </th>
          <tr v-for="(item, index) in mediaplans" v-bind:key="index">
              <td width="5%" >
                <inline-svg v-if="!item.ai_details" :src="require('@/assets/svg/icons/mediaplan/icon_mediaplan_small_25x25px.svg')" width="25" height="25"></inline-svg>
                <inline-svg v-if="item.ai_details" :src="require('@/assets/svg/icons/mediaplan/icon_mediaplan_small_aiplus_25x25px.svg')" width="25" height="25"></inline-svg>
              </td>
              <td width="35%" class="name">
                {{ mediaplanName(item) }} {{ mediaplanLabel(item) }}
                <HeadlineBadge v-if="item.ai_details" type="aiplus" />
              </td>
              <td width="15%" class="date nowrap">
                {{ dateInfos(item).lastModified }}
                <InfoHud v-if="dateInfos(item).lastModified">
                  <slot>
                    <DateInfo :item="item" />
                  </slot>
                </InfoHud>
              </td>
              <td width="15%" class="nowrap">{{ numberOfPredictionsTitles[item.id] }}</td>
              <td width="15%" class="nowrap" v-html="totalValueText(item)"></td>
              <td width="15%" class="nowrap actions">
                <div class="button-progress-wrapper">
                  <button v-if="showPredictionButton(item)" class="button rounded ghost" @click.stop="onPredictionClick(item)">Create forecast</button>
                  <ProgressBar v-if="showProgressBar(item)" :completed="item.ai_details.percent_done"/>
                </div>
              </td>
          </tr>
          <tfoot>
            <td colspan="6" class="view-all">
              <router-link to="/mediaplans">View all</router-link>
            </td>
          </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue";
import MediaplanListMixin from "@/components/mixins/MediaplanListMixin.vue"
import ProgressBar from "@/components/ui/ProgressBar.vue"
import HeadlineBadge from "@/components/ui/HeadlineBadge.vue"
import InfoHud from "@/components/ui/InfoHud.vue"
import DateInfo from "@/components/lists/DateInfo.vue"
import useUI from '@/js/useUI.js'

export default {
  name: "LatestMediaplans",
  mixins: [UiMixin, MediaplanListMixin],
  props: {},
  components: {
    ProgressBar,
    HeadlineBadge,
    InfoHud,
    DateInfo
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("mediaplanning/fetchAllPlans");
  },
  methods: {
    onPredictionClick(mediaplan) {
      this.$store.commit("mediaplanning/currentMediaplanId", mediaplan.id);
      this.$router.push("/predictions/scenario");
      useUI(this.$store).blockUI({ unblockDisabled: true });
    },
    mediaplanLabel(mediaplan) {
      return mediaplan.is_default_plan ? "(default)" : ""
    }
  },
  computed: {
    mediaplans() {
      return this.$store.getters["mediaplanning/mediaplans"]
        // .slice()
        // .reverse()
        .slice(0, 3);
    },
    contextMenuItems() {
      return [
        {
          title: "View all",
          handler: () => {
            this.$router.push("/mediaplans");
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.latest-mediaplans {
}

h3 {
  font-size: 18px;
}

.header {
  text-align: left;
}
table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

th.title {
  text-align: left;
}
th.header-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
td {
  text-align: left;
  border-bottom: 1px solid var(--c-bright-grey);
}
td.actions {
  text-align: center;
}
tr:last-child td {
  border-bottom: none;
}

td.name {
  font-weight: 500;
}
td.name .headline-badge {
  transform: translate(0px, -4px);
}
td:last-child {
  text-align: right;
}
tr {
  cursor: pointer;
  height: var(--tr-default-height);
}

tr:hover {
  background-color: var(--c-white-grey);
}

.button {
  margin: 0;
}
td.view-all {
  text-align: center;
  height: 40px;
  border: none;
}

</style>
