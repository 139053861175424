<template>
  <div class="login-form">
    <form name="form" @submit.prevent="handleLogin">
        <div class="container">
            <input
                v-model="user.username"
                type="text"
                class="uk-input"
                name="username"
                placeholder="Username"
            />

            <input
                v-model="user.password"
                type="password"
                class="uk-input"
                name="password"
                placeholder="Password"
            />

            <button class="button primary tall">
                <span>Log in</span>
            </button>

          <div class="msg error" v-if="error">
            {{ error }}
          </div>
        </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'LoginForm',
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
    };
  },
  props: {
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("user/login", this.user).then( () => {
        this.$store.commit("ui/error", null)
        this.$router.push("/")
      }).catch( (error) => {
        console.log("Login Form error: ", error)
      })
    },
  },
  computed: {
    token() {
      return this.$store.state.user.token
    },
    error() {
      return this.$store.state.ui.error
    }
  }
}
</script>

<style scoped>
.login-form {
    width: 100%;
}
.container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}
button.button.primary {
  width: 240px;
  align-self: center;
}
input[type=text], input[type=password] {
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 16px;
    flex-grow: 1;
    color: #fff;
    text-align: left;
    background: no-repeat scroll 15px 20px;
    border-bottom: 1px solid var(--c-bright-grey);
    flex-grow: 1;
    margin-bottom: 20px;
    color: var(--c-deep-grey);
    text-align: left;
    padding: 20px 20px 10px 20px;
}
input[type=text]:focus, input[type=password]:focus {
  outline: none;
}

/* input[type=text]:-internal-autofill-previewed input[type=text]:-internal-autofill-selected {

} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--c-white-grey) inset;
    font-family: BioSans, Helvetica, Arial, sans-serif;
    color: var(--c-deep-grey);
}

::placeholder {
    color: var(--c-deep-grey);
}
.error {
    color: #f00;
    text-align: center;
}
a:link, a:hover, a:visited {
  color: #fff;
  cursor: pointer;
}
.msg {
    padding: 20px 0;
}

input[type=password] {
  margin-bottom: 60px;
}

</style>
