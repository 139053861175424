<template>
  <div class="scenario-selection">
    <div v-for="scenarioGroup in scenarioGroups" v-bind:key="scenarioGroup.key">
      <label>{{ scenarioGroup.label }}</label>
      <DropDown :options="scenarioOptions[scenarioGroup.key]" v-model="scenarioConfig[scenarioGroup.key]" @update:modelValue="onChange" />
    </div>
  </div>
</template>

<script>
import DropDown from "@/components/forms/DropDown.vue"

export default {
  name: 'ScenarioSelection',
  components: {
    DropDown,
  },
  props: {
    modelValue: {
      type: Object,
    },
  },
  data: function() {
    return {
      scenarioConfig: {
      },
    }
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.scenarioConfig)
      this.$emit("change", this.scenarioConfig)
    },
  },
  computed: {
    scenarioOptions() {
      return this.$store.getters["config/scenarioOptions"]
    },
    scenarioGroups() {
      return this.$store.state.config.scenarioGroups
    },
  },
  created() {
    if (this.modelValue) {
      this.scenarioConfig = JSON.parse(JSON.stringify(this.modelValue))
    }
  },
}
</script>

<style scoped>

</style>
