<template>
  <div class="lgout-page">
    Logout.
  </div>
</template>

<script>

export default {
  name: 'LogoutPage',
  beforeRouteEnter (to, from, next) {
     next(vm => {
       vm.$store.dispatch("user/logout")
       vm.$router.push("/login")
    })
  }
}
</script>

<style scoped>

</style>
