<template>
  <div class="in-progress">
    <SidebarContent>
        <template v-slot:content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Your AI media plan is now in progress. As soon as it is ready, you will find it under media plans.
            </h1>
            <div class="subtitle">This media plan is intended as a reference, not as a 1-to-1 guideline. Please contact your Customer Success Manager, if you have any questions.</div>
            <PiaAssistant />
          </div>
        </template>
        <template v-slot:actions>
            <button class="button primary tall" @click="onOverview">To overview</button>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "../SidebarContent.vue"
import PiaAssistant from "@/components/ui/PiaAssistant.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'StartPlanning',
  components: {
      SidebarContent,
      PiaAssistant
  },
  props: {
  },
  methods: {
    onOverview() {
      useUI(this.$store).unblockUI()
      this.$store.dispatch("mediaplanning/fetchAllPlans")
      this.$router.push("/mediaplans")
    },
  }
}
</script>

<style scoped>
.in-progress {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.content {
  padding-right: 40px;
}
.button {
  margin-right: 30px;
}
</style>
