import lodash from "lodash";
import chroma from "chroma-js";

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
function parseLocaleNumber(stringNumber, locale) {
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}


export default class Util {
  static contextMenuItems(collection, mappingFunction) {
    let menuItemsMap = {};
    collection.forEach((item) => {
      menuItemsMap[item.id] = mappingFunction(item);
    });
    return menuItemsMap;
  }

  static formatNumber(number, opts = {
    maximumFractionDigits: 0
  }) {
      return number ? number.toLocaleString("en-US", opts).replaceAll(",", "\u2009") : 0
  }

  static formatCurrency(number, currency) {
    if (number < 0) {
      return "-\u202F" + currency + "\u202F" + this.formatNumber(Math.abs(number))
    }
    return currency + "\u202F" + this.formatNumber(number)
  }

  static parseCurrency(str, currency) {
    return parseLocaleNumber(str.replaceAll(currency, '').replaceAll(/\p{White_Space}/gu, ''), "en-US")
  }

  static parseNumber(str) {
      return parseLocaleNumber(str.replaceAll(" ", ""), "en-US")
  }

  static sortByString(array, propertyName, ascending) {
    return array.sort(function (x, y) {
      let a = ascending ? x : y;
      let b = ascending ? y : x;
      if (a[propertyName] == b[propertyName]) return 0;
      return lodash.get(a, propertyName) > lodash.get(b, propertyName) ? 1 : -1;
    });
  }
  static sortByNumber(array, propertyName, ascending) {
    return array.sort(function (x, y) {
      let a = ascending ? x : y;
      let b = ascending ? y : x;
      return lodash.get(a, propertyName) - lodash.get(b, propertyName);
    });
  }
  static sortByTimestamp(array, propertyName, ascending) {
    return array.sort(function (x, y) {
      let a = ascending ? x : y;
      let b = ascending ? y : x;
      return (
        (new Date(lodash.get(a, propertyName))?.getTime() || 0) -
        (new Date(lodash.get(b, propertyName))?.getTime() || 0)
      );
    });
  }

  static getScrollParent(node) {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }

    return this.getScrollParent(node.parentNode) || document.body;
  }

  static getNextItem(arr, value) {
    // Find the index of the value in the array
    var index = arr.indexOf(value);

    // Check if the value is not in the array
    if (index === -1) {
      return arr[0];
    }

    // Check if the next index is out of bounds
    if (index + 1 >= arr.length) {
      // Return the first item if the end is reached
      return arr[0];
    } else {
      // Return the next item otherwise
      return arr[index + 1];
    }
  }

  static createColorMap(n, base, alpha) {
    // Start with your base color
    var baseColor = chroma(base);

    // Create a color scale based on the base color
    var colorScale = chroma.scale([baseColor, "black"]);

    // Generate n colors from the color scale
    var colors = [];
    for (var i = 0; i < n; i++) {
      colors.push(colorScale(i / (n - 1)).alpha(alpha).hex());
    }
    return colors;
  }

  static colorsToLinearGradient(colors) {
    // converts a list of colors to an equidistant linear gradient with these colors
    let gradients = []
    colors.forEach((color, idx) => {
      const pct_from = 100 * idx / colors.length
      const pct_to = 100 * (idx + 1) / colors.length
      gradients.push(`${color} ${pct_from}%`);
      gradients.push(`${color} ${pct_to}%`);
    })
    return `linear-gradient(${gradients.join(',')})`
  }

  static replaceGroupInRegex(str, regex, replacement) {
    return str.replace(regex, function(match, group) {
        return match.replace(group, replacement);
    });
  }

  static dataweekConfig(dataweekStart) {
    // Calculate helpful values for calendars / non-isoWeek handling from the
    // configured dataweekStart
    switch (dataweekStart.toUpperCase()) {
      case "MON":
        return {
          firstDayOfWeek: 1,
          firstWeekContainsDate: 4,
          shiftToIsoWeek: 0,
        }

      case "WED":
        return {
          firstDayOfWeek: 3,
          firstWeekContainsDate: 4,
          shiftToIsoWeek: -2,
        }

      case "SAT":
        return {
          firstDayOfWeek: 6,
          firstWeekContainsDate: 2,
          shiftToIsoWeek: 2,
        }

      default:
        throw new Error(`Invalid dataweekStart: ${dataweekStart}`)
    }
  }
}
