import {
  createStore
} from 'vuex'

import { ui } from './ui.module';
import { user } from './user.module';
import { analysis } from './analysis.module';
import { mediaplanning } from './mediaplanning.module';
import { predictions } from './predictions.module';
import { planning } from './planning.module';
import { config } from './config.module';
import { historic } from './historic.module';
import { ai } from './ai.module';
import { admin } from './admin.module';
import { info } from './info.module';
import { reports } from './reports.module';
import { internal } from './internal.module';

import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["admin", "user"],
})

const store = createStore({
  plugins: [vuexLocal.plugin],
  modules: {
    ui,
    admin,
    user,
    analysis,
    mediaplanning,
    predictions,
    historic,
    planning,
    config,
    ai,
    info,
    reports,
    internal,
  }
});

export default store
