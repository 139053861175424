<template>
  <div :class="'container ' + (isInfoBarShown ? 'info-shown' : '') + ($store.state.ui.isScreenBlocked ? ' dimmer' : '')">
    <LoadingSpinner v-if="$store.state.ui.isScreenBlocked && isLoading" />
    <div class="main-layout">
      <div class="main-contents">
        <div class="header">
          <MainHeader />
        </div>
        <div :class="'main ' + ($store.state.ui.isMasterViewBlocked ? 'dimmer' : '')" @contextmenu:toggle.stop="$refs.contextMenu.toggle($event.target)" @click="onDimmerClick">
          <router-view v-if="loaded" v-slot="{ Component }">
            <component ref="mainView" :is="Component" />
          </router-view>
          <LoadingSpinner v-if="$store.state.ui.isMasterViewBlocked && isLoading" />
        </div>
      </div>
      <div class="sidebar-contents">
        <div class="info">
          <InfoSidebar />
        </div>
        <div class="sidebar">
          <InspectorSidebar @nav:back="onSidebarNavBack">
            <router-view name="sidebar" v-if="loaded" />
          </InspectorSidebar>
        </div>
      </div>
    </div>
    <ContextMenu ref="contextMenu" />
    <transition name="fade">
      <MenuOverlayPage v-show="$store.state.ui.isSiteMenuShown" />
    </transition>
    <ErrorMessage v-if="$store.state.ui.error" />
  </div>
</template>

<script>
import InfoSidebar from "./components/sidebar/InfoSidebar.vue"
import MainHeader from "./components/nav/MainHeader.vue"
import InspectorSidebar from "./components/sidebar/InspectorSidebar.vue"
import LoadingSpinner from "./components/ui/LoadingSpinner.vue"
import ContextMenu from "@/components/ui/ContextMenu.vue"
import UiMixin from "@/components/mixins/UiMixin.vue"
import MenuOverlayPage from "@/views/MenuOverlayPage.vue"
import ErrorMessage from "@/components/ui/ErrorMessage.vue"
import useUI from '@/js/useUI.js'
//import gsap from "gsap"

export default {
  name: 'AppLayout',
  mixins: [UiMixin],
  components: {
    MainHeader,
    InfoSidebar,
    InspectorSidebar,
    LoadingSpinner,
    ContextMenu,
    MenuOverlayPage,
    ErrorMessage,
  },
  data: function() {
    return {
      loaded: false
    }
  },
  methods: {
    onDimmerClick(e) {
      if (this.$store.state.ui.isUIUnblockByUserDisabled) {
        e.stopPropagation()
        return
      }
      if (this.isMainViewBlocked) {
        e.stopPropagation()
      }
      if (!this.isLoading) {
        useUI(this.$store).unblockUI();
      }
    },
    onSidebarNavBack(path) {
      this.$refs.mainView.$.ctx.navBack(path)
    }
  },
  computed: {
    isInfoBarShown() {
      return this.$store.state.info.isInfoBarShown
    },
    isLoading() {
      return this.$store.state.ui.isLoading
    },
    isMainViewBlocked() {
      return this.$store.state.ui.isMainViewBlocked
    }
  },
  created() {
    this.$store.dispatch("config/fetchFullConfig").then ( () => {
      let lastKnownYear = this.$store.getters["config/lastKnownYear"]
      this.$store.commit("analysis/historicReferenceYear", lastKnownYear);
      this.$store.commit("analysis/historicProbeYear", lastKnownYear);
      Promise.all([
        this.$store.dispatch("config/fetchScenarios"),
        this.$store.dispatch("mediaplanning/fetchAllPlans"),
        this.$store.dispatch("predictions/fetchAllPredictions"),
        this.$store.dispatch("historic/fetchHistoricTurnover", lastKnownYear),
        this.$store.dispatch("historic/fetchHistoricMediaplan", lastKnownYear),
      ]).finally(() => {
        this.$store.commit("ui/isMasterViewBlocked", false)
        const reportsOnly = this.$store.getters["config/reportsOnly"]
        if (reportsOnly) this.$router.push("/reports")
        this.loaded = true
      })
    })
  }
}
</script>

<style scoped>

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.main-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sidebar-contents {
  grid-area: sidebar;
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  max-width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  /* transition: min-width .25s ease-in-out, max-width 0.25s ease-in-out; */
}
.info-shown .sidebar-contents {
  min-width: calc(var(--sidebar-width) + var(--infobar-width));
  max-width: calc(var(--sidebar-width) + var(--infobar-width));
  transition: min-width 0.25s ease-in-out, max-width 0.25s ease-in-out;
}
.sidebar {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-basis: var(--sidebar-width);
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
}
.header {
  grid-area: header;
  overflow: hidden;
}
.info-shown .info {
  min-width: var(--infobar-width);
  max-width: var(--infobar-width);
  transition: min-width 0.25s ease-in-out, max-width 0.25s ease-in-out;
}
.info {
  grid-area: info;
  background: var(--c-white-grey);
  display: flex;
  min-width: 0;
  max-width: 0;
  overflow: hidden;
  /* transition: min-width 0.5s ease-in-out, max-width 0.5s ease-in-out; */
}
.main-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 70px;
}
.main-contents {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 86px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "main";
  flex-grow: 1;
}
.main {
  /* grid-area: main; */
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: relative;
  padding: 20px 10px;
  flex-grow: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

</style>
