<template>
  <div class="planning-step budget">
    <BudgetStepMaxTurnaround v-if="currentTarget && currentTarget.optimization_type == 'max_umsatz'"/>
    <BudgetStepTargetRomi v-if="currentTarget && currentTarget.optimization_type == 'target_romi'"/>
    <BudgetStepAnnualTurnover v-if="currentTarget && currentTarget.optimization_type == 'annual_turnover'"/>
  </div>
</template>

<script>
//import PlanningContainer from "./PlanningContainer.vue"
import BudgetStepMaxTurnaround from "../planning/optimizationTypes/maxTurnaround/BudgetStepMaxTurnaround.vue"
import BudgetStepTargetRomi from "../planning/optimizationTypes/targetRomi/BudgetStepTargetRomi.vue"
import BudgetStepAnnualTurnover from "../planning/optimizationTypes/annualTurnover/BudgetStepAnnualTurnover.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'

export default {
  name: 'BudgetStep',
  components: {
      // PlanningContainer,
      BudgetStepMaxTurnaround,
      BudgetStepTargetRomi,
      BudgetStepAnnualTurnover
  },
  mixins: [CurrentTargetMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
}
</script>
