const initialState = {
  isInfoBarShown: false,
  infoBarPath: null,
  content: [
    {
      path: "/planning/target",
      contents: [
        {
          icon: "icon_ai_plus_60x60px.svg",
          title: "SimCog AI+",
          text: "I will gladly support you. Step by step, I will guide you through the process. You will receive the media plan after approx. 24 hours, including flighting and budget distribution."
        },
        {
          icon: "icon_optimization_60x60px.svg",
          title: "Optimization goal",
          text: "The optimization goal determines the possible conditions and my goal during the media plan creation. Templates serve you as a shortcut to your stored conditions."
        },
      ]
    },
    {
      path: "/planning/budget",
      max_umsatz: {
        contents: [
          {
            icon: "icon_media_budget_60x60px.svg",
            title: "Media budget",
            text: "Please enter a total media budget in net. I will distribute this among the channels to achieve the highest possible revenue. I will take synergies and flightings into account."
          },
          {
            icon: "icon_time_60x60px.svg",
            title: "Max. budgets (annually)",
            text: "You can optionally define a min. and max. budget for each channel. If the max. budget exceeds the total media budget (net), I will per default take the total media budget."
          },
        ]
      },
      target_romi: {
        contents: [
          {
            icon: "icon_media_budget_60x60px.svg",
            title: "Target ROMI",
            text: "Please enter a Return on Marketing Investment target. The overall media plan is optimized for the target ROMI. Individual channels can be above or below it."
          },
        ]
      },
      annual_turnover: {
        contents: [
          {
            icon: "icon_media_budget_60x60px.svg",
            title: "Revenue target",
            text: "Please enter an annual sell-out revenue target. The required budget will be allocated to reach your target most efficiently, including flighting and timing."
          },
        ]
      }
    },
    {
      path: "/planning/payfactors",
      max_umsatz: {
        contents: [
          {
            icon: "icon_margin_60x60px.svg",
            title: "Sell-out margin",
            text: "The margin helps to convert the gross revenue effect to net. This allows me to optimize on net ROMI. A value of 1 optimizes with gross revenue."
          },
          {
            icon: "icon_pay_factor_60x60px.svg",
            title: "Media discount",
            text: "The media discounts help to determine the net media budget of the respective channel. This helps with comparability. A value of 0 enables gross optimization."
          },
        ],
      },
      target_romi: {
        contents: [
          {
            icon: "icon_margin_60x60px.svg",
            title: "Sell-out margin",
            text: "The margin helps to convert the gross revenue effect to net. This allows me to optimize on net ROMI. A value of 1 optimizes with gross revenue."
          },
          {
            icon: "icon_pay_factor_60x60px.svg",
            title: "Media discount",
            text: "The media discounts help to determine the net media budget of the respective channel. This helps with comparability. A value of 0 enables gross optimization."
          },
        ]
      },
      annual_turnover: {
        contents: [
          {
            icon: "icon_margin_60x60px.svg",
            title: "Sell-out margin",
            text: "The margin helps to convert the gross revenue effect to net. This allows me to optimize on net ROMI. A value of 1 optimizes with gross revenue."
          },
          {
            icon: "icon_pay_factor_60x60px.svg",
            title: "Media discount",
            text: "The media discounts help to determine the net media budget of the respective channel. This helps with comparability. A value of 0 enables gross optimization."
          },
        ]
      }
    },
    {
      path: "/planning/overview",
      max_umsatz: {
        contents: [
          {
            icon: "icon_summary_60x60px.svg",
            title: "Check input",
            text: "This is a summary of all your settings. Please review it. \"Edit\" will take you back to the respective configuration step."
          },
        ]
      },
      target_romi: {
        contents: [
          {
            icon: "icon_summary_60x60px.svg",
            title: "Check input",
            text: "This is a summary of all your settings. Please review it. \"Edit\" will take you back to the respective configuration step."
          },
        ]
      },
      annual_turnover: {
        contents: [
          {
            icon: "icon_summary_60x60px.svg",
            title: "Check input",
            text: "This is a summary of all your settings. Please review it. \"Edit\" will take you back to the respective configuration step."
          },
        ]
      }
    },
    {
      path: "/planning/name",
      max_umsatz: {
        contents: [
          {
            icon: "icon_naming_60x60px.svg",
            title: "Media plan name",
            text: "Give a unique name to the media plan that will be created based on this planning process. Additionally, the conditions you specified as well as sell-out margin and pay factors can be saved as a template."
          },
        ]
      },
      target_romi: {
        contents: [
          {
            icon: "icon_naming_60x60px.svg",
            title: "Media plan name",
            text: "Give a unique name to the media plan that will be created based on this planning process. Additionally, the conditions you specified as well as sell-out margin and pay factors can be saved as a template."
          },
        ]
      },
      annual_turnover: {
        contents: [
          {
            icon: "icon_naming_60x60px.svg",
            title: "Media plan name",
            text: "Give a unique name to the media plan that will be created based on this planning process. Additionally, the conditions you specified as well as sell-out margin and pay factors can be saved as a template."
          },
        ]
      }
    },
    {
      path: "/planning/scenarios",
      max_umsatz: {
        contents: [
          {
            icon: "icon_szenario_60x60px.svg",
            title: "Scenarios",
            text: "Media planning depends on various factors. Please select a scenario combination to be applied within this optimization."
          },
          {
            icon: "icon_time_60x60px.svg",
            title: "Year",
            text: "Please select the year for which the media plan is to be created and optimized. The media plan from the previous year influences this media plan, please select one."
          },
        ]
      },
      target_romi: {
        contents: [
          {
            icon: "icon_szenario_60x60px.svg",
            title: "Scenarios",
            text: "Media planning depends on various factors. Please select a scenario combination to be applied within this optimization."
          },
          {
            icon: "icon_time_60x60px.svg",
            title: "Year",
            text: "Please select the year for which the media plan is to be created and optimized. The media plan from the previous year influences this media plan, please select one."
          },
        ]
      },
      annual_turnover: {
        contents: [
          {
            icon: "icon_szenario_60x60px.svg",
            title: "Scenarios",
            text: "Media planning depends on various factors. Please select a scenario combination to be applied within this optimization."
          },
          {
            icon: "icon_time_60x60px.svg",
            title: "Year",
            text: "Please select the year for which the media plan is to be created and optimized. The media plan from the previous year influences this media plan, please select one."
          },
        ]
      }
    },
    {
      path: "/predictions/scenario",
      contents: [
        {
          icon: "icon_ai_60x60px.svg",
          title: "SimCog AI",
          text: "I will gladly support you in creating your forecast. Select a media plan as a basis for this. You will receive a Sell-Out forecast directly after clicking CREATE FORECAST."
        },
        {
          icon: "icon_szenario_60x60px.svg",
          title: "Scenarios",
          text: "The forecast depends on various factors. Please select a scenario combination to be applied within this forecast."
        },
        {
          icon: "icon_time_60x60px.svg",
          title: "Year",
          text: "Please select the year for which the forecast is to be created. The media plan must contain media spendings up to and including the selected year. Not included future dates will be set to EUR 0 media spending."
        },
      ]
    },
  ]
}

export const info = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    isInfoBarShown(state, isInfoBarShown) {
      state.isInfoBarShown = isInfoBarShown
    },
    infoBarPath(state, infoBarPath) {
      state.infoBarPath = infoBarPath
    },
  },
  getters: {
    contentByPath: (state) => (path) => {
      return state.content.find( c => c.path == path)
    },
  }
};
