<template>
  <div class="planning-step budget-max-turnaround">
    <PlanningContainer>
      <form>
        <h4>Target ROMI</h4>
        <fieldset>
          <label>ROMI</label>
          <TextInput :numbersOnly="true" :step="0.1" :min="0" :max="null" v-model="currentTarget.romi"></TextInput>
        </fieldset>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import PlanningContainer from "../../PlanningContainer.vue"
import TextInput from "@/components/ui/TextInput.vue"

export default {
  name: 'BudgetStepTargetRomi',
  components: {
      PlanningContainer,
      TextInput
  },
  computed: {
    currentTarget() {
      return this.$store.state.ai.currentTarget
    }
  },
}
</script>

<style scoped>

</style>
