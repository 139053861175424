<template>
  <div>
    <json-viewer
      :value="data"
      :expand-depth=0
      copyable
      boxed
      sort></json-viewer>
  </div>
</template>
<script>

import JsonViewer from 'vue-json-viewer';

export default {
  components: {
    JsonViewer,
  },
  props: {
    data: {
      type: Object
    }
  }
};
</script>