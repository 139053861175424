<template>
  <div class="planning-step budget-max-turnaround">
    <PlanningContainer>
      <form>
        <h4>Revenue target</h4>
        <fieldset>
          <label>Annual revenue</label>
          <CurrencyInput :min="0" :placeholder="formatCurrency(0)" v-model="currentTarget.annual_turnover"></CurrencyInput>
        </fieldset>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import PlanningContainer from "../../PlanningContainer.vue"
import CurrencyInput from "@/components/ui/CurrencyInput.vue"
import CurrentTargetMixinVue from '../../CurrentTargetMixin.vue'
import Util from "@/util.js"

export default {
  name: 'BudgetStepAnnualTurnover',
  components: {
      PlanningContainer,
      CurrencyInput
  },
  methods: {
    formatCurrency(number) {
      return Util.formatCurrency(number, this.$store.state.config.currency)
    }
  },
  mixins: [CurrentTargetMixinVue]
}
</script>

<style scoped>

</style>
