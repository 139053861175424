
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <PredictionList v-if="!showCurrentPrediction" />

      <WidgetRow v-if="showCurrentPrediction && currentPrediction" gridTemplateColumns="1fr" flexGrow="1">
        <WidgetContainer>
          <div class="chart-container">
            <div class="chart-diagram-container">
              <ChartDiagram class="chart-diagram"
                :chartData="predictionChartData"
                :title="currentPrediction.name"
                :showLabelTexts="true"
                :yAxes="yAxes"
                :subtitle="currentPrediction.subtitle" />
            </div>
            <div class="chart-details">
              <span class="total-value">{{ formatCurrency(currentPrediction.segments[currentSegment].total_value) }}</span>
              <span class="mediaplan-name">{{ currentPrediction.mediaplanung_name }}</span>
            </div>
          </div>
          <div v-if="showCurrentPrediction && !currentPrediction">
            No data available for the current forecast.
          </div>
        </WidgetContainer>
      </WidgetRow>
      <WidgetRow v-if="showCurrentPrediction" gridTemplateColumns="1fr" flexGrow="0">
        <WidgetContainer>
          <MediaplanDetails v-if="currentPrediction" :mediaplan="mediaplanung" />
        </WidgetContainer>
      </WidgetRow>
    </div>
  </div>
</template>

<script>
import UiMixin from "@/components/mixins/UiMixin.vue"
import AnalysisMixin from "@/components/mixins/AnalysisMixin.vue"
import MediaplanDetails from "@/components/widgets/MediaplanDetails.vue"
import WidgetContainer from "@/components/widgets/WidgetContainer.vue"
import WidgetRow from "@/components/widgets/WidgetRow.vue"
import ChartDiagram from "../components/chart/ChartDiagram.vue"
import PredictionList from "../components/lists/PredictionList.vue"

export default {
  name: 'PredictionsPage',
  mixins: [UiMixin, AnalysisMixin],
  components: {
    PredictionList,
    ChartDiagram,
    WidgetRow,
    WidgetContainer,
    MediaplanDetails
  },
  computed: {
    path() {
      return this.$route.path
    },
    showCurrentPrediction() {
      return this.path == "/predictions/current"
    },
    currentPrediction() {
      return this.$store.state.predictions.currentPrediction
    },
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
    predictionChartData() {
      let chartData = {
        labels: [],
        datasets: [],
      };
      let prediction = this.currentPrediction
      let segment = this.currentSegment
      if (prediction) {
        chartData.datasets.push(
          {
            label: prediction.name,
            lineTension: 0.3,
            pointRadius: 0,
            data: prediction.segments[segment].prediction.map((p) => p.turnover),
            borderColor: "#00D2DE",
            borderWidth: 2,
            // fill: true,
            // backgroundColor: this.fillColor,
          }
        );

        chartData.datasets.push({
            backgroundColor: "rgb(0, 210, 222, 0.25)",
            type: 'bar',
            data: this.accumulatedChannels(this.mediaplanung, prediction.year).map(v => v.gross),
        })

        chartData.labels = prediction.segments[segment].prediction.map(
          (p) => p.cw
        );
      }
      return chartData;
    },
    mediaplanung() {
      return this.currentPrediction.mediaplanung
    },
    yAxes() {
      return [{tickLabelPrefix: this.$store.state.config.currency}]
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.showCurrentPrediction && !vm.currentPrediction) {
        console.log("No current prediction set. Redirecting to /predictions.")
        vm.$router.push("/predictions")
      }
    })
  },
}
</script>

<style scoped>
.chart-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
.chart-container {
  display: flex;
  flex-direction: row;
}
.chart-diagram-container {
  flex-grow: 1;
  flex-basis: 100%;
  text-align: left;
}
h3 {
  margin-bottom: 0;
}
.subtitle {
  font-size: 14px;
  color: var(--c-dark-grey);
}
.mediaplan-name {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding-left: 20px;
}
.total-value {
  color: #000;
  font-size: 36px;
  white-space: nowrap;
}
.euro {
  color: var(--c-button-blue);
}

</style>
