<template>
  <div class="planning-step overview">
    <PlanningContainer v-if="currentTarget">
      <form>
        <h4>Overview</h4>
        <div class="section-with-link">
          <div class="left">
            Optimization goal<br>
            <span class="current-target">{{ currentTarget.label }}</span>
          </div>
          <div class="right">
            <a href="#" @click="$router.push('/planning/target')">Edit</a>
          </div>
        </div>
        <hr class="sidebar">

        <!-- Max Umsatz -->
        <div v-if="currentTarget.optimization_type == 'max_umsatz'" class="section-with-link">
          <div class="left">
            <span class="media-budget-title">Media budget</span>
            <span class="media-budget-value">&sum; {{ formatCurrency(currentTarget.annual_budget) }}</span>
          </div>
          <div class="right">
            <a href="#" @click="$router.push('/planning/budget')">Edit</a>
          </div>
        </div>
        <!-- Ziel-ROMI -->

        <div v-if="currentTarget.optimization_type == 'target_romi'" class="section-with-link">
          <div class="left">
            <span class="media-budget-title">Target ROMI</span>
            <span class="media-budget-value">{{ currentTarget.romi }}</span>
          </div>
          <div class="right">
            <a href="#" @click="$router.push('/planning/budget')">Edit</a>
          </div>
        </div>

        <!-- Jahresumsatz -->
        <div v-if="currentTarget.optimization_type == 'annual_turnover'" class="section-with-link">
          <div class="left">
            <span class="media-budget-title">Annual revenue</span>
            <span class="media-budget-value">&sum; {{ formatCurrency(currentTarget.annual_turnover) }}</span>
          </div>
          <div class="right">
            <a href="#" @click="$router.push('/planning/budget')">Edit</a>
          </div>
        </div>


        <hr class="sidebar">

        <table v-if="currentTarget.optimization_type == 'max_umsatz'" class="channel-info">
          <tr>
            <th class="label">Channel</th>
            <th class="min">MIN</th>
            <th class="max">MAX</th>
          </tr>
          <!-- <tr v-for="(payFactor, index) in currentTarget.pay_factors" v-bind:key="index"> -->
          <tr v-for="(channelBudget, index) in currentTarget.channel_budgets" v-bind:key="index">
            <td class="label">{{ channelBudget.label }}</td>
            <td class="min">{{ formatCurrency(channelBudget.annual_min) }}</td>
            <td class="max">{{ channelBudget.annual_max ? formatCurrency(channelBudget.annual_max) : 'Unlimited' }}</td>
          </tr>
        </table>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import CurrentTargetMixin from './CurrentTargetMixin.vue'
import PlanningContainer from "./PlanningContainer.vue"
import Util from "@/util.js"

export default {
  name: 'OverviewStep',
  components: {
      PlanningContainer
  },
  methods: {
    formatCurrency(number) {
      return Util.formatCurrency(number, this.$store.state.config.currency)
    }
  },
  mixins: [CurrentTargetMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
}
</script>

<style scoped>
table {
  width: 380px;
  table-layout: fixed
}

.section-with-link {
  display: flex;
  flex-direction: row;
}
span.current-target {
  color: var(--c-deep-grey);
  display: block;
  margin: 10px 0;
}
.right {
  flex-grow: 1;
  text-align: right;
}
hr.sidebar {
  margin: 20px 0;
  width: 420px;
}
table {
  border-spacing: 0;
}
td, th {
  padding: 0;
}
th {
  height: 20px;
  border: none;
}
td.label, th.label {
  /* width: 70%; */
}
td.min, td.max, th.min, th.max {
  text-align: right;
  color: var(--c-deep-grey);
}
th.min, th.max {
 font-size: 12px;
 letter-spacing: 2px;
 color: var(--c-dark-grey);
}
td.value {
  text-align: right;
  padding-right: 20px;
}
td {
  padding: 0;
  margin: 0;
  height: 80px;
  border-bottom: 1px solid var(--c-bright-grey);
}
.media-budget-title {
  display: block;
  margin-bottom: 10px;
}
.media-budget-value {
  color: var(--c-deep-grey);
}
</style>
