const planningStepKeys = Object.freeze({
  TARGET: "target",
  BUDGET: "budget",
  PAYFACTORS: "payfactors",
  OVERVIEW: "overview",
  NAME: "name",
  SCENARIOS: "scenarios",
  START: "start",
  INPROGRESS: "inprogress"
})
const initialState = {
  steps: [
    {
      key: planningStepKeys.TARGET,
      path: "/planning/target",
      validates: false
    },
    {
      key: planningStepKeys.BUDGET,
      path: "/planning/budget",
      validates: false
    },
    {
      key: planningStepKeys.PAYFACTORS,
      path: "/planning/payfactors",
      validates: true
    },
    {
      key: planningStepKeys.OVERVIEW,
      path: "/planning/overview",
      validates: true
    },
    {
      key: planningStepKeys.NAME,
      path: "/planning/name",
      validates: true
    },
    {
      key: planningStepKeys.SCENARIOS,
      path: "/planning/scenarios",
      validates: true
    },
    {
      key: planningStepKeys.START,
      path: "/planning/start",
      validates: true
    },
    {
      key: planningStepKeys.INPROGRESS,
      path: "/planning/inprogress",
      validates: true
    },
  ]
}

export const planning = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    stepValid(state, stepInfo) {
      let step = JSON.parse(JSON.stringify(state.steps[stepInfo.index]))
      step.validates = stepInfo.validates
      state.steps[stepInfo.index] = step
    },
    updateStepValidation(state, target) {
      let steps = JSON.parse(JSON.stringify(state.steps))
      var step

      // target
      step = steps.find( s => s.key == planningStepKeys.TARGET)
      step.validates = target

      if (!step.validates) {
        return
      }

      // budget
      step = steps.find( s => s.key == planningStepKeys.BUDGET)
      switch (target.optimization_type) {
        case "max_umsatz": {
          let minBudgetsSum = target.channel_budgets.reduce( (prev, curr) => prev += curr.annual_min, 0)
          step.validates = target.annual_budget > 0 && minBudgetsSum <= target.annual_budget
          break;
        }
        case "target_romi":
          step.validates = target.romi > 0
          break;
        case "annual_turnover":
          step.validates = target.annual_turnover > 0
          break;
      }
      // name
      step = steps.find( s => s.key == planningStepKeys.NAME)
      step.validates = target.mediaplan_label
      state.steps = steps

      // scenarios
      step = steps.find( s => s.key == planningStepKeys.SCENARIOS)
      step.validates = true
      for (var i=0; i<target.scenarios.length; i++) {
        if (target.scenarios[i] == null) {
          step.validates = false
          break
        }
      }
    }
  },
  getters: {
    planningStepPaths(state) {
      return state.steps.map( s => s.path )
    },
    isStepValid: (state) => (index) => {
      return state.steps[index].validates
    },
  }
};
