<template>
  <div class="widget-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WidgetContainer',
}
</script>

<style scoped>
.widget-container {
  background: #fff;
  border-radius: 7px;
  border: 1px solid var(--c-bright-grey);
  box-shadow: var(--std-box-shadow);
  flex-grow: 1;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
}
.widget-container.nopadding {
  padding: 0;
}
</style>

<style>
.widget-container > div {
  flex-grow: 1;
}
.widget-background-blue {
    background-color: var(--c-button-blue);
}
</style>