import axios from "../api"

const initialState = {
  token: process.env.VUE_APP_DEFAULT_TOKEN,
  tokenRefreshTimestamp: new Date().getTime(),
  tokenRefreshTimeout: process.env.VUE_APP_TOKEN_REFRESH_TIMEOUT_SECONDS,
  reportPresentationStyle: "tabs",
}

export const user = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    token(state, token) {
      state.token = token
    },
    error(state, error) {
      state.error = error
    },
    updateTokenRefreshTimestamp(state) {
      state.tokenRefreshTimestamp = new Date().getTime()
    },
    reportPresentationStyle(state, reportPresentationStyle) {
      state.reportPresentationStyle = reportPresentationStyle
    }
  },
  actions: {
    login({ commit }, user) {
      commit("error", null)
      return axios.post("/user/login", user)
      .then((response) => {
        commit("token", response.data.data.access_token)
        commit("updateTokenRefreshTimestamp")
      })
      .catch((response) => {
        commit("ui/error", response, { root: true })
        throw(new Error("Login Failed"))
      })
    },
    logout({ commit }) {
      commit("token", null)
    },
    renewToken({ rootGetters, commit }) {
      return axios.get("/user/renew-token", {
        headers: rootGetters["user/authHeader"],
      }).then((response) => {
        commit("token", response.data.data.access_token)
        commit("updateTokenRefreshTimestamp")
      })
      .catch((response) => {
        commit("ui/error", response, { root: true })
        throw(new Error("Login Failed"))
      })
    },
    changePassword() {
      // post
      // {{base_url}}/user/password
    }
  },
  getters: {
    authHeader: state => {
      return { 'Authorization': `JWT ${state.token}` }
    },
    isTokenRefreshNeeded: state => {
      return (new Date().getTime() - state.tokenRefreshTimestamp) / 1000 > state.tokenRefreshTimeout
    },
    reportPresentationStyle: state => {
      return state.reportPresentationStyle
    }
  }
};
