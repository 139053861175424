<template>
  <nav class="nav main">
    <div class="nav-inner">
      <div class="nav-items">
        <div v-for="(item, index) in navItems" v-bind:key="index" :class="'item ' + (activeIndex == index ? 'active' : '')" :style="itemStyle">
          <router-link :to="item.path" @click="navigate(index)">{{ item.name }}</router-link>
        </div>
      </div>
      <div class="active-indicator" :style="activeIndicatorStyle"></div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'MainNav',
  props: {
  },
  data: function() {
    return {
      activeIndex: -1,
      itemWidth: 140,
      activeIndicatorWidth: 42
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["config/currentUser"]
    },
    reportsOnly() {
      return this.$store.getters["config/reportsOnly"]
    },
    navItems() {
      let items = this.reportsOnly ? [] : [
        {
          path: '/',
          aliases: ['/planning'],
          name: 'Dashboard'
        },
        {
          path: '/mediaplans',
          name: 'Media Plans'
        },
        {
          path: '/predictions',
          name: 'Forecasts'
        },
        {
          path: '/analysis',
          name: 'Analysis'
        },
      ]
      items.push(
        {
          path: '/reports',
          name: 'Reports'
        }
      )
      if (this.currentUser?.is_simcog_user) {
        items.push({path: '/internal', name: "Internal"})
      }
      return items
    },
    itemStyle() {
      return {
        width: this.itemWidth + 'px'
      }
    },
    activeIndicatorStyle() {
      return {
        display: this.activeIndex > -1 ? 'block' : 'none',
        width: this.activeIndicatorWidth + 'px',
        left: (this.activeIndex * this.itemWidth + (this.itemWidth / 2 - this.activeIndicatorWidth / 2)) + 'px'
      }
    },
    path() {
      return this.$route.path
    }
  },
  methods: {
    navigate(index) {
      this.activeIndex = index
    },
    updateIndexWithPath(path) {
      let level1 = "/" + path.split("/")[1]
      let newIndex = this.navItems.findIndex( n => n.path == level1 || (n.aliases && n.aliases.find( a => a == level1)))
      if (newIndex > -1) {
        this.activeIndex = newIndex
      }
    }
  },
  watch: {
    path: function(newPath) {
      this.updateIndexWithPath(newPath)
    },
    navItems() {
      this.updateIndexWithPath(this.path)
    }
  },
  mounted() {
    this.updateIndexWithPath(this.path)
  }
}
</script>

<style scoped>
a:link, a:visited {
  all: unset;
}
nav {
  display: flex;
  flex-direction: column;
}

.container.info-shown nav {
  display: none;
}

.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nav-inner {
  position: relative;
}
.item {
  text-transform: uppercase;
  cursor: pointer;
  color: var(--c-greyish);
  letter-spacing: 2px;
  font-size: 14px;
  text-align: center;
}
.item.active {
  color: #000;
  font-weight: 700;
}
.active-indicator {
  position: absolute;
  background-color: var(--c-button-blue);
  height: 2px;
  transition: left 0.1s ease-in-out;
  margin-top: 12px;
}

</style>
