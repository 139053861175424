
<template>
  <div>
  </div>
</template>

<script>
import useUI from '@/js/useUI.js'

export default {
  data: function() {
    return {
      viewToBeDeletedIndex: -1,
      reportToBeDeleted: null,
      reportToBeRenamed: null,
      reportToBeSaved: null,
      reportToBeSavedAs: null,
      routeAfterSave: null,
      leaveWithoutSaving: false,
      cancelButtonText: "Cancel"
    }
  },
  computed: {
    numberOfReportViewsTitles() {
      let titles = []
      this.reports.forEach(r => {
        titles[r.id] = this.numberOfReportViewsTitle(r)
      });
      return titles
    },
    deleteConfirmMessage() {
      return `Do you really want to delete <b>${this.reportToBeDeleted.title}</b>? This deletes the report permanently and cannot be undone.`
    },
    isSaveButtonDisabled() {
      return !(this.report?.predefined || this.report?.transient)
    },
  },
  methods: {
    numberOfReportViewsTitle(report) {
      if (!report || report.n_views == 0) {
          return "0 views"
        } else {
          return report.n_views > 1 ? (report.n_views + ' views') : '1 view'
        }

    },
    numberOfDatasetsTitle(view) {
      if (!view || view.datasets?.length == 0) {
          return "0 datasets"
        } else {
          return view.datasets?.length > 1 ? (view.datasets.length + ' datasets') : '1 dataset'
        }
    },
    onCancelSave() {
      useUI(this.$store).unblockUI()
      this.reportToBeSaved = null
      this.reportToBeSavedAs = null
      this.reportToBeRenamed = null
      if (this.routeAfterSave) {
        this.leaveWithoutSaving = true
        this.$router.push(this.routeAfterSave)
        this.routeAfterSave = null
      }
    },
    doSave(report) {
      if (report.title.length == 0) {
        return
      }

      let mutation = report.id ? "reports/updateReport" : "reports/addReport"
      if (report.predefined || report.locked) {
        mutation = "reports/addReport"
        delete report.id
        delete report.predefined
        delete report.locked
      }
      report.save = true
      this.$store.dispatch(mutation, report).then( (responseReport) => {
        this.$store.commit("reports/currentReport", responseReport)
        this.$router.push(`/reports/${responseReport.id}`) ///views/${this.viewIndex}`)
        if (this.routeAfterSave) {
          this.$router.push(this.routeAfterSave)
        }
      }).catch( err => {
        this.$store.commit("ui/error", err)
      }).finally( () => {
        this.reportToBeRenamed = null
        this.reportToBeSaved = null
        this.reportToBeSavedAs = null
        this.routeAfterSave = null
        useUI(this.$store).unblockUI()
      })
    },
    doSaveAs(report) {
      let reportCopy = JSON.parse(JSON.stringify(report))
      delete reportCopy.id
      delete reportCopy.predefined
      this.doSave(reportCopy)
    },
    doDeleteReport() {
      this.$store
        .dispatch("reports/deleteReport", this.reportToBeDeleted.id)
        .then(() => {
          this.reportToBeDeleted = null;
          this.$store.dispatch("reports/fetchReports")
          this.$router.push("/reports")
        }).catch( err => {
          this.$store.commit("ui/error", err)
        }).finally( () => {
          useUI(this.$store).unblockUI();
        })
    },
    onCancelDelete() {
      useUI(this.$store).unblockUI()
      this.reportToBeDeleted = null;
    },
    onCancelRename() {
      useUI(this.$store).unblockUI()
      this.reportToBeRenamed = null;
    },
    doChangeVisibility(report, newVisibility) {
      this.$store.dispatch("reports/fetchReport", report.id).then( (fullReport) => {
        fullReport.visibility = newVisibility
        this.$store.dispatch("reports/updateReport", fullReport).then( (responseReport) => {
          Object.assign(report, responseReport)
        }).catch( err => {
          this.$store.commit("ui/error", err)
        })
      }).catch( err => {
          this.$store.commit("ui/error", err)
      }).finally( () => {
        useUI(this.$store).unblockUI()
      })
    },
    doUpdateReportLockState(report, new_locked) {
      const payload = {id: report.id, locked: new_locked}
      this.$store
        .dispatch("reports/updateReportLockState", payload)
        .then((responseReport) => {
          // this.$store.dispatch("reports/fetchReports")
          Object.assign(report, responseReport)
        })
        .catch((err) => {
          this.$store.commit("ui/error", err)
        }).finally(() => {
          useUI(this.$store).unblockUI();
        });
    },
    doRename() {
      if (this.reportToBeRenamed.title.length == 0) {
        return
      }

      let mutation = "updateTransient"
      if (this.reportToBeRenamed.id) {
        this.reportToBeRenamed.save = true
        mutation = "updateReport"
      }

      this.$store.dispatch(`reports/${mutation}`, this.reportToBeRenamed).then( () => {
        this.reportToBeRenamed = null
        this.$store.dispatch("reports/fetchReports")
      }).catch( err => {
        this.$store.commit("ui/error", err)
      }).finally( () => {
        this.reportToBeRenamed = null
        useUI(this.$store).unblockUI()
      })
    }
  }
}
</script>

<style scoped>
</style>
