
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <h2 id="anchor-general">General</h2>
      <WidgetContainer>
        <table>
          <tr>
            <td>future_max_date:</td><td>{{ future_max_date }}</td>
          </tr>
          <tr>
            <td>last_known_turnover_date:</td><td>{{ last_known_turnover_date }}</td>
          </tr>
          <tr>
            <td>last_known_advertising_date:</td><td>{{ last_known_advertising_date }}</td>
          </tr>
        </table>
      </WidgetContainer>

      <div class="mediaplans-container">
        <h2 id="anchor-historic-mediaplans">Historic media plans</h2>
        <WidgetContainer>
          <div class="downloadlinks-container">
            <h3>Regional (full) plans</h3>
            <ul>
              <li>
                Download plan for the
                <a href="#" @click="onHistoricDownloadClick(null)">complete history</a>
              </li>
              <li v-for="year in historicYears" v-bind:key="year.key">
                Download plan for the
                <a href="#" @click="onHistoricDownloadClick(year.key)">{{ year.label_full }}</a>
              </li>
            </ul>
          </div>
          <div class="downloadlinks-container">
            <h3>Nationally aggregated plans</h3>
            <ul>
              <li>
                Download plan for the
                <a href="#" @click="onHistoricDownloadClick(null, true)">complete history</a>
              </li>
              <li v-for="year in historicYears" v-bind:key="year.key">
                Download plan for the
                <a href="#" @click="onHistoricDownloadClick(year.key, true)">{{ year.label_full }}</a>
              </li>
            </ul>
          </div>
        </WidgetContainer>
      </div>

      <div class="models-container">
        <h2 id="anchor-models">Models</h2>
        <!--TODO: Make the model a Vue Component-->
        <WidgetContainer>
        <div class="model-details-container" v-for="(model, index) in models" v-bind:key="index">
          <h2>{{ model.name }}</h2>
          <table>
            <tr>
              <td>User:</td><td>{{ model.user }}</td>
            </tr>
            <tr>
              <td>Creation time:</td><td>{{ model.create_time }}</td>
            </tr>
          </table>
          <h3>Ranges</h3>
          <table>
            <thead>
              <tr>
                <th>Col</th>
                <th>Min</th>
                <th>Max</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(details, feat_idx) in model.features_minmax" v-bind:key="feat_idx">
                <td>{{ details.col }}</td><td>{{ details.min }}</td><td>{{ details.max }}</td>
              </tr>
            </tbody>
          </table>

          <h3>Targets</h3>
          <table>
            <thead>
              <tr>
                <th>Col</th>
                <th>Min</th>
                <th>Max</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(details, feat_idx) in model.targets_minmax" v-bind:key="feat_idx">
                <td>{{ details.col }}</td><td>{{ details.min }}</td><td>{{ details.max }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </WidgetContainer>
      </div>
      <h2 id="anchor-pipeline">Pipeline</h2>
      <WidgetContainer>
        <div class="pipeline">{{ pipeline }}</div>
      </WidgetContainer>
      <!--
        <h2 id="anchor-downloads">Downloads</h2>
        <WidgetContainer>
          TODO: Offer downloads of the df_dict and the pipeline here.
        </WidgetContainer>
      -->
    </div>
  </div>
</template>

<script>
import WidgetContainer from "../components/widgets/WidgetContainer.vue";
import UiMixin from "@/components/mixins/UiMixin.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'InternalInfoPage',
  mixins: [UiMixin],
  components: {
    WidgetContainer,
  },
  props: {
  },
  data: function() {
    return {
    }
  },
  computed: {
    pipeline() {
      return this.$store.getters["internal/pipeline"] ?? ""
    },
    models() {
      return this.$store.getters["internal/models"] || []
    },
    future_max_date() {
      return this.$store.getters["internal/future_max_date"] || ""
    },
    last_known_advertising_date() {
      return this.$store.getters["internal/last_known_advertising_date"] || ""
    },
    last_known_turnover_date() {
      return this.$store.getters["internal/last_known_turnover_date"] || ""
    },
    historicYears() {
      return this.$store.getters["config/historicYears"] ?? []
    },
    // currentViewIndex: {
    //   get() {
    //     return this.$store.getters["reports/currentViewIndex"]
    //   },
    //   set(idx) {
    //     this.$store.commit("reports/currentViewIndex", idx)
    //   }
    // },
  },
  methods: {
    onHistoricDownloadClick(year, as_national=false) {
      this.download(
        "mediaplanning/downloadHistoricPlan",
        {year: year, as_national: as_national}
      )
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      useUI(vm.$store).blockUI({ loading: true, complete: false, chart: true })
      vm.$store.dispatch("internal/fetchModelinfo").then( (modelinfo) => {
        vm.$store.commit("internal/future_max_date", modelinfo.future_max_date ?? "")
        vm.$store.commit("internal/last_known_advertising_date", modelinfo.last_known_advertising_date ?? "")
        vm.$store.commit("internal/last_known_turnover_date", modelinfo.last_known_turnover_date ?? "")
        vm.$store.commit("internal/pipeline", modelinfo.pipeline)
        vm.$store.commit("internal/models", modelinfo.models)
        vm.$store.commit("internal/currentModel", modelinfo.models[0] ?? {})
        vm.$router.push("/internal")
        useUI(vm.$store).unblockUI()
      })
    })
  },
}
</script>

<style scoped>
h2 {
  text-align: left;
}

.pipeline {
  white-space: pre-wrap;
  text-align: left;
  max-width: 80rem;
  font-family: monospace;
}

table {
  text-align: left;
}
/*
.model-details-container {
  background-color: white;
} */

.models-container h3 {
  text-align: left;
}

.mediaplans-container {
  text-align: left;
}

.downloadlinks-container a {
  text-transform: lowercase;
}

</style>
