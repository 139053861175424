<template>
  <div :class="'simple-tile' + (selected ? ' active' : '')" @click="onClick">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'SimpleTile',
  props: {
      text: {
          type: String,
          default: ''
      },
      selected: {
        type: Boolean
      }
  },
  data: function () {
    return {
    }
  },
  methods: {
      onClick() {

      }
  }
}
</script>

<style scoped>
.simple-tile {
    background: var(--c-white-grey);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 7px;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 0 16px;

}

.simple-tile.active, .simple-tile.active:hover {
    background: var(--c-button-blue);
    color: #fff;
}

.simple-tile:hover {
    background: var(--c-soft-grey);
}

</style>
