<template>
  <div class="admin-panel">
      <pre class="admin-info">devmode: {{ $store.state.admin.isDevModeEnabled }}<br>token: {{ $store.state.user.token }}</pre>
      <input type="checkbox" v-model="isDevModeEnabled"> Dev Mode Enabled
  </div>
</template>

<script>
export default {
  name: "AdminPanel",
  computed: {
    isDevModeEnabled: {
      get () {
        return this.$store.state.admin.isDevModeEnabled
      },
      set (value) {
        this.$store.commit('admin/isDevModeEnabled', value)
      }
    }
  }
};
</script>

<style scoped>
.admin-panel {

}

.admin-info {
  width: 300px;
  word-break: break-all;
  white-space: normal;
  user-select: all;
}
</style>
