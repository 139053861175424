<template>
  <div class="progress-bar">
      <div class="bar">
          <div class="bar-inner" :style="innerBarStyle"></div>
      </div> {{ percentage }}
  </div>
</template>

<script>

export default {
  name: 'ProgressBar',
  props: {
    completed: {
        type: Number,
        default: 0
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
      percentage() {
          return this.completed + " %"
      },
      innerBarStyle() {
          return {
              width: this.completed + "%"
          }
      }
  }
}
</script>

<style scoped>
.progress-bar {
    max-width: 130px;
    min-width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}
.bar {
    background: var(--c-soft-grey);
    border-radius: 8px;
    height: 8px;
    flex-grow: 1;
    margin-right: 10px;
    position: relative;
}
.bar-inner {
    background: var(--c-button-blue);
    width: 50%;
    position: absolute;
    border-radius: 8px;
    height: 8px;
}
</style>
