<template>
  <div class="welcome-sidebar">
    <SidebarContent>
        <template v-slot:content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Welcome {{ firstname }}!<br>My name is PIA and I&nbsp;will support you to make the best decisions
            </h1>
            <div class="subtitle">How can I help you?</div>
            <PiaAssistant />
          </div>
        </template>
        <template v-slot:actions>
          <div class="action-buttons">
            <button class="button primary tall" @click="onAnalyze">Analyze for me</button>
            <button class="button ghost tall" @click="onPlan">Plan for me</button>
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import PiaAssistant from "@/components/ui/PiaAssistant.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'WelcomeSidebar',
  components: {
      SidebarContent,
      PiaAssistant
  },
  props: {
  },
  computed: {
    firstname() {
      return this.$store.getters["config/currentUser"]?.firstname
    }
  },
  methods: {
      onAnalyze() {
        this.$router.push("/analysis")
      },
      onPlan() {
        this.$router.push("/planning")
        useUI(this.$store).blockUI()
      }
  }
}
</script>

<style scoped>
.welcome-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h1 {
    font-weight: 500;
    font-size: 32px;
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.action-buttons .button {
  margin-right: 30px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
  color: var(--c-deep-grey);
  letter-spacing: 0.5px;
}
</style>
