<template>
  <div class="planning-step name">
    <PlanningContainer v-if="currentTarget">
      <form>
        <h3>Media plan name</h3>
        <fieldset>
          <label>Name</label>
          <TextInput type="text" v-model="currentTarget.mediaplan_label"/>
          <div class="save-as-template-text">Save media planning conditions <a href="#" @click="onSaveAsTemplate">as template</a></div>
        </fieldset>
      </form>
    </PlanningContainer>
    <ConfirmDialog v-if="showSaveAsTemplateDialog" title="Save as template">
        <template v-slot:message>Please enter a name for the template.</template>
        <template v-slot:content>
          <TextInput class="input-template-name" type="text" v-model="templateName" />
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="doCancelSaveAsTemplate" class="button ghost mediumsize rounded">Cancel</button>
            <button :disabled="!saveButtonEnabled" @click.stop="doSaveAsTemplate" class="button primary mediumsize rounded">Save</button>
          </div>
        </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import PlanningContainer from "./PlanningContainer.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import UiMixin from "@/components/mixins/UiMixin.vue"
import TextInput from "@/components/ui/TextInput.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'BudgetStep',
  mixins: [UiMixin, CurrentTargetMixin],
  components: {
      PlanningContainer,
      ConfirmDialog,
      TextInput
  },
  data: function() {
    return {
      showSaveAsTemplateDialog: false,
      templateName: null,
    }
  },
  computed: {
    saveButtonEnabled() {
      return this.templateName && this.templateName.length >= 3
    }
  },
  methods: {
    onSaveAsTemplate() {
      useUI(this.$store).blockUI({ complete: true });
      this.templateName = null
      this.showSaveAsTemplateDialog = true
    },
    doCancelSaveAsTemplate() {
      useUI(this.$store).unblockUI();
      this.showSaveAsTemplateDialog = false
    },
    doSaveAsTemplate() {
      this.currentTarget.label = this.templateName
      this.showSaveAsTemplateDialog = false
      this.$store.dispatch("ai/createNewTemplate", this.currentTarget)
      useUI(this.$store).unblockUI();
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
}
</script>
<style scoped>
.save-as-template-text {
  margin: 20px 0;
  font-size: 14px;
}
</style>
<style>
input[type="text"].input-template-name {
  width: 250px;
  margin-bottom: 40px;
}
</style>
