<template>
  <div class="start-planning">
    <SidebarContent>
        <template v-slot:content>
          <div class="scrollable-container sidebar">
            <h1 class="underline">
                Click "Start" to create an AI optimized media plan.
            </h1>
            <div class="subtitle">I calculate the best possible media plan for you, based on your specifications. You will receive an e-mail as soon as the media plan is available.</div>
            <PiaAssistant />
          </div>
        </template>
        <template v-slot:actions>
            <button class="button primary tall" @click="onStart">Start</button>
            <button class="button ghost tall" @click="onCancel">Back</button>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "../SidebarContent.vue"
import PiaAssistant from "@/components/ui/PiaAssistant.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'

export default {
  name: 'StartPlanning',
  mixins: [CurrentTargetMixin],
  components: {
      SidebarContent,
      PiaAssistant
  },
  computed: {
    currentTarget() {
      return this.$store.state.ai.currentTarget
    }
  },
  methods: {
      onStart() {
        this.$store.dispatch("ai/startOptimization", this.currentTarget).then( () => {
          this.$store.dispatch("mediaplanning/fetchAllPlans")
        })
        this.$router.push("/planning/inprogress")
      },
      onCancel() {
        let lastIndex = this.$store.getters["planning/planningStepPaths"].length - 3
        this.$router.push(this.$store.getters["planning/planningStepPaths"][lastIndex])
      }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
}
</script>

<style scoped>
.start-planning {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
h1 {
    font-weight: 500;
    font-size: 32px;
    padding: 40px;
    flex-grow: 1;
}
.button {
  margin-right: 30px;
}
</style>
