<template>
    <div :class="dropdownClazz">
        <ul ref="optionsList" :class="isActive ? 'active' : ''">
            <li v-for="(option, index) in availableOptions" v-bind:key="index" :value="option.value" @mouseup.stop="onSelectLi(option)" v-html="option.label"></li>
        </ul>
        <div ref="valueBox" class="value-box">
            <div :class="clazz" @mousedown.prevent="toggleDropdown" @mouseleave="onMouseLeave" v-html="displayValue">
            </div>
            <div v-if="showPrefix" :class="'prefix' + (isActive ? ' active' : '')">
                <slot name="prefix"></slot>
            </div>
            <div v-if="showSuffix" :class="'suffix' + (isActive ? ' active' : '')">
                <slot name="suffix"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ellipsize from "ellipsize";
import getScrollParent from "@/js/getScrollParent.js"

export default {
  name: "DropDown",
  components: {},
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    showPrefix: {
      type: Boolean,
      default: false,
    },
    showSuffix: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
    },
    allowNoSelection: {
      type: Boolean,
      default: true
    },
    maxStringLength: {
      type: Number,
      default: 33
    },
    disabled: {
      type: Boolean
    }
  },
  data: function () {
    return {
      isActive: false,
      valueBoxNaturalRect: null,
      optionsListNaturalRect: null
    };
  },
  computed: {
    displayValue() {
      return this.modelValue != null
        ? this.limitString("" + this.valueLabel)
        : "Select";
    },
    valueLabel() {
      return (
        this.availableOptions?.find((opt) => opt.value == this.modelValue)
          ?.label || ""
      );
    },
    clazz() {
      let s = "selectbox";
      s += (this.isActive ? " active" : "");
      s += (this.showPrefix ? " has-prefix" : "");
      s += (this.showSuffix ? " has-suffix" : "");
      return s;
    },
    dropdownClazz() {
      let s = "dropdown"
      s += (this.modelValue != null ? " value-set" : "");
      s += (this.allowNoSelection ? " allows-no-selection" : "")
      s += (this.disabled ? " disabled" : "")
      return s
    },
    availableOptions() {
      if (this.modelValue != null && this.allowNoSelection) {
        return [
          {
            label: "No selection",
            value: null,
          },
        ].concat(this.options);
      }
      return this.options;
    },
  },
  methods: {
    limitString(str) {
      return this.maxStringLength ? ellipsize(str, this.maxStringLength, {chars: []}) : str;
    },
    onSelectLi(option) {
      this.$emit("update:modelValue", option.value);
      this.isActive = false;
    },
    toggleDropdown() {
      if (this.disabled) return
      this.isActive = !this.isActive;
    },
    handleDocumentClick() {
      this.isActive = false;
    },
    onBlur() {
      this.isActive = false
    },
    onResize() {
      this.isActive = false
    },
    onScroll() {
      this.isActive = false
    },
    onMouseLeave() {
      if (this.isActive) {
        document.removeEventListener("click", this.handleDocumentClick);
        document.addEventListener("click", this.handleDocumentClick);
      }
    },
    updateOptionsListStyle() {
        let ul = this.$refs.optionsList;
        let valueBoxEl = this.$refs.valueBox;
        let valueBoxRect = this.valueBoxNaturalRect
        valueBoxEl.style.position = "fixed";
        valueBoxEl.style.left = valueBoxRect.left + "px";
        valueBoxEl.style.top = valueBoxRect.top + "px";
        valueBoxEl.style.width = valueBoxRect.width + "px";
        valueBoxEl.style.zIndex = 100000
        let distanceToBottom = (window.innerHeight - valueBoxRect.top - valueBoxRect.height) - this.optionsListNaturalRect.height
        ul.style.position = "fixed";
        ul.style.left = valueBoxRect.left + "px";
        ul.style.top = valueBoxRect.top + valueBoxRect.height + "px";
        ul.style.width = valueBoxRect.width + "px";
        ul.style.zIndex = 100000
        if (distanceToBottom < 0) {
            ul.style.overflowX = "auto"
            ul.style.height = (this.optionsListNaturalRect.height + distanceToBottom) + "px";
        }
    },
    resetOptionsListStyle() {
        let ul = this.$refs.optionsList;
        ul.style.position = null
        ul.style.top = null
        ul.style.left = null
        ul.style.width = null
        ul.style.height = null
        ul.style.overflowX = null
        ul.style.zIndex = null
        let valueBoxEl = this.$refs.valueBox;
        valueBoxEl.style.position = null
        valueBoxEl.style.top = null
        valueBoxEl.style.left = null
        valueBoxEl.style.width = null
        valueBoxEl.style.height = null
        valueBoxEl.style.zIndex = null
    }
  },
  watch: {
      isActive(active) {
        if (active) {
            this.$nextTick(() => {
                let rect = this.$refs.optionsList.getBoundingClientRect()
                this.optionsListNaturalRect = rect
                this.valueBoxNaturalRect = this.$refs.valueBox.getBoundingClientRect()
                this.updateOptionsListStyle();
                window.addEventListener("resize", this.onResize);
                setTimeout( () => {
                    getScrollParent(this.$el).addEventListener("scroll", this.onScroll)
                }, 200)
            });
        } else {
            getScrollParent(this.$el).removeEventListener("scroll", this.onScroll)
            this.resetOptionsListStyle()
            document.removeEventListener("click", this.handleDocumentClick);
            window.removeEventListener("resize", this.onResize);
        }
      }
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  margin-bottom: 20px;
  height: 54px;
}

.dropdown.disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
.selectbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  border-radius: 7px;
  /* width: calc(100% - 40px); */
  height: 54px;
  color: var(--c-dark-blue);
  padding: 0 20px;
  background: var(--c-white-grey)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgOS43NDNsLTYtNiAxLjQ4Ny0xLjQ4Nkw2IDYuNzdsNC41MTMtNC41MTNMMTIgMy43NDN6Ii8+PC9zdmc+")
    no-repeat;
  background-size: 12px 12px;
  background-position: right 20px top 50%;
  white-space: nowrap;
}
.selectbox.has-prefix {
  padding: 0 40px 0 40px;
}
.selectbox select {
  display: none;
}
.selectbox.active {
  outline: 1px solid var(--c-medium-grey);
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 16px;
  border-radius: 7px;
  width: calc(100% - 40px);
  height: 54px;
  color: var(--c-dark-blue);
  padding: 0 20px;
  background: var(--c-white-grey)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgOS43NDNsLTYtNiAxLjQ4Ny0xLjQ4Nkw2IDYuNzdsNC41MTMtNC41MTNMMTIgMy43NDN6Ii8+PC9zdmc+")
    no-repeat;
  background-size: 12px 12px;
  background-position: right 20px top 50%;
}
select.has-prefix {
  padding: 0 40px 0 40px;
  width: calc(100% - 80px);
}

select.active {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZmlsbD0iIzNmNTFmMiIgZD0iTTYgMi4yNTdsNiA2LTEuNDg3IDEuNDg2TDYgNS4yMyAxLjQ4NyA5Ljc0MyAwIDguMjU3eiIvPjwvc3ZnPg==");
  z-index: 999;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  outline: 1px solid var(--c-medium-grey);
}

select:focus {
  outline: 1px solid var(--c-medium-grey);
}

option {
  display: none;
}
ul {
  padding: 4px 0;
  margin: 0;
  position: absolute;
  top: 54px;
  width: 100%;
  display: none;
  z-index: 998;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: var(--c-white-grey);
}
ul.active {
  display: block;
  transform: translate(0, -4px);
  outline: 1px solid var(--c-medium-grey);
  z-index: 999;
  box-shadow: 0 3px 0px var(--c-medium-grey);
}
li {
  list-style-type: none;
  height: 54px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--c-deep-grey);
  cursor: pointer;
}
.dropdown.allows-no-selection.value-set li:first-child {
  font-style: italic;
}
li:last-child {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
li:hover {
  color: var(--c-dark-blue);
  background-color: var(--c-soft-grey);
}
.prefix {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 54px;
  width: 40px;
}
.prefix.active {
  z-index: 999;
}

</style>
