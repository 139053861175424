<template>
  <div class="mediaplan-sidebar">
    <SidebarContent>
        <template v-slot:content>
            <h2 class="underline left">
                Upload media plan
            </h2>
            <div class="scrollable-container sidebar">
              <form>
                <fieldset>
                  <label>Drag &amp; Drop Zone</label>
                  <DropZone />
                  <!-- <button class="button primary tall">Upload media plan</button> -->
                </fieldset>
                <hr class="sidebar">
                <fieldset>
                  <h3>Media plan template</h3>
                  <DownloadTile label="Media plan (national)" dispatchPath="mediaplanning/fetchTemplateNational" />
                  <DownloadTile label="Media plan (regional)" dispatchPath="mediaplanning/fetchTemplateRegional" />
                </fieldset>
              </form>
            </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"
import DropZone from "@/components/forms/DropZone.vue"
import DownloadTile from "@/components/ui/DownloadTile.vue"

export default {
  name: 'MediaplanSidebar',
  components: {
      SidebarContent,
      DropZone,
      DownloadTile
  },
  props: {
  }
}
</script>

<style scoped>
.mediaplan-sidebar {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}

</style>
