<template>
  <div class="analysis-margin-tiles">
    <fieldset>
      <h3>Comparison in overlapping period</h3>
      <label>Sell-out revenue</label>
      <MarginTile
        :referenceTotalValue="turnoverSums.sumReference"
        :probeTotalValue="turnoverSums.sumProbe" />
    </fieldset>
    <fieldset>
      <label>Media budget (gross)</label>
      <MarginTile
        :referenceTotalValue="turnoverSums.sumReferenceMediaplan"
        :probeTotalValue="turnoverSums.sumProbeMediaplan"  />
    </fieldset>
  </div>
</template>

<script>
import AnalysisMixin from "@/components/mixins/AnalysisMixin.vue"
import UiMixin from "@/components/mixins/UiMixin.vue";

import MarginTile from "@/components/ui/MarginTile.vue"

export default {
  name: 'AnalysisMarginTiles',
  mixins: [AnalysisMixin, UiMixin],
  components: {
    MarginTile,
  },
  computed: {
    turnoverSums() {
      return this.calculateTurnoverSums()
    },
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: 10px;
  margin-top: 0;
}
</style>
