<template>
  <div class="planning-step budget-max-turnaround">
    <PlanningContainer>
      <form ref="form">
        <fieldset>
          <div :style="mediaBudgetStyle" class="media-budget">
            <h4 :style="mediaBudgetHeadlineStyle">Media budget</h4>
            <label :style="mediaBudgetLabelStyle">Total media budget (net)</label>
            <CurrencyInput class="annual-budget" :min="0" :placeholder="formatCurrency(0)" v-model="currentTarget.annual_budget"></CurrencyInput>
            <div :class="'hint min-budgets-sum-out-of-bounds ' + (isMinBudgetsSumOutOfBounds ? 'visible' : '')">
              <inline-svg :src="require('@/assets/svg/warning.svg')" width="12" height="12" class="icon"></inline-svg>
              <div class="text">Sum of min budgets larger than total budget. Difference: {{ formatCurrency(minBudgetsSum - currentTarget.annual_budget) }}</div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div :class="'max-budgets-year ' + (isMinBudgetsSumOutOfBounds ? 'validation-error' : '')">
            <h4>Max. budgets (annual)</h4>
            <table>
              <template v-for="(channelBudget, index) in currentTarget.channel_budgets" v-bind:key="index">
                <tr class="channel">
                  <td colspan="2">
                    <label class="budget-label">{{channelBudget.label}}</label>
                  </td>
                </tr>
                <tr class="minmax">
                  <td class="channel-min">
                    <label ref="inputMinLabel" class="label-min">MIN</label>
                    <CurrencyInput ref="inputMin"
                      :placeholder="formatCurrency(0)"
                      :min="minFieldMinValues[index]"
                      :max="minFieldMaxValues[index]"
                      v-model="channelBudget.annual_min"
                      @parseerror:outofbounds="minOutOfBounds($event, index)">
                    </CurrencyInput>
                    <div :class="'hint ' + (minHintsVisible[index] ? 'visible' : '')">{{ minHints[index] }}</div>
                  </td>
                  <td class="channel-max">
                    <label ref="inputMaxLabel" class="label-max">MAX</label>
                    <CurrencyInput ref="inputMax"
                      placeholder="Unlimited"
                      :min="maxFieldMinValues[index]"
                      :max="maxFieldMaxValues[index]"
                      v-model="channelBudget.annual_max"
                      @parseerror:outofbounds="maxOutOfBounds($event, index)">
                    </CurrencyInput>
                    <div :class="'hint ' + (maxHintsVisible[index] ? 'visible' : '')">{{ maxHints[index] }}</div>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </fieldset>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import PlanningContainer from "../../PlanningContainer.vue"
import CurrencyInput from "@/components/ui/CurrencyInput.vue"
import useUI from '@/js/useUI.js'
import Util from "@/util.js"

export default {
  name: 'BudgetStepMaxTurnaround',
  components: {
      PlanningContainer,
      CurrencyInput,
  },
  data: function() {
    return {
      scrollAmount: 0,
      minHints: [],
      maxHints: [],
      minHintsVisible: [],
      maxHintsVisible: [],
    }
  },
  computed: {
    currentTarget() {
      return this.$store.state.ai.currentTarget
    },
    budgets() {
      return this.currentTarget.channel_budgets
    },
    minBudgetsSum() {
      return this.budgets.reduce( (prev, curr) => {
        prev += curr.annual_min
        return prev
      }, 0)
    },
    isMinBudgetsSumOutOfBounds() {
      return this.minBudgetsSum > this.currentTarget.annual_budget
    },
    annualBudget() {
      return this.currentTarget.annual_budget
    },
    minFieldMinValues() {
      return this.budgets.map( budget => this.convertMinFromModel(budget.bounds.annual_min) )
    },
    minFieldMaxValues() {
       return this.budgets.map( budget => Math.min(this.annualBudget, budget.annual_max || Number.MAX_SAFE_INTEGER ) )
    },
    maxFieldMinValues() {
      return this.budgets.map( budget => budget.annual_min )
    },
    maxFieldMaxValues() {
      return this.budgets.map( budget => this.convertMaxFromModel(budget.bounds.annual_max) )
    },
    mediaBudgetHeadlineStyle() {
      return {
        marginTop: ((1 - Math.min(0.5, this.scrollAmount)) * 20) + "px",
        marginBottom: ((1 - Math.min(0.5, this.scrollAmount)) * 20) + "px",
      }
    },
    mediaBudgetLabelStyle() {
      return {
        marginTop: ((1 - this.scrollAmount) * 20) + "px",
        marginBottom: ((1 - this.scrollAmount) * 20) + "px",
        height: ((1 - this.scrollAmount) * 16) + "px",
        opacity: ((1 - this.scrollAmount)),
      }
    },
    mediaBudgetStyle() {
      return {
        boxShadow: `0 4px 20px -20px rgba(0, 0, 0, ${0.75 * this.scrollAmount})`,
        paddingBottom: Math.min(20, 20 * 2 * this.scrollAmount)  + 'px',
      }
    }
  },
  props: {
  },
  methods: {
    formatCurrency(number) {
      return Util.formatCurrency(number, this.$store.state.config.currency)
    },
    convertMinFromModel(modelMin) {
      return modelMin == null || modelMin == undefined || modelMin == -1 ? -1 * Number.MAX_SAFE_INTEGER : modelMin
    },
    convertMaxFromModel(modelMax) {
      return modelMax == null || modelMax == undefined || modelMax == -1 ? Number.MAX_SAFE_INTEGER : modelMax
    },
    placeholderMin(channelBudget) {
      if (!channelBudget.bounds.annual_min || channelBudget.bounds.annual_min == -1) {
        return 0
      }
      return null
    },
    placeholderMax(channelBudget) {
      if (!channelBudget.bounds.annual_max || channelBudget.bounds.annual_max == -1) {
        return "Unlimited"
      }
      return null
    },
    onScroll(e) {
      this.scrollAmount = Math.max(0, Math.min(e.target.scrollTop / 300, 1));
    },
    minOutOfBounds(value, index) {
      if (value > this.annualBudget) {
        this.minHints[index] = "Larger than annual budget"
      } else {
        this.minHints[index] = "Larger than max value"
      }
      this.minHintsVisible[index] = true
      setTimeout( () => {
        this.minHintsVisible[index] = false
      }, 2000)
      setTimeout( () => {
        this.minHints[index] = ""
      }, 3000)
    },
    maxOutOfBounds(value, index) {
      this.maxHints[index] = "Smaller than min value"
      this.maxHintsVisible[index] = true
      setTimeout( () => {
        this.maxHintsVisible[index] = false
      }, 2000)
      setTimeout( () => {
        this.maxHints[index] = ""
      }, 3000)
    }
  },
  mounted() {
    let scrollableContainer = useUI(this.$store).getScrollParent(this.$refs.form);
    scrollableContainer.removeEventListener("scroll", this.onScroll)
    scrollableContainer.addEventListener("scroll", this.onScroll)
    this.minHintsVisible = Array(this.budgets.length).fill(false)
    this.maxHintsVisible = Array(this.budgets.length).fill(false)
  }
}
</script>

<style scoped>
h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.annual-budget {
  width: 320px;
}
table {
  width: 380px;
  table-layout: fixed
}
td .text-input {
  width: 130px;
}

.label-min, .label-max  {
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  transition: color 1s ease-in-out;
}
.pair {
  flex-basis: 0;
}
tr.minmax td {
  padding-bottom: 20px;
}
.media-budget {
  width: calc(var(--sidebar-width) - 80px);
  background: #fff;
  position: absolute;
  padding-left: 3px;
}
.max-budgets-year {
  margin-top: 160px;
  transition: margin-top 0.125s ease-in-out;
}
.max-budgets-year.validation-error {
  margin-top: 180px;
  transition: margin-top 0.1s ease-in-out;
}
.hint {
  color: var(--c-dark-grey);
  font-size: 12px;
  font-weight: 200;
  height: 20px;
  padding: 8px 10px 0 10px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.hint.min-budgets-sum-out-of-bounds {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-items: center;
  align-items: center;
  height: 0px;
  width: 320px;
  transition: height 0.25s ease-in-out;
  margin-top: 10px;
}
.min-budgets-sum-out-of-bounds.visible {
  height: 30px;
  transition: height 0.25s ease-in-out;
}
.hint.min-budgets-sum-out-of-bounds .icon {
  min-width: 20px;
  max-width: 20px;
  flex-grow: 0;
}
.hint.min-budgets-sum-out-of-bounds .text {
  padding-left: 10px;
  max-width: 300px;
}
.hint.visible {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.budget-label {
  margin-bottom: 10px;
}
</style>
