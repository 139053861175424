<template>
  <div class="internal-info-sidebar">
    <SidebarContent>
      <template v-slot:content>
        <h2 class="underline left">
          Quick goto
        </h2>
        <div>
          <ul class="linklist">
            <li v-for="(link, index) in links" :key="index">
              <a :href=link.anchor>{{ link.title }}</a><br />
            </li>
          </ul>
        </div>
      </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "./SidebarContent.vue"

export default {
  name: 'InternalInfoSidebar',
  components: {
      SidebarContent,
  },
  data: function() {
    return {
      modelIndex: null,
      links: [
        {title: "General", anchor: "#anchor-general"},
        {title: "Historic media plans", anchor: "#anchor-historic-mediaplans"},
        {title: "Models", anchor: "#anchor-models"},
        {title: "Pipeline", anchor: "#anchor-pipeline"},
        // {title: "Downloads", anchor: "#anchor-downloads"},
      ]
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style scoped>
.linklist li {
  text-align: left;
}
</style>
