
<template>
  <div class="segment-selector">
    <DropDown :disabled="segmentSelectorDisabled" class="short" :options="segmentOptions" v-model="currentSegment" :showPrefix="true" :allowNoSelection="false" :maxStringLength="0">
      <template #prefix><div class="colorwell"></div></template>
    </DropDown>
  </div>
</template>

<script>

import DropDown from "@/components/forms/DropDown.vue"

export default {
  components: {
    DropDown
  },
  computed: {
    currentSegment: {
      get() {
        return this.$store.getters["ui/currentSegment"]
      },
      set(segment) {
        this.$store.commit("ui/currentSegment", segment)
      }
    },
    segmentOptions() {
      let opts = this.$store.getters["config/segmentOptions"]
      opts.forEach(opt => {
        opt.label = `<span><b>${this.brandLabel}</b> <span class="separator">|</span> ${opt.label}</span>`
      });
      return opts
    },
    brands() {
      return this.$store.state.config.brands
    },
    brandLabel() {
      return this.brands && this.brands.length ? this.brands[0].label : 'No brand selected'
    },
    segmentSelectorDisabled() {
      return this.$route.meta.segmentSelectorDisabled || false
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.segment-selector {
  width: 340px;
}
.container.info-shown .segment-selector {
  display: none;
}
.colorwell {
  width: 6px;
  height: 6px;
  background: var(--c-orange);
  margin-bottom: 12px;
  margin-left: 10px;
}

:deep(.dropdown.short) {
  margin-bottom: 0;
  border: 1px solid var(--c-light-grey);
  height: 40px;
  font-size: 14px;
}
:deep(.dropdown.short .selectbox) {
  background-color: #fff;
  height: 40px;
  font-size: 14px;
  text-align: left;
}

:deep(.dropdown.short li) {
  text-align: left;
  padding-left: 40px;
}
:deep(.dropdown.short ul) {
  background-color: #fff;
}
:deep(.separator) {
  opacity: 0.2;
  margin: 0 4px;
}
:deep(.dropdown.short ul.active) {
  box-shadow: var(--std-box-shadow);
}

</style>
