<template>
  <div class="report-view-switch">
    <div :class="'option tabs' + ( modelValue == 'tabs' ? ' active' : '')" @click="$emit('update:modelValue', 'tabs'); $emit('click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <line y1=".746" x2="12" y2=".746" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
        <rect x=".75" y="4.246" width="10.5" height="7" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
      </svg>
    </div>
    <div :class="'option list' + ( modelValue == 'list' ? ' active' : '')" @click="$emit('update:modelValue', 'list'); $emit('click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <line y1=".75" x2="12" y2=".75" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
        <line y1="4.25" x2="12" y2="4.25" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
        <line y1="7.75" x2="12" y2="7.75" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
        <line y1="11.25" x2="12" y2="11.25" style="fill:none; stroke:currentColor; stroke-width:1.5px;" />
      </svg></div>
    <div :class="'option grid' + ( modelValue == 'grid' ? ' active' : '')" @click="$emit('update:modelValue', 'grid'); $emit('click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <line x1="0" y1="1" x2="2" y2="1" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="5" y1="1" x2="7" y2="1" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="10" y1="1" x2="12" y2="1" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="0" y1="6" x2="2" y2="6" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="5" y1="6" x2="7" y2="6" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="10" y1="6" x2="12" y2="6" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="0" y1="11" x2="2" y2="11" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="5" y1="11" x2="7" y2="11" style="fill:none; stroke:currentColor; stroke-width:2px;" />
        <line x1="10" y1="11" x2="12" y2="11" style="fill:none; stroke:currentColor; stroke-width:2px;" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportViewSwitch",
  props: {
    modelValue: {
      type: String, // tabs, list, grid
      default: "tabs"
    }
  },
}
</script>

<style scoped>
.report-view-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.option {
  margin: 0 10px 0 0;
  padding-top: 1px;
  width: 24px;
  height: 24px;
  border-radius: 7px;
  border: 1px solid transparent;
  background: none;
  color: #AEB4CB;
  cursor: pointer;
}

.option.active {
  border: 1px solid #E6EBF7;
  background: rgba(237, 241, 250, 0.5);
  color: #1E2566;
}

.options:last-child {
  margin-right: 0;
}

.option:not(.active):hover {
  color: #3F4C73;
}
</style>
