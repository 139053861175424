<template>
  <div class="pia-assistant">
    <img id="pia-ai-path-a" class="pia-ai-path rotating" src="@/assets/img/pia-ai/path-a.png">
    <img id="pia-ai-path-b" class="pia-ai-path rotating delay-1" src="@/assets/img/pia-ai/path-b.png">
    <img id="pia-ai-path-c" class="pia-ai-path rotating-reverse" src="@/assets/img/pia-ai/path-c.png">
    <img id="pia-ai-path-d" class="pia-ai-path rotating delay-6"  src="@/assets/img/pia-ai/path-d.png">
    <img id="pia-ai-path-e" class="pia-ai-path" src="@/assets/img/pia-ai/path-e.png">
    <img id="pia-ai-path-f" class="pia-ai-path" src="@/assets/img/pia-ai/ai.png">
  </div>
</template>

<script>


export default {
  name: 'PiaAssistant',
  data: function() {
    return {
    }
  },
  components: {
  },
  props: {
  },
  methods: {
  }
}
</script>

<style scoped>
.pia-assistant {
  width: 280px;
  overflow: visible;
  position: relative;
  transform: scale(0.5);
  margin-left: 20px;
  min-height: 300px;
  flex-grow: 1;
}

.pia-ai-path {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.9;
  mix-blend-mode: multiply;
}

#pia-ai-path-a {
  /* transform-origin: 52% 58%; */
}

#pia-ai-path-b {
  /* transform-origin: 42% 58%; */
  left: 45px;
}
#pia-ai-path-c {
  left: 170px;
  transform-origin: -20px 180px;
  /* transform: translate(0,0); */
}

#pia-ai-path-d {
  transform-origin: -100% 120%;
  left: 280px;
  top: 220px;
}

#pia-ai-path-e {
  /* transform-origin: 50% 45%; */
  opacity: 1;
  left: 40px;
  mix-blend-mode: lighten;
  transform: scale(0.95);
}

#pia-ai-path-f {
  top: 120px;
  left: 140px;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating-reverse {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rotating {
  -webkit-animation: rotating 30s linear infinite;
  -moz-animation: rotating 30s linear infinite;
  -ms-animation: rotating 30s linear infinite;
  -o-animation: rotating 30s linear infinite;
  animation: rotating 30s linear infinite;
}

.rotating-reverse {
  -webkit-animation: rotating-reverse 38s linear infinite;
  -moz-animation: rotating-reverse 38s linear infinite;
  -ms-animation: rotating-reverse 38s linear infinite;
  -o-animation: rotating-reverse 38s linear infinite;
  animation: rotating-reverse 38s linear infinite;
}

.delay-1 {
  animation-delay: -1s;
}

.delay-6 {
  animation-delay: -50s;
}

</style>
