<template>
  <input
    ref="inputRef"
    :class="clazz"
    type="text"
    :min="min"
    :max="max"
    :value="effectiveValue"
    :placeholder="placeholder"
    @input="onInput"
    @focus="onFocus"
    @change="onChange"
    @blur="onBlur">
</template>

<script>

import Util from "@/util.js"

export default {
  name: 'NumberInput',
  props: {
    modelValue: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    autoSelectOnFocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
  },
  data: function() {
    return {
      hasError: false,
      isFocused: false,
      formattedValue: Util.formatNumber(this.modelValue, { maximumFractionDigits: 2 })
    }
  },
  computed: {
    effectiveValue() {
      return this.modelValue ? this.formattedValue : null
    },
    clazz() {
      let s = "text-input"
      if (this.hasError) {
        s += " has-error"
      }
      return s
    }
  },
  methods: {
    onFocus(e) {
      this.isFocused = true
      if (this.autoSelectOnFocus) {
        this.$nextTick( () => {
          e.target.select()
        })
      }
    },
    onChange(e) {
      if (e.target.value == "") {
        this.$emit("update:modelValue", null)
        return
      }
      let number = this.parse(e.target.value)
      this.hasError = number == null
      if (!this.hasError) {
        this.$emit("update:modelValue", number)
        this.formattedValue = Util.formatNumber(this.modelValue, { maximumFractionDigits: 2 })
        e.target.value = this.formattedValue
      }
      this.clearErrorDelayed()
    },
    onInput() {
    },
    isOutOfBounds(number) {
      return (this.min != null && number < this.min) || (this.max != null && number > this.max)
    },
    parse(inputValue) {
      let number = Util.parseNumber(inputValue)
      if (Number.isNaN(number) || this.isOutOfBounds(number)) {
        return null
      }
      return number
    },
    clearErrorDelayed() {
      setTimeout( () => {
        this.hasError = false
      }, 750)
    }
  },
  watch: {
    modelValue(newValue) {
      this.formattedValue = Util.formatNumber(newValue, { maximumFractionDigits: 2 })
    }
  }
}
</script>
<style scoped>

.text-input {
  background: var(--c-white-grey);
  height: 54px;
  border-radius: 7px;
  color: var(--c-dark-blue);
  padding: 0 20px;
  border: none;
  font-size: 16px;
  transition: background 0.35s ease-in-out;
}

.text-input.has-error {
   background: #efbcc3;
}

.debuginfo {
  font-size: 10px;
  color: #888;
}
</style>
