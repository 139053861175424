<template>
  <div class="planning-container flex vertical full-height">
    <SidebarContent>
        <template v-slot:content>
            <h2 class="underline left">
                Media planning
                <HeadlineBadge type="aiplus" />
            </h2>
            <SegmentedControl :numberOfSteps="6" :currentIndex="currentIndex" @clickedindex="currentIndex = $event"/>
            <div class="scrollable-container sidebar">
              <slot></slot>
            </div>
        </template>
        <template v-slot:actions>
          <div class="button-group">
            <button v-if="showBackButton" class="button ghost tall" @click="onPrevious">Back</button>
            <button v-if="showCancelButton" class="button ghost tall" @click="onCancel">Cancel</button>
            <button :disabled="!nexButtonEnabled" class="button primary tall" @click="onNext">Next</button>
          </div>
        </template>
    </SidebarContent>
  </div>
</template>

<script>
import SidebarContent from "../SidebarContent.vue"
import HeadlineBadge from "@/components/ui/HeadlineBadge.vue"
import SegmentedControl from "@/components/ui/SegmentedControl.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'

export default {
  name: 'PlanningContainer',
  mixins: [CurrentTargetMixin],
  components: {
      SidebarContent,
      HeadlineBadge,
      SegmentedControl
  },
  props: {
  },
  data: function() {
    return {
    }
  },
  computed: {
    paths() {
      return this.$store.getters["planning/planningStepPaths"]
    },
    currentIndex: {
      get() {
        return this.paths.findIndex( p => p == this.$route.path)
      },
      set(index) {
        this.$router.push(this.paths[index])
      }
    },
    nexButtonEnabled() {
      return this.$store.getters["planning/isStepValid"](this.currentIndex)
    },
    showBackButton() {
      return this.currentIndex
    },
    showCancelButton() {
      return this.currentIndex == 0
    },
  },
  methods: {
      onPrevious() {
        this.currentIndex = Math.max(0, this.currentIndex - 1)
      },
      onCancel() {
        this.$router.push("/")
      },
      onNext() {
        this.currentIndex = Math.min(this.currentIndex + 1, this.paths.length - 1)
      },
      onClickedStepIndex(index) {
        this.currentIndex = index
      }
  },
  watch: {
    currentIndex: function(newValue) {
      if (newValue > -1) {
        this.$router.push(this.paths[newValue])
      }
    },

    currentTarget: {
      handler(newTarget) {
        this.$store.commit("planning/updateStepValidation", newTarget)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.planning-container {
}
.button-group {
  padding-right: 40px;
  max-height: 54px;
}
</style>

<style>
.planning-step {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
}
.planning-container .button {
  margin-right: 30px;
}
</style>
