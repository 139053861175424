<template>
  <div class="planning-step scenarios">
    <PlanningContainer v-if="currentTarget">
      <form>
        <h3>Scenarios &amp; year</h3>
        <ScenarioSelection v-model="scenarioConfig" @change="scenarioConfigChanged" />
        <fieldset>
          <label>Year</label>
          <DropDown v-model="currentTarget.year" :options="predictionYearOptions" />
        </fieldset>
        <fieldset>
          <label>Media plan for previous year</label>
          <DropDown v-model="currentTarget.preceding_mediaplanung_id" :options="mediaplanOptions"/>
        </fieldset>
      </form>
    </PlanningContainer>
  </div>
</template>

<script>
import PlanningContainer from "./PlanningContainer.vue"
import DropDown from "@/components/forms/DropDown.vue"
import CurrentTargetMixin from './CurrentTargetMixin.vue'
import ScenarioSelection from "@/components/sidebar/ScenarioSelection.vue"

export default {
  name: 'ScenariosStep',
  mixins: [CurrentTargetMixin],
  data: function() {
    return {
      scenarioConfig: {}
    }
  },
  components: {
      PlanningContainer,
      DropDown,
      ScenarioSelection,
  },
  methods: {
    scenarioConfigChanged(cfg) {
      this.$store.commit("ai/scenarios", Object.values(cfg))
    },
    createScenarioConfig(scenarioGroups) {
      let cfg = {}
      let scenarios = this.currentTarget?.scenarios
      scenarioGroups.forEach((group, index) => {
        cfg[group.key] = scenarios?.[index]
      });
      this.scenarioConfig = cfg
    }
  },
  computed: {
    predictionYearOptions() {
      return this.$store.getters['config/predictionYearOptions']
    },
    mediaplanOptions() {
      return this.$store.getters['mediaplanning/mediaplanOptions']
    },
    scenarioGroups() {
      return this.$store.state.config.scenarioGroups
    },
  },
  created() {
    this.createScenarioConfig(this.scenarioGroups)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.redirectIfNoCurrentTarget())
  },
}
</script>
