
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <MediaplanList />
    </div>
  </div>
</template>

<script>
import MediaplanList from "../components/lists/MediaplanList.vue"

export default {
  name: 'MediaplansPage',
  components: {
    MediaplanList,
  },
  props: {
  }
}
</script>
