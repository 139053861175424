<template>
  <div class="fullframe-page-container">
    <div class="second-bg">
      <slot />
    </div>
    <img class="logo" width="49" height="21" src="@/assets/svg/logos/pia/pia_logo_small_positive.svg">
  </div>
</template>
<script>

export default {
  name: 'FullFramePageContainer',
  props: {
  }
}
</script>

<style scoped>
.fullframe-page-container {
  height: 100vh;
  background: var(--g-grey);
}
.second-bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}
.logo {
    position: absolute;
    top: 49px;
    right: 68px;
}

@media screen and (max-width: 1023px) {
.second-bg  {
  background: #fff;
}
}
</style>
