<template>
  <div class="segmented-control">
    <ul>
      <li v-for="(step, index) in steps"
        v-bind:key="index"
        :class="index == currentIndex ? 'active' : ''"
        @click="onClick(index)">{{ step + 1 }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SegmentedControl',
  props: {
    numberOfSteps: {
      type: Number,
      default: 2
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    steps() {
      let steps = []
      for (var i=0; i<this.numberOfSteps; i++) {
        steps.push(i)
      }
      return steps
    }
  },
  methods: {
    onClick(index) {
      this.$emit("clickedindex", index)
    }
  },
}
</script>

<style scoped>
.segmented-control {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  display: inline-block;
  width: 59px;
  height: 25px;
  background: var(--c-white-grey);
  padding-top: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 3px;
  cursor: pointer;
}
li:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
li:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
li:hover {
  background: var(--c-soft-grey);
}
li.active {
  background: var(--c-greenish);
  color: #fff;
}
</style>
