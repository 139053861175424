<template>
  <div class="public-layout">
      <router-view />
  </div>
</template>

<script>

export default {
  name: 'PublicLayout',
  components: {
  },
}
</script>

<style scoped>

.public-layout {

}

</style>
