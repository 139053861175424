import axios from "../api"
import Util from "@/util";

const initialState = {
  predictions: [],
  currentPrediction: null,
  favoritePrediction: null,
  sortInfo: {
    property: "created_timestamp",
    ascending: false
  }
}

export const predictions = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    predictions(state, predictions) {
      state.predictions = predictions
    },
    currentPrediction(state, prediction) {
      state.currentPrediction = prediction
    },
    sortInfo(state, sortInfo) {
      state.sortInfo = {
        property: sortInfo.property,
        ascending: state.sortInfo.property == sortInfo.property ? !state.sortInfo.ascending : sortInfo.ascending || true
      }
    },
  },
  getters: {
    // Used for dropdowns
    predictionOptions(state) {
      return state.predictions.map( m => {
        return {
          label: m.name,
          value: m.id
        }
      })
    },
    favoritePredictionId(state) {
      let prediction = state.predictions.find( p => p.is_favorite )
      return prediction ? prediction.id : null
    },
    predictions(state, getters) {
      return getters.sortedPredictions(state.sortInfo)
    },
    sortedPredictions: (state, getters, rootState, rootGetters) => (sortInfo) => {
      let predictions = state.predictions
      switch (sortInfo.property) {
        case "name": return Util.sortByString(predictions, "name", sortInfo.ascending)
        case `segments.${rootGetters["ui/currentSegment"]}.total_value`: return Util.sortByNumber(predictions, `segments.${rootGetters["ui/currentSegment"]}.total_value`, sortInfo.ascending)
        case "created_timestamp":
        default:
          return Util.sortByTimestamp(predictions, "created_timestamp", sortInfo.ascending)
      }
    },
  },
  actions: {
    fetchAllPredictions({
      commit,
      rootGetters
    }) {
      return axios.get("/prediction", {
          headers: rootGetters["user/authHeader"]
        })
        .then((response) => {
          commit("predictions", response.data.data)
        })
        .catch((err) => {
          commit("ui/error", err, { root: true })
          //throw (new Error("Failed fetching predictions"))
        })
    },
    predictionById({
      rootGetters
    }, id) {
        return axios.get(`/prediction/${id}`, {
          headers: rootGetters["user/authHeader"]
        })
    },
    predict({
      commit,
      rootGetters
    }, prediction) {
      let predictionCopy = JSON.parse(JSON.stringify(prediction))
      //delete predictionCopy.save_as
      return axios.post("/predict", predictionCopy, {
        headers: rootGetters["user/authHeader"]
      })
      .then((response) => {
        commit("currentPrediction", response.data.data)
      })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error("Failed executing prediction"))
      })
    },
    addPrediction({
      commit,
      rootGetters
    }, prediction) {
      return axios.post("/predict", prediction, {
        headers: rootGetters["user/authHeader"]
      })
      // .then((response) => {
      //   commit("addPrediction", response.data.data)
      // })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error("Failed saving prediction"))
      })
    },
    deletePrediction({
      commit,
      rootGetters
    }, predictionId) {
      return axios.delete(`/prediction/${predictionId}`, {
        headers: rootGetters["user/authHeader"]
      })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error("Failed deleting prediction"))
      })
    },
    updatePrediction({
      commit,
      rootGetters
    }, data) {
      if (!data || !data.id) {
        throw (new Error(`No data given or id missing`, data))
      }
      return axios.put(`/prediction/${data.id}`, data, {
        headers: rootGetters["user/authHeader"]
      })
      .catch((response) => {
        commit("ui/error", response, { root: true})
        //throw (new Error(`Failed updating prediction with id ${data.id}`))
      })
    },
    downloadPrediction({ rootGetters }, id) {
      ///mediaplanung/0/download
      ///mediaplanung/1/download?asbase64json
      return axios.get(`/prediction/${id}/download?asbase64json`, {
        headers: rootGetters["user/authHeader"],
      })
    },
  }
};