import axios from "../api"

const initialState = {
  models: [],
  currentModel: {},
  metainfo: {},
  pipeline: "",
  future_max_date: "",
  last_known_advertising_date: "",
  last_known_turnover_date: "",
}

export const internal = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    pipeline(state, pipeline) {
      state.pipeline = pipeline
    },
    models(state, models) {
      state.models = models
    },
    future_max_date(state, future_max_date) {
      return state.future_max_date = future_max_date
    },
    last_known_turnover_date(state, last_known_turnover_date) {
      return state.last_known_turnover_date = last_known_turnover_date
    },
    last_known_advertising_date(state, last_known_advertising_date) {
      return state.last_known_advertising_date = last_known_advertising_date
    },
    currentModel(state, currentModel) {
      state.currentModel = currentModel
    },
    error(state, error) {
      state.error = error
    },
  },
  getters: {
    models(state) {
      return state.models
    },
    currentModel(state) {
      return state.currentModel
    },
    pipeline(state) {
      return state.pipeline
    },
    metainfo(state) {
      return state.metainfo
    },
    future_max_date(state) {
      return state.future_max_date
    },
    last_known_turnover_date(state) {
      return state.last_known_turnover_date
    },
    last_known_advertising_date(state) {
      return state.last_known_advertising_date
    }
  },
  actions: {
    fetchModelinfo({
      commit,
      rootGetters
    }) {
      return new Promise( (resolve, reject) => {
        axios.get("/modelinfo", {
          headers: rootGetters["user/authHeader"]
        }).then((response) => {
          let modelinfo = response.data.data
          resolve(modelinfo)
        })
        .catch((err) => {
          console.log(err)
          commit("ui/error", err, { root: true })
          reject(err)
        })
      })
    },
  }
};
