
<template>
  <div class="flex vertical full-height">
    <div class="scrollable-container main">
      <div class="reports-header">
        <h2>Predefined reports</h2>
        <div class="actions">
          <div @click="onAddReport()" class="add-report">
            <inline-svg class="add-icon" :src="require('@/assets/svg/icons/reports/icon-add-small.svg')" width="10" height="10"></inline-svg>
            New report
          </div>
        </div>
      </div>
      <div class="report-tiles">
        <PredefinedReportTile
          class="report-tile predefined"
          v-for="(report, index) in predefinedReports" v-bind:key="index"
          @click="selectReport(report, true)"
          :report="report"
        />
      </div>
      <h2>Own reports</h2>
      <div class="report-tiles">
        <WidgetRow flexGrow="1">
          <div class="lower-widget-row">
            <WidgetContainer>
              <ReportsList
                :reports="customReports"
              >
              </ReportsList>
            </WidgetContainer>
          </div>
        </WidgetRow>
      </div>
    </div>
  </div>
</template>

<script>
import PredefinedReportTile from "@/components/reports/PredefinedReportTile.vue"
import ReportsList from "@/components/reports/ReportsList.vue"
import WidgetContainer from '@/components/widgets/WidgetContainer.vue'
import WidgetRow from "@/components/widgets/WidgetRow.vue"

export default {
  name: 'ReportsPage',
  components: {
    PredefinedReportTile,
    ReportsList,
    WidgetContainer,
    WidgetRow
  },
  data: function() {
    return {
    }
  },
  methods: {
    selectReport(report, /*isPredefined*/) {
      this.$router.push(`/reports/${report.id}/views/0`)
    },
    onAddReport() {
      this.$store.dispatch("reports/newReport").then( () => {
        this.$router.push("/reports/current/views/0/datasets/0/filters")
      })
    }
  },
  computed: {
    reports() {
      return this.$store.getters["reports/reports"]
    },
    customReports() {
      return this.reports?.filter( r => !r.predefined) || []
    },
    predefinedReports() {
      return this.reports?.filter( r => r.predefined) || []
    }
  },
  created() {
    this.$store.dispatch("reports/fetchReports").then( report => {
      this.$store.commit("reports/currentReport", report)
    })
  },
}
</script>

<style src="@/assets/css/reports.css" />
<style scoped>

.report-tiles {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
h2 {
  text-align: left;
}
.add-report {
  cursor: pointer;
}
</style>
