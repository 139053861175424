<template>
  <div class="download-tile" @click="onDownload">
    <inline-svg
        class="icon-filetype"
        :src="require('@/assets/svg/icons/right_sidebar/icon_excel_doc_large_40x40px.svg')" width="40" height="40">
    </inline-svg>
    <div class="file-desc">
        <span class="file-title">{{ label }}</span>
        <span class="file-info">EXCEL</span>
    </div>
    <inline-svg
        class="icon-download"
        :src="require('@/assets/svg/icons/right_sidebar/icon_download_20x15px.svg')" width="20" height="15">
    </inline-svg>
  </div>
</template>

<script>
import UiMixin from '../mixins/UiMixin.vue'

export default {
  name: 'DownloadTile',
  mixins: [UiMixin],
  props: {
    label: {
      type: String
    },
    dispatchPath: {
      type: String
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
      onDownload() {
        this.download(this.dispatchPath)
      }
  }
}
</script>

<style scoped>
.download-tile {
    background: var(--c-white-grey);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 108px;
    border-radius: 7px;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 1rem;
}
.file-desc {
    flex-grow: 1;
}
.file-desc span {
    display: block;
}
span.file-title {
    font-weight: 500;
    margin-bottom: 4px;
}
span.file-info {
    font-size: 14px;
}
.icon-filetype {
    margin: 0 20px 0 40px;
}
.icon-download {
    margin-right: 40px;
}
</style>
