
<template>
  <div class="sort-actions" @click="onSort()">
      <svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="6.121" viewBox="0 0 9.414 6.121">
        <path d="M-3660.711,2906.911l4-4,4,4" transform="translate(3661.418 -2901.497)" fill="none" stroke="#1e2566" stroke-width="2"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="6.121" viewBox="0 0 9.414 6.121">
        <path d="M-3660.711,2906.911l4-4,4,4" transform="translate(-3652.004 2907.618) rotate(180)" fill="none" stroke="#1e2566" stroke-width="2"/>
      </svg>
  </div>
</template>

<script>

export default {
  name: 'SortActions',
  props: {
    property: String,
    label: String,
  },
  methods: {
    onSort() {
      this.$emit("sort", {
        property: this.property,
        label: this.label,
      })
    }
  }
}
</script>

<style scoped>
.sort-actions {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.sort-actions svg:first-child {
  margin-bottom: 4px;
}
</style>
