<template>
  <div class="tiles-collection">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TilesCollection',
}
</script>

<style scoped>
.tiles-collection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
