
const initialState = {
  historicReferenceYear: null,
  historicProbeYear: null,
  selectedChannels: [],
  referenceTurnover: null,
  referenceMediaplan: null,
  probeTurnover: null,
  probeMediaplan: null,
  referenceUplifts: null,
  probeUplifts: null,
  referenceMeta: null,
  probeMeta: null,
  datasetIds: {},
}
function extractMetadata(response) {
  let meta = JSON.parse(JSON.stringify(response.data.data))
  delete meta.mediaplanung
  delete meta.prediction
  delete meta.uplifts
  meta.totalValues = remapTotalValues(response.data.data.segments)
  meta.isHistoric = meta.id == -1
  return meta
}

const remapTurnover = (segments, rootState, key) => {
  let turnoverSegments = {}
  Object.keys(segments).forEach( segment => {
    let turnover = rootState.config.weeks.map( () => null)
    segments[segment]?.[key]?.forEach( t => {
      turnover[t.week_index] = t
    })
    turnoverSegments[segment] = turnover
  })
  return turnoverSegments
}

const remapUplifts = (segments) => {
  return Object.keys(segments).reduce((acc,curr)=> (acc[curr]=segments[curr].uplifts,acc),{})
}

const remapTotalValues = (segments) => {
  return Object.keys(segments).reduce((acc,curr)=> (acc[curr]=segments[curr].total_value,acc),{})
}

const remapHistoricTotalValues = (segments) => {
  let map = Object.keys(segments).reduce((acc,curr)=> (acc[curr]=segments[curr].historic_turnover,acc),{})
  Object.keys(map).forEach( key => {
    map[key] = map[key]?.reduce( (total, t) => total + t.turnover, 0 ) || null
  })
  return map
}



export const analysis = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    historicReferenceYear(state, year) {
      state.historicReferenceYear = year
    },
    historicProbeYear(state, year) {
      state.historicProbeYear = year
    },
    selectedChannels(state, selectedChannels) {
      state.selectedChannels = selectedChannels
    },
    addSelectedChannel(state, channel) {
      let idx = state.selectedChannels.indexOf(channel)
      if (idx == -1) {
        state.selectedChannels.push(channel)
      }
    },
    removeSelectedChannel(state, channel) {
      let idx = state.selectedChannels.indexOf(channel)
      if (idx > -1) {
        state.selectedChannels.splice(idx, 1)
      }
    },
    toggleSelectedChannel(state, channel) {
      let idx = state.selectedChannels.indexOf(channel)
      if (idx > -1) {
        state.selectedChannels.splice(idx, 1)
      } else {
        state.selectedChannels.push(channel)
      }
    },
    referenceTurnover(state, turnover) {
      state.referenceTurnover = turnover
    },
    probeTurnover(state, turnover) {
      state.probeTurnover = turnover
    },
    referenceMediaplan(state, mediaplan) {
      state.referenceMediaplan = mediaplan
    },
    probeMediaplan(state, mediaplan) {
      state.probeMediaplan = mediaplan
    },
    referenceUplifts(state, uplifts) {
      state.referenceUplifts = uplifts
    },
    probeUplifts(state, uplifts) {
      state.probeUplifts = uplifts
    },
    referenceMeta(state, meta) {
      state.referenceMeta = meta
    },
    probeMeta(state, meta) {
      state.probeMeta = meta
    },
    resetReferenceFinancialData(state) {
      state.referenceTurnover = initialState.referenceTurnover
      state.referenceMediaplan = initialState.referenceMediaplan
      state.referenceUplifts = initialState.referenceUplifts
      state.referenceMeta = initialState.referenceMeta
    },
    resetProbeFinancialData(state) {
      state.probeTurnover = initialState.probeTurnover
      state.probeMediaplan = initialState.probeMediaplan
      state.probeUplifts = initialState.probeUplifts
      state.probeMeta = initialState.probeMeta
    },
    datasetIds(state, ids) {
      state.datasetIds = ids
    },
    datasetIdForKeyAndType(state, payload) {
      let ids = state.datasetIds[payload.key] || {}
      ids[payload.type] = payload.id
      state.datasetIds[payload.key] = ids
    },
  },
  getters: {
    referenceFinancialData(state, getters, rootState, rootGetters) {
      let segment = rootGetters["ui/currentSegment"]

      return {
          turnover: segment ? state.referenceTurnover?.[segment] : [],
          mediaplan: state.referenceMediaplan,
          uplifts: segment ? state.referenceUplifts?.[segment] : {},
          meta: state.referenceMeta
      }
    },

    probeFinancialData(state, getters, rootState, rootGetters) {
      let segment = rootGetters["ui/currentSegment"]

      return {
        turnover: segment ? state.probeTurnover?.[segment] : null,
        mediaplan: state.probeMediaplan,
        uplifts: segment ? state.probeUplifts?.[segment] : {},
        meta: state.probeMeta
      }
    },
  },
  actions: {
    fetchReferenceFinancialData(storevars, opts) {
      return storevars.dispatch("fetchFinancialData", { ...opts, ...{type: "reference"}})
    },
    fetchProbeFinancialData(storevars, opts) {
      return storevars.dispatch("fetchFinancialData", { ...opts, ...{type: "probe"}})
    },
    fetchFinancialData({ state, commit, dispatch, rootState }, opts) {
      let type = opts.type
      commit(type == "reference" ? "resetReferenceFinancialData" : "resetProbeFinancialData")
      return new Promise((resolve, reject) => {
        if (opts.id == -1) {
          let historicYear = opts.historicYear || (type == "reference" ? state.historicReferenceYear : state.historicProbeYear)

          dispatch("historic/fetchHistoricTurnover", historicYear, { root: true }).then((turnoverResponse) => {
            dispatch("historic/fetchHistoricMediaplan", historicYear, { root: true }).then((response) => {
              commit(`${type}Turnover`, remapTurnover(turnoverResponse.data.data.segments, rootState, "historic_turnover"));
              commit(`${type}Uplifts`, remapUplifts(turnoverResponse.data.data.segments));
              commit(`${type}Meta`, {
                name: turnoverResponse.data.data.label,
                totalValues: remapHistoricTotalValues(turnoverResponse.data.data.segments),
                year: historicYear,
                isHistoric: true
              })
              commit(`${type}Mediaplan`, response.data.data.historic_mediaplanung);
              resolve()
            })
            .catch((err) => {
              console.error(err)
              reject()
            });
          })
          .catch((err) => {
            console.error(err)
            reject()
          });
        } else {
          if (opts.id) {
            dispatch("predictions/predictionById", opts.id, { root: true }).then((response) => {
              commit(`${type}Turnover`, remapTurnover(response.data?.data?.segments, rootState, "prediction"));
              commit(`${type}Mediaplan`, response.data.data.mediaplanung);
              commit(`${type}Uplifts`, remapUplifts(response.data.data.segments));
              commit(`${type}Meta`, extractMetadata(response));
              resolve()
            })
            .catch((err) => {
              console.error(err)
              reject()
            });
          } else {
            resolve()
          }
        }
      })
    },
  }
};
