<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    currentTarget() {
      return this.$store.state.ai.currentTarget
    }
  },
  methods: {
    redirectIfNoCurrentTarget() {
      if (!this.currentTarget) {
        console.log("No current target set. Redirecting to the beginning of the planning wizard.")
        this.$router.push("/planning")
      }
    }
  }
}
</script>
