import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import InlineSvg from 'vue-inline-svg';
import store from "./store"
import moment from 'moment';

moment.locale('en')

const app = createApp(App)
//app.provide('$axios', axios.create(axiosConfig));
//app.config.globalProperties.$axios = http
app.component('inline-svg', InlineSvg)
app.use(store)
app.use(router)
app.mount('#app')

