<template>
  <div class="info-sidebar">
      <div class="header">
        <span class="title">INFO</span>
      </div>
      <div class="content flex vertical full-height">
        <div class="scrollable-container">
          <div class="section" v-for="(item, index) in content" v-bind:key="index">
            <div class="icon">
              <inline-svg v-if="item.icon" :src="require(`@/assets/svg/icons/info_sidebar/${item.icon}`)" width="60" height="60"></inline-svg>
            </div>
            <h3 v-html="item.title"></h3>
            <p class="copy" v-html="item.text"></p>
          </div>
        </div>
      <div class="footer">
        <h3>You have questions?</h3>
        <div class="vendor-email"><a :href="`mailto:${$store.state.ui.vendor.email}`">{{ $store.state.ui.vendor.email }}</a></div>
      </div>
    </div>
  </div>
</template>

<script>

import CurrentTargetMixin from './planning/CurrentTargetMixin.vue'

export default {
  name: 'InfoSidebar',
  mixins: [CurrentTargetMixin],
  data: function() {
    return {
      lastDisplayedContent: []
    }
  },
  computed: {
    content() {
      let path = this.$store.state.info.infoBarPath
      let infoContents = this.$store.getters["info/contentByPath"](path) || {}
      let result = null
      if (this.currentTarget?.optimization_type) {
        result = infoContents?.[this.currentTarget?.optimization_type]?.contents || infoContents.contents || []
      } else {
        result = infoContents.contents || []
      }
      // Also display content while collapse animation is pending (path might be set to null, so no content would be shown during animation)
      if (!result.length) {
        return this.lastDisplayedContent
      }
      return result
    }
  },
  watch: {
    content(newContent) {
      if (newContent.length) {
        this.lastDisplayedContent = newContent
      }
    }
  }
}
</script>

<style scoped>
.header {
  padding-top: 20px;
  border-bottom: 1px solid var(--c-medium-grey);
  text-align: left;
}
.footer {
  border-top: 1px solid var(--c-medium-grey);
  padding: 20px 0 40px;
}
.footer h3 {
  color: #000;
  font-size: 20px;
}
.vendor-phone {
  color: var(--c-deep-grey);
  margin-bottom: 4px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  color: var(--c-dark-grey);
  line-height: 80px;
  padding-left: 40px;
}
.info-sidebar {
  color: var(--c-dark-grey);
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 1;
}
.section {
  padding: 40px 20px;
  border-bottom: 1px solid var(--c-medium-grey);
}
.section:last-child {
  border-bottom: none;
}
.section p {
  min-width: 205px;
}
.copy {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
}
</style>
