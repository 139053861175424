
<template>
  <div>
  </div>
</template>

<script>

export default {
  computed: {
    numberOfPredictionsTitles() {
      let titles = []
      this.mediaplans.forEach(m => {
        if (m.is_default_plan) {
          titles[m.id] = ""
        } else if (m.n_predictions == 0) {
          titles[m.id] = "0 Forecasts"
        } else {
          titles[m.id] = m.n_predictions > 1 ? (m.n_predictions + ' Forecasts') : '1 Forecast'
        }
      });
      return titles
    },
  },
  methods: {
    mediaplanName(item) {
      return item.name || "Unnamed"
    },
    showPredictionButton(item) {
      return !item.ai_details || item.ai_details.status == "DONE"
    },
    showProgressBar(item) {
      return item.ai_details?.status == "QUEUED" || item.ai_details?.status == "RUNNING"
    },
    isMediaplanDownloadEnabled(item) {
      return !item.ai_details || (item.ai_details?.status != "QUEUED" && item.ai_details?.status != "RUNNING")
    },
    totalValueText(item) {
      if (item.ai_details && item.ai_details.status != "DONE") {
        return "In progress"
      }
      return `&sum; ${this.formatCurrency(item.total_value)}`
    },
  }
}
</script>

<style scoped>
</style>
