<template>
  <div class="stats-widget">
    <div class="stats-collection">
      <div class="stat" v-for="(stat, index) in statuses" v-bind:key="index">
        <div class="value">{{ stat.value }}</div>
        <div class="label hyphenate">{{ label(stat) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StatsWidget',
  props: {
  },
  computed: {
    statuses() {
      return this.$store.state.config.status
    }
  },
  methods: {
    label(stat) {
      return stat.label // hyphenate here?
    }
  }
}
</script>

<style scoped>
.stats-widget {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-grow: 1;
  background-image: url('@/assets/img/pia_otc_visual_small@2x.jpg');
  border-radius: 7px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  min-height: 300px;
}
.stats-collection {
  margin: 40px 0 40px 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMTAuNSIgaGVpZ2h0PSIyMTAuNSIgdmlld0JveD0iMCAwIDIxMC41IDIxMC41Ij4KICA8ZyBpZD0iR3J1cHBlXzQwMDQiIGRhdGEtbmFtZT0iR3J1cHBlIDQwMDQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzAgLTcyNS4yNSkiPgogICAgPGxpbmUgaWQ9IkxpbmllXzU0OSIgZGF0YS1uYW1lPSJMaW5pZSA1NDkiIHgyPSIyMTAuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMwIDgzMC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEiIG9wYWNpdHk9IjAuNDE1Ii8+CiAgICA8ZyBpZD0iR3J1cHBlXzQwMDAiIGRhdGEtbmFtZT0iR3J1cHBlIDQwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwNjUuNzUgNTk1LjI1KSByb3RhdGUoOTApIj4KICAgICAgPGxpbmUgaWQ9IkxpbmllXzU1MCIgZGF0YS1uYW1lPSJMaW5pZSA1NTAiIHgyPSIyMTAuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMwIDgzMC41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEiIG9wYWNpdHk9IjAuNDE1Ii8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K");
}
.stat {
  flex-grow: 1;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.value {
  font-size: 32px;
  margin-bottom: 10px;
}
.label {
  word-wrap: break-word;
  max-width: 80px;
  font-size: 12px;
}
</style>
