<template>
  <div class="planning-step target">
    <PlanningContainer>
      <form>
        <div class="header">
          <h4>Optimization goal</h4>
          <button
            type="button"
            class="rename ghost button rounded short"
            :disabled="!targetActionsEnabled"
            @click="onRenameTarget">Rename</button>
          <button
            type="button"
            :disabled="!targetActionsEnabled"
            @click="onDeleteTarget">
            <svg class="trash-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30" viewBox="0 0 30 30">
              <g transform="translate(-1849 -319)">
                <g transform="translate(1849 319)" fill="none" stroke="currentColor" stroke-width="1">
                  <circle cx="15" cy="15" r="15" stroke="none"/>
                  <circle cx="15" cy="15" r="14.5" fill="none"/>
                </g>
                <g transform="translate(1859.367 326.922)">
                  <g>
                    <path d="M9.191,5.353a.648.648,0,0,1,0,.072l-.795,8.028a.768.768,0,0,1-.768.7H1.639a.767.767,0,0,1-.768-.7l-.8-8.028a.772.772,0,0,1,.7-.841.632.632,0,0,1,.071,0h7.58a.771.771,0,0,1,.772.773M.87,3.313H8.4a.869.869,0,1,0,0-1.739l-2.895,0V.87a.87.87,0,0,0-1.739,0v.7l-2.892,0a.869.869,0,1,0,0,1.739" fill="currentColor"/>
                  </g>
                </g>
              </g>
            </svg>
          </button>
      </div>
        <fieldset>
          <label>Default</label>
          <TilesCollection>
            <SimpleTile v-for="(target, index) in predefinedTargets"
              v-bind:key="index"
              :text="target.label"
              :selected="currentTarget && currentTarget.is_predefined && currentTarget.optimization_type == target.optimization_type"
              @click="selectTarget(target)"
            />
          </TilesCollection>
        </fieldset>
        <fieldset>
          <label>Custom templates</label>
          <TilesCollection>
            <SimpleTile v-for="(target, index) in customTargets"
              v-bind:key="index"
              :text="target.label"
              :selected="currentTarget && !currentTarget.is_predefined && target.id == currentTarget.id"
              @click="selectTarget(target)"
            />
          </TilesCollection>
        </fieldset>
      </form>
    </PlanningContainer>
    <ConfirmDialog v-if="targetToBeDeleted" title="Delete optimization goal?">
        <template v-slot:message><span v-html="deleteConfirmMessage"></span></template>
        <template v-slot:actions>
          <div class="button-group">
            <button @click.stop="doCancelDelete" class="button ghost mediumsize rounded">Cancel</button>
            <button @click.stop="doDeleteTarget" class="button primary mediumsize rounded">Delete</button>
          </div>
        </template>
    </ConfirmDialog>
    <RenameDialog
      v-if="itemToBeRenamed"
      title="Rename optimization goal"
      :oldName="itemToBeRenamed.label"
      v-model="newItemName"
      :error="renamingError"
      @rename:cancel="doCancelRename"
      @rename:save="doRenameTarget"
    />
  </div>
</template>

<script>
import SimpleTile from "@/components/ui/SimpleTile.vue"
import TilesCollection from "@/components/ui/TilesCollection.vue"
import PlanningContainer from "./PlanningContainer.vue"
import ConfirmDialog from "@/components/ui/ConfirmDialog.vue"
import RenameDialog from "@/components//ui/RenameDialog.vue"
import RenameMixin from "@/components/mixins/RenameMixin.vue"
import useUI from '@/js/useUI.js'

export default {
  name: 'TargetStep',
  components: {
      SimpleTile,
      TilesCollection,
      PlanningContainer,
      ConfirmDialog,
      RenameDialog
  },
  mixins: [RenameMixin],
  data: function() {
    return {
      targetToBeDeleted: null
    }
  },
  computed: {
    predefinedTargets() {
      return this.$store.getters["ai/predefinedTargets"]
    },
    customTargets() {
      return this.$store.getters["ai/customTargets"]
    },
    currentTarget() {
      return this.$store.state.ai.currentTarget
    },
    targetActionsEnabled() {
      return this.currentTarget && !this.currentTarget?.is_predefined
    },
    deleteConfirmMessage() {
      return `Do you really want to delete the optimization goal <b>${this.targetToBeDeleted.label}</b>?`
    },
  },
  methods: {
    onRenameTarget() {
      useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
      this.itemToBeRenamed = this.currentTarget
      this.newItemName = this.currentTarget.label
    },
    onDeleteTarget() {
      useUI(this.$store).blockUI({ complete: true, unblockDisabled: true })
      this.targetToBeDeleted = this.currentTarget
    },
    selectTarget(target) {
      this.$store.commit("ai/currentTarget", target)
    },
    doRenameTarget() {
      useUI(this.$store).unblockUI()
      this.renamingError = null
      this.$store
        .dispatch("ai/updateTarget", {
            id: this.itemToBeRenamed.id,
            label: this.newItemName
        })
        .then(() => {
          this.$store.dispatch("ai/fetchTargets").then( () => {
            this.currentTarget.label = this.newItemName
            this.itemToBeRenamed = null
            this.newItemName = null
            useUI(this.$store).unblockUI()
          });
        })
        .catch( (error, response) => {
          console.log("renamingError", error, response)
          this.renamingError = error
        });
    },
    doDeleteTarget() {
      this.$store
        .dispatch("ai/deleteTarget", this.targetToBeDeleted.id)
        .then(() => {
          this.targetToBeDeleted = null;
          this.$store.commit("ai/currentTarget", null)
          this.$store.dispatch("ai/fetchTargets").then( () => {
            useUI(this.$store).unblockUI()
          });
        });
    },
    doCancelDelete() {
      useUI(this.$store).unblockUI()
      this.targetToBeDeleted = null
    },
    doCancelRename() {
      useUI(this.$store).unblockUI()
      this.itemToBeRenamed = null
    }
  },
  beforeCreate() {
    this.$store.dispatch("ai/fetchTargets")
  }
}
</script>

<style scoped>
  h4 {
    flex-grow: 1;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .actions {
    justify-self: flex-end;
  }
  button.rename {
    color: #7882A8;
    outline-color: #7882A8;
    margin-right: 10px;
    height: 30px;
  }
  button.rename::not(:disabled) :hover {
    color: #2c303e;
    outline-color: #2c303e;
  }
  button:disabled .trash-icon {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .trash-icon {
    margin-top: 4px;
    cursor: pointer;
    color: #7882A8;
  }
  button::not(:disabled) .trash-icon:hover {
    color: #2c303e;
  }
</style>
