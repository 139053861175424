<template>
  <div class="login-page">
    <div class="welcome-text">
      <h1 class="underline left">
        Turn your data<br>into insights
      </h1>
    </div>
    <FullFramePageContainer>
      <div class="login-dialog">
          <h1 class="underline center">Login</h1>
          <LoginForm />
      </div>
    </FullFramePageContainer>
  </div>
</template>

<script>
import LoginForm from '../components/login/LoginForm.vue'
import FullFramePageContainer from '@/views/FullFramePageContainer.vue'

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    FullFramePageContainer
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit("ui/error", null)
    })
  },
}
</script>

<style scoped>
.welcome-text {
  position: absolute;
  height: 100vh;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in-out;
}
.login-page {
  height: 100vh;
  background: var(--g-grey);
}
.login-dialog {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 460px;
  padding: 20px 40px 80px 40px;
  background: #fff;
  border-radius: 7px;
  box-shadow: var(--std-box-shadow);
}

.second-bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}

.welcome-text h1 {
  font-size: 24px;
}

@media screen and (max-width: 1200px) {
  .welcome-text {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
}

</style>
